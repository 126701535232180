<template>
<div id="page-compras" class="non-printable">
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <v-icon slot="divider">forward</v-icon>
        </v-breadcrumbs>
    </div>
    <v-container fluid fill-height class="non-printable">
        <v-row align-center justify-center>
            <v-col>
                <base-material-card color="primary" icon="library_books" title="Ordenes de Compra" class="elevation-1 px-5 py-3">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- Botón redondo con contador -->
                        <div class="notification-button">
                            <v-btn fab dark x-small color="yellow darken-1" @click.native="modal_vencidos=true;"
                                title="Pedidos Vencidos" v-tippy :loading="loading_counter">
                                <v-icon>event</v-icon>
                            </v-btn>&nbsp;

                            <!-- Contador en la esquina superior derecha -->
                            <div class="counter">{{ pedidos_vencidos.length }}</div>
                        </div>&nbsp;&nbsp;
                        <v-btn fab dark x-small color="primary" @click.native="openModalSugeridos(1)" title="Buscar Sugeridos" v-tippy>
                            <v-icon dark>mdi-cart-plus</v-icon>
                        </v-btn>&nbsp;                        
                        <v-btn fab dark x-small color="blue" @click.native="openModalDirecta()" title="Orden Directa" v-tippy>
                            <v-icon dark>post_add</v-icon>
                        </v-btn>&nbsp;
                        <v-btn fab dark x-small color="secondary" @click.native="openModal(1)" title="Crear Orden" v-tippy>
                            <v-icon dark>add</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-card-text>
                        <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

                            <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" 
                                class="elevation-2 pa-2 no-horizontal-scroll" @row-click="selectRow">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>
                                <template slot="fecha" slot-scope="props">
                                    {{ parse_date_time(props.row.fecha) }}
                                </template>
                                <template slot="fecha_finalizada" slot-scope="props">
                                    {{ parse_date_time(props.row.fecha_finalizada) }}
                                </template>
                                <template slot="facturas" slot-scope="props">
                                    <v-chip color="blue" label outlined x-small @click="showFacturas(props.row)">
                                        {{ (props.row.facturas && props.row.facturas.length)?props.row.facturas.length:"0" }} Facturas                                        
                                    </v-chip>
                                </template>
                                <template slot="articulos" slot-scope="props">
                                    <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                                        Ver {{ props.row.articulos.length }} artículos
                                    </v-chip>
                                </template>
                                <template slot="estatus" slot-scope="props">
                                    <div v-if="props.row.estatus=='Orden Finalizada'" style="font-weight:bold;color:green;font-size:10px !important;">{{props.row.estatus}}</div>
                                    <div v-else style="font-weight:bold;color:#DC7633;font-size:10px !important;">{{props.row.estatus}}</div>
                                </template>
                                <template slot="actions" slot-scope="props">
                                    <v-speed-dial direction="left" open-on-hover>
                                        <template v-slot:activator>
                                            <v-btn x-small fab dark color="primary">
                                                <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                                                <v-icon v-else>reorder</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-btn x-small fab dark color="blue" @click.native="cambiarProveedor(props.row)" title="Editar Proveedor" v-tippy>
                                            <v-icon>account_circle</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="green" @click.native="vistaPreviaExcel(props.row)" title="Excel Proveedor" v-tippy>
                                            <v-icon>mdi-file-excel</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="blue darken-2" @click.native="cambiarEstatus(props.row)" title="Editar Estatus" v-tippy>
                                            <v-icon>mdi-more</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="red" v-if="['Orden Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(props.row.estatus)" @click.native="eliminarOrden(props.row)" title="Eliminar Orden" v-tippy>
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="purple darken-1" v-if="props.row.estatus!='Pendiente'" @click.native="editarObservaciones(props.row)" title="Observaciones" v-tippy>
                                            <v-icon>mdi-note-plus</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="blue" @click.native="modalAdjuntar(props.row)" title="Adjuntar Documentos" v-tippy>
                                            <v-icon>attach_file</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="yellow darken-1" v-if="props.row.estatus!='Orden Finalizada'" @click.native="editarFechaEntrega(props.row)" title="Fecha Entrega Estimada" v-tippy>
                                            <v-icon>event</v-icon>
                                        </v-btn>
                                    </v-speed-dial>

                                </template>

                            </v-client-table>
                            <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
                        </div>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>

    <!-- MODAL BUSQUEDA CON INFORMACION-->
    <v-dialog v-model="modalBusqueda" max-width="99%">
        <v-card>
            <!--v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Información de Artículos</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalBusqueda = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title-->
            <v-card-text>

                    <!--v-container grid-list-md id="modalbusqueda"--> 
                    <v-tabs id="modalbusqueda" grow style="margin-left:0px;" v-model="active_tab">
                        <v-tabs-slider color="#EEEEEE"></v-tabs-slider>

                        <v-tab key="busqueda">
                            <v-card-title><b>Buscar Artículos</b></v-card-title>
                        </v-tab>
                        <v-tab key="pedido" @click="ver_compra()">
                            <v-card-title><b>Pedido Abierto</b></v-card-title>
                        </v-tab>

                        <v-tab-item key="busqueda" style="margin-left:10px;margin-right:0px;">
                        <v-row dense>
                            <v-col  sm="10" md="10" lg="10" v-show="modal_opcion==2">
                                <div id="combos_sugeridos">
                                    <v-row id="row_combos_sugeridos">
                                        <v-col sm="9" md="9" lg="9">
                                            <v-autocomplete dense v-model="id_proveedor" :items="proveedores_combo" :hide-no-data="true"
                                                ref="selectproveedor" :loading="loadingProveedores"
                                                placeholder="Ingrese nombre de proveedor"
                                                prepend-icon="search" clearable item-text="nombre" item-value="_id"
                                                @change="consultarSugeridos()" label="Proveedor">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="3" md="3" lg="3">
                                            <v-spacer></v-spacer>
                                            <v-btn small color="success" v-if="data_sugeridos.length>0" v-tippy @click="agregarTodosSugerido()">
                                                <v-icon>mdi-ok</v-icon>Agregar Todos a la Orden
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div id="tableBuscarSugeridos" style="height: 47vh; overflow-y: scroll; overflow-x: scroll;">                                    

                                <v-client-table ref="vuetablearticulossugeridos" :data="data_sugeridos" :columns="columnsArticulosSugeridos"
                                    :options="optionsArticulosSugeridos" class="elevation-2 pa-2 tablafija">
                                    <template slot="articulo.nombre" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:12px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.articulo.nombre}}</div>
                                    </template>
                                    <template slot="articulo.descripcion" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.articulo.descripcion}}</div>
                                    </template>
                                    <template slot="articulo.categoria" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.articulo.categoria.join(", ")}}</div>
                                    </template>
                                    <template slot="minimo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.minimo}}</div>
                                    </template>
                                    <template slot="reorden" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.reorden}}</div>
                                    </template>
                                    <template slot="maximo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.maximo}}</div>
                                    </template>
                                    <template slot="actual" slot-scope="props">
                                        <div v-if="parseFloat(props.row.actual)<=0" :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)"><span style="color:red">{{parseFloat(props.row.actual).toFixed(2)}}</span></div>
                                        <div v-else :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{parseFloat(props.row.actual).toFixed(2)}}</div>
                                    </template>
                                    <template slot="pedir" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{formatNumberDec(props.row.pedir,0)}}</div>
                                    </template>
                                    <template slot="actions" slot-scope="props">
                                        <v-icon small color="success"
                                            v-show="articuloEnOrden(props.row.articulo._id, props.row.proveedor._id)"
                                            title="Artículo Agregado" >check_circle
                                        </v-icon>
                                    </template>
                                </v-client-table>
                                </div>                                
                            </v-col>
                            <v-col sm="10" md="10" lg="10" v-show="modal_opcion==1">
                                <div id="combos_busqueda">
                                    <v-row id="row_combos">
                                        <v-col sm="4" md="4" lg="4">
                                            <v-autocomplete dense v-model="id_articulo" :items="articulos" :hide-no-data="true"
                                            :loading="loadingArticulos" ref="selectarticulo" placeholder="Ingrese nombre de artículo"
                                            prepend-icon="search" clearable item-text="nombre" item-value="_id"
                                            @change="consultarArticulo()" label="Artículo">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="4" md="4" lg="4">
                                            <v-autocomplete dense v-model="id_proveedor" :items="proveedores_combo" :hide-no-data="true"
                                            :loading="loadingProveedores"
                                            ref="selectproveedor" placeholder="Ingrese nombre de proveedor" prepend-icon="search" clearable
                                            item-text="nombre" item-value="_id" @change="consultarProveedor_combo()" label="Proveedor">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="1" md="1" lg="1">
                                            <v-icon @click="modal_proveedor_articulo()" title="Agregar Artículo a Proveedor">mdi-account-settings</v-icon>
                                        </v-col>
                                        <v-col sm="3" md="3" lg="3">
                                            <v-autocomplete dense v-model="nombre_categoria"
                                            :items="categorias" :hide-no-data="true" :loading="loadingCategorias"
                                            ref="selectcategoria" placeholder="Ingrese nombre de categoría"
                                            prepend-icon="search" clearable item-text="nombre" item-value="nombre"
                                            @change="consultarCategoria()" label="Categoría">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div id="tableBuscar" style="height: 47vh; overflow-y: scroll; overflow-x: scroll;">
                                    
                                <v-client-table ref="vuetablearticulos" :data="articulos_encontrados"
                                    :columns="columnsArticulos" :options="optionsArticulos" :key="keyTablaBusqueda">
                                    <template slot="articulo.nombre" slot-scope="props">
                                        <div :class="'columnaArticulo celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:12px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.nombre}}</div>
                                    </template>
                                    <template slot="articulo.descripcion" slot-scope="props">
                                        <div :class="'columnaUnidad celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.descripcion}}</div>
                                    </template>
                                    <template slot="articulo.categoria" slot-scope="props">
                                        <div :class="'columnaCategoria celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.categoria.join(", ")}}</div>
                                    </template>
                                    <template slot="articulo.minimo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.minimo}}</div>
                                    </template>
                                    <template slot="articulo.reorden" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.reorden}}</div>
                                    </template>
                                    <template slot="articulo.maximo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.maximo}}</div>
                                    </template>
                                    <template slot="articulo.actual" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{formatNumberDec(props.row.articulo.actual,2)}}</div>
                                    </template>
                                    <template slot="articulo.actual_total" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{formatNumberDec(props.row.articulo.actual_total,2)}}</div>
                                    </template>
                                    <template slot="articulo.precio_compra_con_iva" slot-scope="props">
                                        <div :class="'columnaPrecioCompra celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">${{formatNumberDec(props.row.articulo.precio_compra_con_iva,2)}}</div>
                                    </template>
                                    <template slot="articulo.precio_venta" slot-scope="props">
                                        <div :class="'columnaPrecioVenta celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">${{parseFloat(props.row.articulo.precio_venta).toFixed(2)}}</div>
                                    </template>
                                    <template slot="articulo.ventas_30_dias" slot-scope="props">
                                        <div :class="'columnaVentas30 celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{parseFloat(props.row.articulo.ventas_30_dias).toFixed(2)}}</div>
                                    </template>
                                    <template slot="fvm" slot-scope="props">
                                        <div :class="'columnaFVM celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">
                                        <span style="color:#DC7633" v-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=0 && (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))<50">
                                            <strong>{{(parseFloat(props.row.articulo.actual_total) - parseFloat(props.row.articulo.ventas_30_dias)).toFixed(2)}}</strong>
                                        </span>
                                        <span style="color:red" v-else-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=50">
                                            <strong>{{(parseFloat(props.row.articulo.actual_total) - parseFloat(props.row.articulo.ventas_30_dias)).toFixed(2)}}</strong>
                                        </span>
                                        <span style="color:green" v-else>
                                            {{(parseFloat(props.row.articulo.actual_total) - parseFloat(props.row.articulo.ventas_30_dias)).toFixed(2)}}
                                        </span>                                        
                                        </div>
                                    </template>
                                    <template slot="fvm2" slot-scope="props">
                                        <div :class="'columnaFVM2 celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">
                                        <span style="color:#DC7633" v-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=0 && (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))<50">
                                            <strong>{{ (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1)).toFixed(2) }}%</strong>
                                        </span>
                                        <span style="color:red" v-else-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=50">
                                            <strong>{{ (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1)).toFixed(2) }}%</strong>
                                        </span>
                                        <span style="color:green" v-else>
                                            {{ (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1)).toFixed(2) }}%
                                        </span>
                                            
                                        </div>
                                    </template>
                                    <!--template slot="articulo.ventas_60_dias" slot-scope="props">
                                        <div :class="'columnaVentas60 celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{parseFloat(props.row.articulo.ventas_60_dias).toFixed(2)}}</div>
                                    </template-->
                                    <template slot="articulo.ventas_periodo_seleccionado" slot-scope="props">
                                        <div :class="'columnaVentasPeriodo celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.ventas_periodo_seleccionado}}</div>
                                    </template>
                                    <template slot="proveedor.nombre" slot-scope="props">
                                        <div :class="'columnaProveedor celda_'+props.row.articulo._id+'_'+props.row.proveedor._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.proveedor.nombre}}
                                            </div>
                                    </template>

                                    <template slot="actions" slot-scope="props">
                                        <v-icon small color="success"
                                            v-show="articuloEnOrden(props.row.articulo._id, props.row.proveedor._id)"
                                            title="Artículo Agregado" >check_circle
                                        </v-icon>
                                    </template>

                                    <template slot="desactivar" slot-scope="props">
                                        <v-icon small color="pink"
                                            @click.native="desactivarArticulo(props.row.articulo._id, props.row.articulo.nombre)"
                                            title="Desactivar Artículo">close_circle
                                        </v-icon>
                                    </template>

                                </v-client-table>
                                </div>
                                
                            </v-col>
                            <v-col sm="2" lg="2" md="2">
                                <fieldset>
                                    <legend> &nbsp;&nbsp;&nbsp;<v-btn outlined x-small dark color="green">Exist. Total:&nbsp; <strong>{{formatNumberDec(existencia_articulo,0)}}</strong>  </v-btn></legend>                                    
                                    <div id="tableExistencias" style="height: 20vh; overflow-y: scroll; overflow-x: scroll;">

                                    <v-client-table ref="vuetableexistencias" :data="articulo_existencias"
                                        :columns="columnsExistencias" :options="optionsExistencias">
                                        <template slot="sucursal" slot-scope="props">
                                            <div style="font-size:10px !important;" >{{props.row.sucursal}}</div>
                                        </template>
                                        <template slot="existencia" slot-scope="props">
                                            <div style="font-size:10px !important;" >{{formatNumberDec(props.row.existencia,2)}}</div>
                                        </template>
                                    </v-client-table>
                                    </div>
                                </fieldset>

                                <fieldset>                                
                                <div id="tablePrecios" style="height: 17vh; overflow-y: scroll; overflow-x: scroll; margin-bottom:-5px; padding-bottom:-5px;">
                                <v-client-table ref="vuetablePrecios" :data="precios_articulo"
                                    :columns="columnsPrecios" :options="optionsPrecios">
                                    <template slot="tipo_precio" slot-scope="props">
                                        <div style="font-size:10px !important;">{{props.row.tipo_precio}}</div>
                                    </template>
                                    <template slot="precio_con_impuestos" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">${{ formatNumberDec(props.row.precio_con_impuestos,2) }}</div>
                                    </template>
                                    <template slot="de" slot-scope="props">
                                        <div style="font-size:10px !important;">{{props.row.de}}</div>
                                    </template>
                                    <template slot="a" slot-scope="props">
                                        <div style="font-size:10px !important;">{{props.row.a}}</div>
                                    </template>
                                </v-client-table>
                                </div>
                                </fieldset><br>

                                <div style="text-align: center !important;">
                                    <v-btn small @click.native="modalBusqueda=false">
                                        <v-icon>cancel</v-icon> Cerrar
                                    </v-btn> <br><br>
                                    <v-btn small color="blue" title="Ver Compras (30 días)" v-tippy 
                                        @click="showCompras()"> Historial Compras
                                    </v-btn>
                                </div>


                            </v-col>
                        </v-row>                        
                        
                                <fieldset>                                
                                <div id="filtroPeriodo" style="height: 10vh; margin-bottom:-5px !important; padding-bottom:-5px;">
                                    <v-form ref="form" lazy-validation>
                                    <v-row style="margin-bottom:-5px !important; padding-bottom:-5px !important;">
                                        <v-col sm="12" md="1" lg="1">
                                            <v-checkbox class="compact" :label="bloquear_fechas=='1'?'Desbloquear':'Bloquear'" v-model="bloquear_fechas" true-value="1" false-value="0" @change="set_fechas_periodo()">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col sm="12" md="2" lg="2" v-show="bloquear_fechas=='1'">
                                            <v-text-field disabled readonly v-model="fecha_de_venta" label="Fecha Inicio" 
                                                color="secondary" prepend-icon="event" @blur="fecha_de_venta = parse_date(fecha_de_venta)" :rules="[rules.required, valida_fecha]">
                                            </v-text-field>
                                        </v-col>
                                        <v-col sm="12" md="2" lg="2" v-show="bloquear_fechas=='0'">
                                            <v-menu offset-x ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-on="on" v-model="fecha_de_venta" label="Fecha Inicio" color="secondary" prepend-icon="event" @blur="fecha_de_venta = parse_date(fecha_de_venta)" :rules="[rules.required, valida_fecha]">
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker v-model="fecha_de_venta" color="secondary" scrollable locale="es-mx" @input="menu3 = false">
                                                    <v-spacer />

                                                    <v-btn color="secondary" x-small @click="menu3 = false">
                                                    Cancelar
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col sm="12" md="2" lg="2" v-show="bloquear_fechas=='1'">
                                            <v-text-field  disabled readonly v-model="fecha_a_venta" label="Fecha Fin" 
                                            color="secondary" prepend-icon="event" @blur="fecha_a_venta = parse_date(fecha_a_venta)" :rules="[rules.required, valida_fecha]">
                                            </v-text-field>
                                        </v-col>
                                        <v-col sm="12" md="2" lg="2" v-show="bloquear_fechas=='0'">
                                            <v-menu offset-x ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-on="on" v-model="fecha_a_venta" label="Fecha Fin" color="secondary" prepend-icon="event" @blur="fecha_a_venta = parse_date(fecha_a_venta)" :rules="[rules.required, valida_fecha]">
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker v-model="fecha_a_venta" color="secondary" scrollable locale="es-mx" @input="menu4 = false">
                                                    <v-spacer />

                                                    <v-btn color="secondary" x-small @click="menu4 = false">
                                                    Cancelar
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>                                    
                                        <v-col sm="12" md="2" lg="2">
                                            <v-radio-group dense v-model="periodo" row :rules="[rules.required]">
                                            <template v-slot:label>
                                                <div>Periodo</div>
                                            </template>
                                            <v-radio label="Día" value="1"></v-radio>
                                            <v-radio label="Mes" value="2"></v-radio>
                                            <v-radio label="Año" value="3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col sm="12" md="1" lg="1">
                                            <v-text-field label="Plazo" v-model="plazo_calculo" :rules="[rules.required]"></v-text-field>                                                                                        
                                        </v-col>
                                        <v-col sm="12" md="2" lg="2">    
                                            <br>                                        
                                            <v-btn v-show="id_articulo_calculo!=''" x-small color="info"
                                                @click.native="calcular_compras_ventas()"
                                                >Calcular</v-btn>
                                        </v-col>
                                    </v-row>
                                    </v-form>
                                </div>
                                </fieldset>
                            
                        <v-row dense>
                            <v-col sm="6" lg="6" md="6">
                                
                                <span><strong>Compras</strong> <v-btn v-show="loadingCompras" fab x-small color="gray" :loading="loadingCompras">...</v-btn></span>
                                <div id="tableCompras" style="height: 15vh; overflow-y: scroll; overflow-x: scroll;">
                                <v-client-table ref="vuetable_compras" :data="items_compras" :columns="compras.columns" :options="compras.options">
                                    <template slot="fecha" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{props.row.fecha}}</div>
                                    </template>
                                    <template slot="existencia" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{formatNumberDec(props.row.existencia,2)}}</div>
                                    </template>
                                    <template slot="cantidad" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center">{{props.row.cantidad}}</div>
                                    </template>
                                    <template slot="precio" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">${{ formatNumberDec(props.row.precio,2)}}</div>
                                    </template>
                                    <template slot="proveedor" slot-scope="props">
                                        <div class="columnaProveedor" style="font-size:10px !important;">{{props.row.proveedor}}</div>
                                    </template>
                                    <template slot="orden" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center">{{props.row.orden}}</div>
                                    </template>
                                    <template slot="total" slot-scope="props">
                                        <div style="font-size:10px !important; text-align:center">

                                            <v-icon small dark color="blue" @click.native="calcular_fecha(props.row.fecha)" title="Calcular">refresh</v-icon>

                                        </div>
                                    </template>                                    

                                </v-client-table>
                                </div>
                                    <span title="Es la cantidad total comprada en el periodo seleccionado"><strong>Cantidad Comprada del Periodo: </strong>{{total_compra.toFixed(4)}}</span><br>
                                    <strong>Cantidad Promedio Diaria: </strong>{{promedio_compra}}<br>
                                    <strong>Precio Promedio: </strong>${{precio_promedio_compra.toFixed(4)}}
                                
                            </v-col>
                            <v-col sm="6" lg="6" md="6">
                                <span><strong>Ventas</strong> <v-btn v-show="loadingVentas" fab x-small color="gray" :loading="loadingVentas">...</v-btn></span>
                                <div id="tableVentas" style="height: 15vh; overflow-y: scroll; overflow-x: scroll;">
                                <v-client-table ref="vuetable_ventas" :data="items_ventas" :columns="ventas.columns" :options="ventas.options">
                                    <template slot="fecha" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{props.row.fecha}}</div>
                                    </template>
                                    <template slot="cantidad" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{props.row.cantidad}}</div>
                                    </template>
                                    <template slot="precio" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">${{formatNumberDec(props.row.precio,2)}}</div>
                                    </template>

                                </v-client-table>
                                </div>
                                <v-row>
                                    <v-col sm="12" md="4" lg="4">
                                    <span title="Es la cantidad total vendida en el periodo seleccionado"><strong>Cantidad Vendida del Periodo: </strong>{{total_venta.toFixed(4)}}</span><br>
                                    <strong>Cantidad Promedio Diaria: </strong>{{promedio_venta}}<br>
                                    <strong>Precio Promedio: </strong>${{precio_promedio_venta.toFixed(4)}}
                                    </v-col>
                                    <v-col sm="12" md="4" lg="4">
                                    <span :title="'Promedio x Plazo = '+promedio_venta+' x '+plazo_calculo"><strong>Mínimo: </strong>{{minimo}}</span><br>
                                    <span :title="'Promedio x Plazo + Minimo = '+promedio_venta+' x '+plazo_calculo+' + '+minimo"><strong>Reorden: </strong>{{reorden}}</span><br>
                                    <span :title="'Promedio x (Plazo + 30 dias) + Minimo = '+promedio_venta+' x ('+(parseInt(plazo_calculo)+30)+') + '+minimo+'  (Suponiendo que se quiere surtir durante 30 días)'"><strong>Máximo: </strong>{{maximo}}</span><br>  
                                    </v-col>
                                    <v-col sm="12" md="4" lg="4">
                                    <strong>Existencia: </strong>{{existencia_articulo}}<br>
                                    <!--strong>Consumo Mínimo: </strong>{{consumo_minimo}}<br>
                                    <strong>Consumo Medio: </strong>{{consumo_medio}}<br>
                                    <strong>Consumo Máximo: </strong>{{consumo_maximo}}<br-->
                                    <span :title="'Máximo - Existencia = '+maximo+' - '+existencia_articulo"><strong>Cantidad a Pedir: </strong>{{cantidad_pedir}}</span>
                                    </v-col>
                                </v-row>                                
                            </v-col>
                        </v-row>
                    </v-tab-item>

                        <v-tab-item key="pedido" style="margin-left:10px;margin-right:0px;">
                            <v-card>                                
                                <v-card-text>
                                    <v-container grid-list-md> 
                                        <div style="text-align:center">  
                                            Proveedor: <span v-if="model_vista.proveedor!=null && model_vista.proveedor!=''" 
                                                style="font-weight:bold;background-color:lightblue;"> {{model_vista.proveedor.nombre_proveedor}}
                                            </span>                                        
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   
                                            Usuario: <span style="font-weight:bold;background-color:lightblue;">  {{usuario}}
                                            </span></div>   
                                        <span style="font-weight:bold;color:red">{{mensaje_tab_pedido}}</span>                                  
                                        <v-client-table ref="vuetablearticulosorden" :data="model_vista.articulos" :columns="columnsArticulosVista" 
                                            :options="optionsArticulosVista" class="elevation-2 pa-2" :key="keyTableArticulosOrden"
                                            >
                                            <template slot="codigo_articulo" slot-scope="props">
                                                {{ props.row.codigo_articulo.join(", ") }}
                                            </template>
                                            <template slot="nombre_articulo" slot-scope="props">
                                                <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.nombre_articulo }}</strong></span>
                                            </template>
                                            <template slot="descripcion" slot-scope="props">
                                                <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.descripcion }}</strong></span>
                                            </template>
                                            <template slot="fecha_recibio" slot-scope="props">
                                                {{ parse_date_time_mx(props.row.fecha_recibio)}}
                                            </template>
                                            <template slot="precio_compra" slot-scope="props">
                                                <div style="text-align:right">${{ formatNumberDec(props.row.precio_compra,4) }}</div>
                                            </template>
                                            <template slot="precio_sin_iva" slot-scope="props">
                                                <!--div style="text-align:right">${{ formatNumberDec(props.row.precio_sin_iva,4) }}</div-->
                                                $<input small style="text-align:right;background-color: white!important;border-style:inset !important;width:100px;" 
                                                    v-model="props.row.precio_sin_iva"
                                                    :ref="'articulo_precio_sin_iva_'+props.row.id_articulo"
                                                    @input="calculaPrecioProveedorInput(props.row, 'articulo_precio_sin_iva_'+props.row.id_articulo, model_vista.factura_descuento)"
                                                    @keyup="soloNumeros(props.row.precio_sin_iva)"
                                                    @blur="scheduleSave('saveCambiosOrden',props.row)"
                                                    >
                                                
                                            </template>
                                            <template slot="iva" slot-scope="props">
                                                <div style="text-align:right">{{ props.row.iva }}%</div>
                                            </template>
                                            <template slot="precio_con_iva" slot-scope="props">
                                                <!--div style="text-align:right">${{ formatNumberDec(props.row.precio_con_iva,4) }}</div-->
                                                $<input small style="text-align:right;background-color: white!important;border-style:inset !important;width:100px;" 
                                                    v-model="props.row.precio_con_iva"
                                                    :ref="'articulo_precio_con_iva_'+props.row.id_articulo"
                                                    @input="calculaPrecioSinIVAInput(props.row, 'articulo_precio_con_iva_'+props.row.id_articulo, model_vista.factura_descuento)"
                                                    @keyup="soloNumeros(props.row.precio_con_iva)"
                                                    @blur="scheduleSave('saveCambiosOrden',props.row)"
                                                    >
                                            </template>
                                            <template slot="observaciones_proveedor" slot-scope="props">
                                                <input small style="background-color: white!important;border-style:inset !important;width:120px;" 
                                                    v-model="props.row.observaciones_proveedor"
                                                    @blur="saveCambiosOrden(props.row)"
                                                    >
                                            </template>   
                                            <template slot="existencia_articulo" slot-scope="props">
                                                <div style="text-align:right">{{ formatNumberDec(props.row.existencia_articulo,2) }}</div>
                                            </template>                             
                                            <template slot="cantidad" slot-scope="props">
                                                <input style="text-align:right;background-color: white!important;border-style:inset !important;width:70px;" 
                                                    v-model="props.row.cantidad"
                                                    :ref="'articulo_cantidad_'+props.row.id_articulo"
                                                    @input="actualizaArticuloOrdenInput(props.row, 'articulo_cantidad_'+props.row.id_articulo)"
                                                    @keyup="soloNumeros(props.row.cantidad)"
                                                    @blur="scheduleSave('saveCambiosOrden',props.row)"
                                                    >
                                            </template>                                
                                            
                                            <template slot="monto" slot-scope="props">
                                                <div style="text-align:right">${{ getMonto(props.row) }}</div>
                                            </template>
                                            <template slot="actions" slot-scope="props">
                                                <v-speed-dial direction="left" open-on-hover>
                                                    <template v-slot:activator>
                                                        <v-btn x-small fab dark color="primary">
                                                            <v-icon v-if="fab[props.row.id_articulo]">mdi-close</v-icon>
                                                            <v-icon v-else>reorder</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-btn x-small fab dark color="pink" @click.native="deleteArticulo(props.row.id_articulo)" title="Descartar Artículo" v-tippy>
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                    &nbsp;
                                                    <v-btn x-small fab dark color="teal darken-1" @click.native="editArticulo(props.row)" title="Editar Cantidad" v-tippy>
                                                        <v-icon>edit</v-icon>
                                                    </v-btn> &nbsp;
                                                    <v-btn x-small fab dark color="yellow darken-1" @click.native="ingresarCantidadAbastos(props.row)"
                                                        v-show="(model_vista.tipo_sucursal=='matriz' && ['Orden Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus))"
                                                        title="Marcar Abastos" v-tippy>
                                                        <v-icon>bookmark_border</v-icon>
                                                    </v-btn>
                                                </v-speed-dial>
                                            </template>
                                        </v-client-table>
                                            
                                    </v-container>

                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click.native="modalBusqueda=false">
                                        <v-icon>cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>


                    <!--/v-container-->

            </v-card-text>
            
        </v-card>
    </v-dialog>

    <v-dialog v-model="modal_compras" max-width="85%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Historial Compras (Últimos 30 días) &nbsp;:::&nbsp; {{articulo_editable}}</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_compras = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> PRECIO PROMEDIO DE COMPRA: <v-btn small text :loading="loadingPrecioPromedio" title="El Precio Promedio se calcula en base al precio de compra de las existencias. Si muestra 0 es que no hay existencias positivas del producto." v-tippy>${{ parseFloat(precio_promedio_compra).toFixed(2) }}</v-btn> </strong>
            
            <v-client-table ref="vuetable_compras" :data="items_compras_historico" :columns="compras_historico.columns" :options="compras_historico.options">
                <template slot="no_orden" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.no_orden}}</div>
                </template>
                <template slot="fecha" slot-scope="props">
                    <div style="font-size:10px !important;">{{parse_date_time(props.row.fecha)}}</div>
                </template>
                <template slot="estatus" slot-scope="props">
                    <div v-if="props.row.estatus=='Orden Finalizada'" style="font-weight:bold;color:green;font-size:10px !important;">{{props.row.estatus}}</div>
                    <div v-else style="font-weight:bold;color:orange;font-size:10px !important;">{{props.row.estatus}}</div>
                </template>
                <template slot="existencia" slot-scope="props">
                    <div style="text-align:center !important;font-size:10px !important;">{{formatNumberDecCero(props.row.existencia,2)}}</div>
                </template>
                <template slot="cantidad" slot-scope="props">
                    <div style="text-align:center !important;font-size:10px !important;">{{props.row.cantidad}}</div>
                </template>
                <template slot="cantidad_recibida" slot-scope="props">
                    <div v-if="props.row.cantidad > props.row.cantidad_recibida" style="color:red;font-weight:bold;text-align:center !important;font-size:10px !important;">{{props.row.cantidad_recibida}}</div>
                    <div v-else style="color:green;font-weight:bold;text-align:center !important;font-size:10px !important;">{{props.row.cantidad_recibida}}</div>
                </template>
                <template slot="fecha_estimada" slot-scope="props">
                    <div style="font-size:10px !important;">{{parse_date(props.row.fecha_estimada)}}</div>
                </template>
                <template slot="fecha_recibio" slot-scope="props">
                    <div style="font-size:10px !important;">{{parse_date_time(props.row.fecha_recibio)}}</div>
                </template>
                <template slot="proveedor" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.proveedor}}</div>
                </template>
                <template slot="observaciones_orden" slot-scope="props">
                    <div class="class_observaciones" style="font-size:8px !important;">{{props.row.observaciones_orden}}</div>
                </template>
                <template slot="observaciones_entrada" slot-scope="props">
                    <div class="class_observaciones" style="font-size:8px !important;">{{props.row.observaciones_entrada}}</div>
                </template>
                
          </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_compras = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL PEDIDOS VENCIDOS -->
  <v-dialog v-model="modal_vencidos" max-width="85%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">event</v-icon>&nbsp;
          <span class="subheading">
            <strong>Pedidos con Fecha de Entrega Vencida</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_vencidos = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>    
        <v-btn fab text v-show="loading_counter" :loading="loading_counter"></v-btn>    
            <v-client-table ref="vuetable_compras" :data="pedidos_vencidos" :columns="vencidos_options.columns" :options="vencidos_options.options">
                <template slot="no_orden" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.no_orden}}</div>
                </template>
                <template slot="fecha" slot-scope="props">
                    <div style="font-size:10px !important;">{{parse_date_time(props.row.fecha)}}</div>
                </template>
                <template slot="estatus" slot-scope="props">
                    <div v-if="props.row.estatus=='Orden Finalizada'" style="font-weight:bold;color:green;font-size:10px !important;">{{props.row.estatus}}</div>
                    <div v-else style="font-weight:bold;color:orange;font-size:10px !important;">{{props.row.estatus}}</div>
                </template>
                
                <template slot="fecha_estimada" slot-scope="props">
                    <div style="font-weight:bold;font-size:10px !important;">{{parse_date(props.row.fecha_estimada)}}
                        &nbsp;<v-icon color="primary" small @click.native="editarFechaEntrega(props.row)">edit</v-icon>
                    </div>
                    
                </template>
                <template slot="fecha_recibio" slot-scope="props">
                    <div style="font-size:10px !important;">{{parse_date_time(props.row.fecha_recibio)}}</div>
                </template>
                <template slot="proveedor.nombre_proveedor" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.proveedor.nombre_proveedor}}</div>
                </template>
                <template slot="proveedor.contacto" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.proveedor.contacto}}</div>
                </template>
                <template slot="proveedor.email" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.proveedor.email}}</div>
                </template>
                <template slot="proveedor.telefono_1" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.proveedor.telefono_1}}</div>
                </template>
                
                
          </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_vencidos = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

    <!-- MODAL ARTICULOS -->
    <v-dialog v-model="modalArticulos" max-width="95%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Orden de Compra: {{model_vista.nombre_sucursal_destino}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Proveedor: {{model_vista.proveedor.nombre_proveedor}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalArticulos = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-row>
                        <v-col lg="7" md="7" sm="7">
                            <v-row>
                                <v-col lg="4" md="4" sm="4" v-if="model_vista.proveedor!=null && model_vista.proveedor!=''">
                                    <b>Proveedor:</b> {{model_vista.proveedor.nombre_proveedor}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4">
                                    <b>Estatus:</b> {{model_vista.estatus}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4" v-show="model_vista.observaciones_orden!=null && model_vista.observaciones_orden!=''">
                                    <b>Observaciones de Orden de Compra:</b><br>
                                    {{model_vista.observaciones_orden}}
                                </v-col>
                            </v-row><br>
                            <v-row>                                                
                                <v-col lg="12" md="12" sm="12" v-show="observaciones_entrada_facturas!=null && observaciones_entrada_facturas.length>0">
                                    <b>Observaciones de Entrada de Mercancía:</b><br>
                                    <span v-for="(texto, index) in observaciones_entrada_facturas">
                                        {{texto}}<br>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col lg="5" md="5" sm="5">
                            <v-row>
                                <v-col style="color:black;text-align:right !important;" title="Cantidad x Precio Pedido"><h5>TOTAL PEDIDO: $ {{ formatNumberDec(get_total_orden(model_vista.articulos),2) }}</h5></v-col>
                            </v-row>
                            <v-row>
                                <v-col style="color:DarkGreen;text-align:right !important;" title="Cantidad Recibida x Precio Factura"><h5>TOTAL MERCANCÍA RECIBIDA: $ {{ formatNumberDec(total_factura_mercancia_recibida, 2) }}</h5></v-col>
                            </v-row>
                            <v-row>
                                <v-col style="color:red;text-align:right !important;" title="Total Cotizado - Total Recibido"><h6>DIFERENCIA: $ {{ formatNumberDec(parseFloat(get_total_orden(model_vista.articulos)) - parseFloat(total_factura_mercancia_recibida), 2) }}</h6></v-col>
                            </v-row>
                        </v-col>
                    </v-row>                            
                            <v-client-table ref="vuetablearticulosorden" :data="model_vista.articulos" :columns="columnsArticulosVista" 
                                :options="optionsArticulosVista" class="elevation-2 pa-2" :key="keyTableArticulosOrden"
                                >
                                <template slot="codigo_articulo" slot-scope="props">
                                    {{ props.row.codigo_articulo.join(", ") }}
                                </template>
                                <template slot="nombre_articulo" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.nombre_articulo }}</strong></span>
                                </template>
                                <template slot="descripcion" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.descripcion }}</strong></span>
                                </template>
                                <template slot="fecha_recibio" slot-scope="props">
                                    {{ parse_date_time_mx(props.row.fecha_recibio)}}
                                </template>
                                <template slot="precio_compra" slot-scope="props">
                                    <div style="text-align:center">${{ formatNumberDec(props.row.precio_compra,4) }}</div>
                                </template>
                                <template slot="precio_sin_iva" slot-scope="props">
                                    <!--div style="text-align:right">${{ formatNumberDec(props.row.precio_sin_iva,4) }}</div-->
                                    $<input small style="text-align:center;background-color: white!important;border-style:inset !important;width:100px;" 
                                        v-model="props.row.precio_sin_iva"
                                        :ref="'articulo_precio_sin_iva_'+props.row.id_articulo"
                                        @input="calculaPrecioProveedorInput(props.row, 'articulo_precio_sin_iva_'+props.row.id_articulo, model_vista.factura_descuento)"
                                        @keyup="soloNumeros(props.row.precio_sin_iva)"
                                        @blur="scheduleSave('saveCambiosOrden',props.row)"
                                        >
                                    
                                </template>
                                <template slot="iva" slot-scope="props">
                                    <div style="text-align:center">{{ props.row.iva }}%</div>
                                </template>
                                <template slot="precio_con_iva" slot-scope="props">
                                    <!--div style="text-align:center">${{ formatNumberDec(props.row.precio_con_iva,4) }}</div-->
                                    $<input small style="text-align:center;background-color: white!important;border-style:inset !important;width:100px;" 
                                        v-model="props.row.precio_con_iva"
                                        :ref="'articulo_precio_con_iva_'+props.row.id_articulo"
                                        @input="calculaPrecioSinIVAInput(props.row, 'articulo_precio_con_iva_'+props.row.id_articulo, model_vista.factura_descuento)"
                                        @keyup="soloNumeros(props.row.precio_con_iva)"
                                        @blur="scheduleSave('saveCambiosOrden',props.row)"
                                        >
                                </template>
                                <template slot="observaciones_proveedor" slot-scope="props">
                                    <input small style="background-color: white!important;border-style:inset !important;width:120px;" 
                                        v-model="props.row.observaciones_proveedor"
                                        @blur="saveCambiosOrden(props.row)"
                                        >
                                </template>   
                                <template slot="existencia_articulo" slot-scope="props">
                                    <div style="text-align:center">{{ formatNumberDec(props.row.existencia_articulo,2) }}</div>
                                </template>                             
                                <template slot="cantidad" slot-scope="props">
                                    <input style="text-align:center;background-color: white!important;border-style:inset !important;width:70px;" 
                                        v-model="props.row.cantidad"
                                        :ref="'articulo_cantidad_'+props.row.id_articulo"
                                        @input="actualizaArticuloOrdenInput(props.row, 'articulo_cantidad_'+props.row.id_articulo)"
                                        @keyup="soloNumeros(props.row.cantidad)"
                                        @blur="scheduleSave('saveCambiosOrden',props.row)"
                                        >
                                </template>    

                                <template slot="cantidad_recibida_facturas" slot-scope="props">
                                    <div style="text-align:center;" :class="(props.row.cantidad_recibida_facturas==undefined || (props.row.cantidad_recibida_facturas && parseFloat(props.row.cantidad_recibida_facturas) < parseFloat(props.row.cantidad))?'bg-red':'bg-green')">
                                        {{ props.row.cantidad_recibida_facturas }} &nbsp;
                                    </div>
                                </template>                            
                                
                                <template slot="monto" slot-scope="props">
                                    <div style="text-align:center">${{ getMonto(props.row) }}</div>
                                </template>
                                <template slot="actions" slot-scope="props">
                                    <v-speed-dial direction="left" open-on-hover>
                                        <template v-slot:activator>
                                            <v-btn x-small fab dark color="primary">
                                                <v-icon v-if="fab[props.row.id_articulo]">mdi-close</v-icon>
                                                <v-icon v-else>reorder</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-btn x-small fab dark color="pink" @click.native="deleteArticulo(props.row.id_articulo)" title="Descartar Artículo" v-tippy>
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                        &nbsp;
                                        <v-btn x-small fab dark color="teal darken-1" @click.native="editArticulo(props.row)" title="Editar Cantidad" v-tippy>
                                            <v-icon>edit</v-icon>
                                        </v-btn> &nbsp;
                                        <v-btn x-small fab dark color="yellow darken-1" @click.native="ingresarCantidadAbastos(props.row)"
                                            v-show="(model_vista.tipo_sucursal=='matriz' && ['Orden Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus))"
                                            title="Marcar Abastos" v-tippy>
                                            <v-icon>bookmark_border</v-icon>
                                        </v-btn>
                                    </v-speed-dial>
                                </template>
                            </v-client-table>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.native="agregarArticuloEditable()">
                    <v-icon>add</v-icon> Agregar Artículo
                </v-btn>                
                <v-btn color="success">
                    <v-icon>done</v-icon> Guardar Cambios
                </v-btn>
                <v-btn @click.native="modalArticulos=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

        <!-- MODAL ARTICULOS FACTURA-->
    <v-dialog v-model="modalArticulosFactura" max-width="95%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>SubOrden: {{model_vista_factura.no_orden}} &nbsp;&nbsp;&nbsp;Factura: {{model_vista_factura.factura_folio}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalArticulosFactura = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-row>
                        <v-col lg="8" md="8" sm="8">
                            <v-row>
                                <v-col lg="4" md="4" sm="4" v-if="model_vista_factura.proveedor!=null && model_vista_factura.proveedor!=''">
                                    <b>Proveedor:</b> {{model_vista_factura.proveedor.nombre_proveedor}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4">
                                    <b>Sucursal:</b> {{model_vista_factura.nombre_sucursal_destino}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4">
                                    <b>Estatus:</b> {{model_vista_factura.factura_estatus}}
                                </v-col>
                            </v-row><br>
                            <v-row>
                                <v-col lg="6" md="6" sm="6" v-show="model_vista_factura.observaciones_orden!=null && model_vista_factura.observaciones_orden!=''">
                                    <b>Observaciones de Factura:</b><br>
                                    {{model_vista_factura.observaciones_orden}}
                                </v-col>
                                <v-col lg="6" md="6" sm="6" v-show="model_vista_factura.observaciones_entrada!=null && model_vista_factura.observaciones_entrada!=''">
                                    <b>Observaciones de Entrada de Mercancía:</b><br>
                                    {{model_vista_factura.observaciones_entrada}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="3" md="3" sm="3" v-show="model_vista_factura.factura_descuento!=null && model_vista_factura.factura_descuento!=''">
                                    <b>Descuento Factura:</b><br>
                                    {{model_vista_factura.factura_descuento}} %
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col lg="4" md="4" sm="4">
                            <v-row>
                                <v-col style="color:black;text-align:right !important;" title="Cantidad x Precio Pedido"><h5>TOTAL PEDIDO: $ {{ formatNumberDec(get_total_pedido_factura(model_vista.articulos, model_vista_factura.articulos),2) }}</h5></v-col>                                
                            </v-row>
                            <v-row>                                
                                <v-col style="color:black;text-align:right !important;" title="Cantidad x Precio Factura"><h5>TOTAL FACTURA: $ {{ formatNumberDec(get_total_orden_factura(model_vista_factura.articulos),2) }}</h5></v-col>
                            </v-row>
                            <v-row>                                
                                <v-col style="color:DarkOrange; text-align:right !important;" title="Cantidad Recibida x Precio Factura"><h5>TOTAL MERCANCÍA RECIBIDA: $ {{ formatNumberDec(get_total_orden_recibida(model_vista_factura.articulos),2) }}</h5></v-col>
                            </v-row>
                            <v-row>
                                
                                <v-col style="color:red; text-align:right !important;" title="Recibido Precio Factura - Recibido Precio Pedido"><h6>DIFERENCIA EN PRECIO: $ {{ formatNumberDec(get_total_diferencia(model_vista_factura.articulos),2) }}</h6></v-col>
                            </v-row>
                            <v-row>                                
                                <v-col style="color:red;text-align:right !important;" title="Cantidad Faltante x Precio Factura"><h6>FALTANTE(S): $ {{ formatNumberDec(get_total_faltante(model_vista_factura.articulos),2) }}</h6></v-col>
                            </v-row>
                            <v-row>                                
                                <v-col style="color:red;text-align:right !important;" title="Cantidad Devuelta x Precio Factura"><h6>DEVOLUCIÓN(ES): $ {{ formatNumberDec(get_total_devuelto(model_vista_factura.articulos),2) }}</h6></v-col>
                            </v-row>
                            <v-row>                                
                                <v-col style="color:DarkGreen; text-align:right !important;" title="Cantidad Recibida x Precio Pedido"><h5>TOTAL A PAGAR: $ {{ formatNumberDec(get_total_a_pagar(model_vista_factura.articulos),2) }}</h5></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    
                        
                            
                            <v-client-table ref="vuetablearticulosorden" :data="model_vista_factura.articulos" :columns="columnsArticulosVistaFactura" 
                            :options="optionsArticulosVistaFactura" :key="keyTableArticulosFactura"
                            class="elevation-2 pa-2">
                                <template slot="nombre_articulo" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.nombre_articulo }}</strong></span>
                                </template>
                                <template slot="descripcion" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.descripcion }}</strong></span>
                                </template>
                                <!--template slot="precio_compra" slot-scope="props">
                                    <div style="text-align:right">${{ formatNumberDec(props.row.precio_compra,4) }}</div>
                                </template-->
                                <template slot="precio_pedido" slot-scope="props">
                                    <div style="text-align:center">${{ formatNumberDec(getPrecioPedido(props.row),4) }}</div>
                                </template>
                                <template slot="precio_sin_iva" slot-scope="props">
                                    <div v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                        $<input style="text-align:center;background-color: white!important;border-style:inset !important;width:80px;" 
                                        v-model="props.row.precio_sin_iva"
                                        :ref="'articulo_factura_precio_sin_iva_'+props.row.id_articulo"
                                        @input="calculaPrecioProveedorFacturaInput(props.row, 'articulo_factura_precio_sin_iva_'+props.row.id_articulo, model_vista_factura.factura_descuento)"
                                        @keyup="soloNumeros(props.row.precio_sin_iva)"
                                        @blur="scheduleSave('saveCambiosFactura',props.row)"
                                        >
                                    </div>
                                    <div v-else style="text-align:right">${{ formatNumberDec(props.row.precio_sin_iva,4) }}</div>
                                </template>
                                <template slot="iva" slot-scope="props">
                                    <div style="text-align:center">{{ props.row.iva }}%</div>
                                </template>
                                <template slot="precio_con_iva" slot-scope="props">
                                    <div v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                    $<input style="text-align:center;background-color: white!important;border-style:inset !important;width:80px;" 
                                        v-model="props.row.precio_con_iva"
                                        :ref="'articulo_factura_precio_con_iva_'+props.row.id_articulo"
                                        @input="calculaPrecioSinIVAFacturaInput(props.row, 'articulo_factura_precio_con_iva_'+props.row.id_articulo, model_vista_factura.factura_descuento)"
                                        @keyup="soloNumeros(props.row.precio_con_iva)"
                                        @blur="scheduleSave('saveCambiosFactura',props.row)"
                                        >
                                    </div>
                                    <div v-else style="text-align:center">${{ formatNumberDec(props.row.precio_con_iva,4) }}</div>
                                </template>
                                <template slot="porcentaje_descuento1" slot-scope="props">
                                    <div v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                    <input style="text-align:center;background-color: white!important;border-style:inset !important;width:40px;" 
                                        v-model="props.row.porcentaje_descuento1"
                                        :ref="'articulo_factura_porcentaje_descuento1_'+props.row.id_articulo"
                                        @input="calculaPrecioProveedorFacturaInput(props.row, 'articulo_factura_porcentaje_descuento1_'+props.row.id_articulo, model_vista_factura.factura_descuento)"
                                        @keyup="soloNumeros(props.row.porcentaje_descuento1)"
                                        @blur="scheduleSave('saveCambiosFactura',props.row)"
                                        >%
                                    </div>
                                    <div v-else style="text-align:center">{{ props.row.porcentaje_descuento1 }}%</div>
                                </template>
                                <template slot="porcentaje_descuento2" slot-scope="props">
                                    <div v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                    <input style="text-align:center;background-color: white!important;border-style:inset !important;width:40px;" 
                                        v-model="props.row.porcentaje_descuento2"
                                        :ref="'articulo_factura_porcentaje_descuento2_'+props.row.id_articulo"
                                        @input="calculaPrecioProveedorFacturaInput(props.row, 'articulo_factura_porcentaje_descuento2_'+props.row.id_articulo, model_vista_factura.factura_descuento)"
                                        @keyup="soloNumeros(props.row.porcentaje_descuento2)"
                                        @blur="scheduleSave('saveCambiosFactura',props.row)"
                                        >%
                                    </div>
                                    <div v-else style="text-align:center">{{ props.row.porcentaje_descuento2 }}%</div>
                                </template>
                                <template slot="precio_factura" slot-scope="props">
                                    <div style="text-align:center">${{ formatNumberDec(props.row.precio_factura,4) }}</div>
                                </template>
                                <template slot="diferencia" slot-scope="props">
                                    <div style="text-align:center">${{ formatNumberDec(parseFloat(props.row.precio_factura) - getPrecioPedido(props.row),4) }}</div>
                                </template>
                                <template slot="fecha_recibio" slot-scope="props">
                                    {{ parse_date_time_mx(props.row.fecha_recibio)}}
                                </template>
                                <template slot="existencia_articulo" slot-scope="props">
                                    <div style="text-align:center">{{ formatNumberDec(props.row.existencia_articulo,2) }}</div>
                                </template>                             
                                <template slot="cantidad" slot-scope="props">
                                    <div v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                    <input style="text-align:center;background-color: white!important;border-style:inset !important;width:60px;" 
                                        v-model="props.row.cantidad"
                                        :ref="'articulo_factura_cantidad_'+props.row.id_articulo"
                                        @input="actualizaArticuloFacturaInput(props.row, 'articulo_factura_cantidad_'+props.row.id_articulo)"
                                        @keyup="soloNumeros(props.row.cantidad)"
                                        @blur="scheduleSave('saveCambiosFactura',props.row)"
                                        >
                                    </div>
                                    <div v-else style="text-align:center">{{ props.row.cantidad }}</div>
                                </template>
                                <template slot="cantidad_recibida" slot-scope="props">
                                    <div style="color:green;text-align:center;"><strong>{{ props.row.cantidad_recibida }}</strong></div>
                                </template>
                                <template slot="cantidad_faltante" slot-scope="props">
                                    <div v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                    <input style="color:red;text-align:center;background-color: white!important;border-style:inset !important;width:60px;" 
                                        v-model="props.row.cantidad_faltante"
                                        :ref="'articulo_factura_cantidad_faltante_'+props.row.id_articulo"
                                        @input="actualizaArticuloFacturaInput(props.row, 'articulo_factura_cantidad_faltante_'+props.row.id_articulo)"
                                        @keyup="soloNumeros(props.row.cantidad_faltante)"
                                        @blur="scheduleSave('saveCambiosFactura',props.row)"
                                        >
                                    </div>
                                    <div v-else style="color:red;text-align:center"><strong>{{ props.row.cantidad_faltante }}</strong></div>
                                </template>
                                <template slot="cantidad_devuelta" slot-scope="props">
                                    <div v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                    <input style="color:red;text-align:center;background-color: white!important;border-style:inset !important;width:60px;" 
                                        v-model="props.row.cantidad_devuelta"
                                        :ref="'articulo_factura_cantidad_devuelta_'+props.row.id_articulo"
                                        @input="actualizaArticuloFacturaInput(props.row, 'articulo_factura_cantidad_devuelta_'+props.row.id_articulo)"
                                        @keyup="soloNumeros(props.row.cantidad_devuelta)"
                                        @blur="scheduleSave('saveCambiosFactura',props.row)"
                                        >
                                    </div>
                                    <div v-else style="color:red;text-align:center"><strong>{{ props.row.cantidad_devuelta }}</strong></div>
                                </template>
                                <template slot="monto" slot-scope="props">
                                    <div style="text-align:center">${{ getMonto(props.row) }}</div>
                                </template>
                                <template slot="actions" slot-scope="props" v-if="(model_vista_factura.estatus!='Orden Finalizada' || verificaPermiso('t.compras.editar_factura'))">
                                    <v-speed-dial direction="left" open-on-hover>
                                        <template v-slot:activator>
                                            <v-btn x-small fab dark color="primary">
                                                <v-icon v-if="fab[props.row.id_articulo]">mdi-close</v-icon>
                                                <v-icon v-else>reorder</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-btn x-small fab dark color="teal darken-1" @click.native="editArticuloFactura(props.row)" title="Editar Cantidad" v-tippy>
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                        <v-btn x-small fab dark color="pink" @click.native="deleteArticuloFactura(props.row.id_articulo)" title="Descartar Artículo" v-tippy>
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </v-speed-dial>                                        
                                </template>
                            </v-client-table>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="info" 
                    @click="finalizar_entrada_mercancia()"
                    :disabled="guardando"
                    v-show="model_vista_factura.estatus!='Orden Finalizada'">
                    <v-icon>done_all</v-icon> Finalizar Entrada Mercancía
                </v-btn>&nbsp;
                <v-btn color="yellow darken-2" 
                    @click="showModalCaducidades()"
                    :disabled="guardando">
                    <v-icon>event</v-icon> Capturar Caducidades
                </v-btn>&nbsp;                
                <v-btn color="success">
                    <v-icon>done</v-icon> Guardar Cambios
                </v-btn>&nbsp;
                <v-btn @click.native="modalArticulosFactura=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                
            </v-card-actions>
        </v-card>
    </v-dialog>

        <!-- MODAL CADUCIDADES ARTICULOS FACTURA-->
    <v-dialog v-model="modalCaducidadArticulosFactura" max-width="85%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">event</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>CAPTURA DE CADUCIDADES &nbsp;&nbsp;&nbsp; SubOrden: {{model_vista_factura.no_orden}} &nbsp;&nbsp;&nbsp;Factura: {{model_vista_factura.factura_folio}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCaducidadArticulosFactura = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-row>
                        <v-col lg="12" md="12" sm="12">
                            <v-row>
                                <v-col lg="4" md="4" sm="4" v-if="model_vista_factura.proveedor!=null && model_vista_factura.proveedor!=''">
                                    <b>Proveedor:</b> {{model_vista_factura.proveedor.nombre_proveedor}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4">
                                    <b>Sucursal:</b> {{model_vista_factura.nombre_sucursal_destino}}
                                </v-col>
                                <v-col lg="4" md="4" sm="4">
                                    <b>Estatus:</b> {{model_vista_factura.factura_estatus}}
                                </v-col>
                            </v-row>                            
                        </v-col>                        
                    </v-row>
                    
                        
                            
                            <v-client-table ref="vuetablearticuloscaducidad" :data="model_vista_factura.articulos" 
                            :columns="columnsCaducidadArticulosVistaFactura" 
                            :options="optionsCaducidadArticulosVistaFactura" :key="keyTableCaducidadArticulosFactura"
                            @filter="onFilterChange"
                            class="elevation-2 pa-2">
                                <template slot="nombre_articulo" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.nombre_articulo }}</strong></span>
                                </template>
                                <template slot="descripcion" slot-scope="props">
                                    <span style="font-weight:bolder;font-size:10px;"><strong>{{ props.row.descripcion }}</strong></span>
                                </template>
                                
                                <template slot="precio_factura" slot-scope="props">
                                    <div style="text-align:center">${{ formatNumberDec(props.row.precio_factura,4) }}</div>
                                </template>
                                <template slot="cantidad" slot-scope="props">
                                    <div style="text-align:center">{{ props.row.cantidad }}</div>                                    
                                </template>

                                <template slot="caducidad" slot-scope="props">
                                    <v-checkbox class="compact" v-model="props.row.caducidad" :true-value="1" :false-value="0" 
                                        @change="saveCaducidadArticulo(props.row)"
                                    ></v-checkbox>                                    
                                </template>
                                <template slot="fecha_caducidad" slot-scope="props">
                                    <v-chip x-small color="gray" text-color="black" title="Cambiar Fecha" 
                                        v-tippy @click="showModalFechaCaducidad(props.row)">
                                        <b>{{props.row.fecha_caducidad!=""?props.row.fecha_caducidad.toString().substring(0,10):"N/A"}}</b>
                                    </v-chip>                                        
                                </template>

                                                                
                            </v-client-table>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalCaducidadArticulosFactura=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- modal fecha caducidad-->
    <v-dialog v-model="modalfechacaducidad" max-width="25%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Actualizar Fecha Caducidad</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalfechacaducidad = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                <v-menu offset-x ref="menu_fecha" v-model="menu_fecha" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-text-field v-on="on" v-model="articulo_editable.fecha_caducidad" label="Fecha" color="secondary" prepend-icon="event"
                                      ref="ingresafecha" readonly clearable
                                     @blur="articulo_editable.fecha_caducidad = parseDate(articulo_editable.fecha_caducidad)">
                                    </v-text-field>
                                  </template>

                                  <v-date-picker ref="ingresafecha" v-model="articulo_editable.fecha_caducidad" color="secondary" scrollable locale="es-mx" @input="menu_fecha = false">
                                    <v-spacer />

                                    <v-btn color="secondary" x-small @click="menu_fecha = false">
                                      Cancelar
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalfechacaducidad = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="saveFechaCaducidadArticulo()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                                                        
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <!-- modal fecha de entrega-->
    <v-dialog v-model="modalFechaEntrega" max-width="25%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Fecha de Entrega Estimada</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalFechaEntrega = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formfechaentrega" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                <v-menu offset-x ref="menu_fecha" v-model="menu_fecha" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-text-field v-on="on" v-model="model.fecha_entrega_estimada" label="Fecha Estimada" color="secondary" prepend-icon="event"
                                      ref="ingresafecha" readonly clearable :rules="[rules.required]"
                                     @blur="model.fecha_entrega_estimada = parseDate(model.fecha_entrega_estimada)">
                                    </v-text-field>
                                  </template>

                                  <v-date-picker ref="ingresafecha" v-model="model.fecha_entrega_estimada" color="secondary" scrollable locale="es-mx" @input="menu_fecha = false">
                                    <v-spacer />

                                    <v-btn color="secondary" x-small @click="menu_fecha = false">
                                      Cancelar
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalFechaEntrega = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="guardarFechaEntrega()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                                                        
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <!-- MODAL EDITAR ARTICULO -->
    <v-dialog v-model="modalEditable" max-width="50%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Editar Artículo</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon class="close_modal">
                    <v-icon class="white--text"></v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formeditable" lazy-validation>
                    <v-container grid-list-md>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <!--v-text-field label="Articulo" v-model="articulo_editable.nombre_articulo" readonly></v-text-field-->
                                <v-autocomplete v-model="id_articulo_editable" :items="articulos" :hide-no-data="true"
                                    :loading="loadingArticulos" ref="selectarticulo" placeholder="Ingrese nombre de artículo"
                                    prepend-icon="search" item-text="nombre" item-value="_id"
                                    @change="getArticuloEditable()" label="Artículo">
                                    </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Último Precio Compra" v-model="articulo_editable.precio_compra" 
                                disabled readonly></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Existencia" v-model="articulo_editable.existencia_articulo" disabled readonly></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Cantidad" v-model="articulo_editable.cantidad"
                                :rules="[rules.required, validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Precio Proveedor S/Impuestos" v-model="articulo_editable.precio_sin_iva"
                                @input="calculaPrecioProveedor(model_vista.factura_descuento)"
                                :rules="[validaCantidadFloat]"></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="% Impuestos Artículo" v-model="articulo_editable.iva"
                                disabled readonly
                                @input="calculaPrecioProveedor(model_vista.factura_descuento)"
                                :rules="[validaCantidadFloatCero]"
                                :hint="getImpuestosString(articulo_editable.impuestos_articulo)" persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Precio Proveedor C/Impuestos" v-model="articulo_editable.precio_con_iva" 
                                @input="calculaPrecioSinIVA(model_vista.factura_descuento)" :rules="[validaCantidadFloat]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="8" md="8" lg="8">
                                <v-text-field label="Observaciones Proveedor" v-model="articulo_editable.observaciones_proveedor"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalEditable=false">
                    <v-icon>cancel</v-icon> Cancelar
                </v-btn>
                <v-btn color="success" @click.native="saveNuevaCantidadArticulo()">
                    <v-icon>done</v-icon> Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- MODAL EDITAR ARTICULO FACTURA -->
    <v-dialog v-model="modalEditableFactura" max-width="50%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Editar Artículo Factura</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon class="close_modal">
                    <v-icon class="white--text"></v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formeditable" lazy-validation>
                    <v-container grid-list-md>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-text-field label="Articulo" v-model="articulo_editable.nombre_articulo" readonly></v-text-field>                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Último Precio Compra" v-model="articulo_editable.precio_compra" 
                                disabled readonly></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Existencia" v-model="articulo_editable.existencia_articulo" disabled readonly></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Cantidad Factura" v-model="articulo_editable.cantidad"
                                :rules="[rules.required, validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Precio Factura S/Impuestos" v-model="articulo_editable.precio_sin_iva"
                                @input="calculaPrecioProveedorFactura(model_vista_factura.factura_descuento)"
                                :rules="[validaCantidadFloat]"></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="% Impuestos Artículo" v-model="articulo_editable.iva"
                                disabled readonly
                                @input="calculaPrecioProveedorFactura(model_vista_factura.factura_descuento)"
                                :rules="[validaCantidadFloatCero]"
                                :hint="getImpuestosString(articulo_editable.impuestos_articulo)" persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Precio Factura C/Impuestos" v-model="articulo_editable.precio_con_iva" 
                                @input="calculaPrecioSinIVAFactura(model_vista_factura.factura_descuento)" :rules="[validaCantidadFloat]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="%Desc Factura" v-model="model_vista_factura.factura_descuento"
                                disabled readonly></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="%Desc1" v-model="articulo_editable.porcentaje_descuento1"
                                @input="calculaPrecioProveedorFactura(model_vista_factura.factura_descuento)"
                                :rules="[validaCantidadFloatCero]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="%Desc2" v-model="articulo_editable.porcentaje_descuento2"
                                @input="calculaPrecioProveedorFactura(model_vista_factura.factura_descuento)"
                                :rules="[validaCantidadFloatCero]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Precio Final Factura" v-model="articulo_editable.precio_proveedor" 
                                disabled readonly
                                :rules="[validaCantidadFloat]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Cantidad Faltante" v-model="articulo_editable.cantidad_faltante"
                                :rules="[validaCantidadFloatCero]"></v-text-field>
                            </v-col>
                            <v-col sm="4" md="4" lg="4">
                                <v-text-field label="Cantidad Devuelta" v-model="articulo_editable.cantidad_devuelta"
                                :rules="[validaCantidadFloatCero]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="8" md="8" lg="8">
                                <v-text-field label="Observaciones" v-model="articulo_editable.observaciones_proveedor"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalEditableFactura=false">
                    <v-icon>cancel</v-icon> Cancelar
                </v-btn>
                <v-btn color="success" @click.native="saveNuevaCantidadArticuloFactura()">
                    <v-icon>done</v-icon> Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- MODAL OBSERVACION -->
    <v-dialog v-model="modalObservacion" max-width="50%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Editar Observaciones</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon class="close_modal">
                    <v-icon class="white--text"></v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formobservacion" lazy-validation>
                    <v-container grid-list-md>
                        <v-row v-if="model.secuencia?false:true">
                            <v-col sm="12" md="12" lg="12">
                                <v-textarea outlined :label="'Condiciones Proveedor'" rows="4" v-model="model.condiciones_orden"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-textarea outlined :label="model.secuencia?'Observaciones de Factura':'Observaciones de Orden de Compra'" rows="4" v-model="model.observaciones_orden"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-textarea outlined label="Observaciones de entrada de mercancía" rows="4" v-model="model.observaciones_entrada"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalObservacion=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="success" @click.native="guardarObservaciones()">
                    <v-icon>done</v-icon> Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- MODAL PROVEEDOR: selecciona proveedor para crear orden directa -->
    <v-dialog v-model="modalProveedor" max-width="35%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Seleccione Proveedor para Orden Directa</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalProveedor = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

                    <v-container grid-list-md>
                        <span>Orden Directa es cuando no se hace pedido al proveedor, pero se recibe la factura y mercancía.</span>
                        <v-form ref="formproveedor">
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-autocomplete v-model="id_proveedor" :items="proveedores_combo" :hide-no-data="true"
                                    ref="selectproveedor" :loading="loadingProveedores"
                                    placeholder="Ingrese nombre de proveedor"
                                    prepend-icon="search" clearable item-text="nombre" item-value="_id"
                                    :rules="[rules.required]"
                                    label="Proveedor">
                                </v-autocomplete>
                            </v-col>                            
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <v-autocomplete v-model="sucursal_select" :items="[{'nombre':'MATRIZ','id':'matriz'},{'nombre':'ABASTOS','id':'abastos'}]" :hide-no-data="true"
                                    placeholder="Seleccione Sucursal"
                                    item-text="nombre" item-value="id"
                                    :rules="[rules.required]"
                                    label="Sucursal">
                                </v-autocomplete>
                            </v-col>                            
                        </v-row>
                        </v-form>
                                                
                    </v-container>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalProveedor=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>&nbsp;
                <v-btn color="success" @click.native="generarOrdenDirecta()">
                    <v-icon>done</v-icon> Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>   

    <!-- MODAL CAMBIO PROVEEDOR: para cambiar el proveedor a la orden y sus facturas -->
    <v-dialog v-model="modalCambioProveedor" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Editar Proveedor al Pedido: {{model_vista.no_orden}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCambioProveedor = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formproveedor" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="10" md="10" lg="10" style="text-align:center !important;">
                                <v-autocomplete v-model="proveedor_editable" :items="proveedores_combo" :hide-no-data="true" 
                                item-text="nombre" item-value="_id" 
                                label="Seleccione Proveedor" :rules="[rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalCambioProveedor = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="changeProveedor()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <!-- MODAL DATOS FACTURA -->
    <v-dialog v-model="modalFactura" max-width="90%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Datos de la Factura &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Proveedor: {{model_vista_factura.proveedor.nombre_proveedor}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalFactura = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidadplazo" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Folio Factura" v-model="model_vista_factura.factura_folio" 
                                    ref="ingresafolio" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Folio SAT" v-model="model_vista_factura.factura_folio_sat" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-on="on" v-model="model_vista_factura.factura_fecha" label="Fecha Factura"
                                        @input="setVencimiento()" readonly clearable
                                        color="secondary" prepend-icon="event" @blur="model_vista_factura.factura_fecha = parse_date(model_vista_factura.factura_fecha);" :rules="[rules.required, valida_fecha]">
                                        </v-text-field>
                                    </template>

                                    <v-date-picker v-model="model_vista_factura.factura_fecha" color="secondary" scrollable
                                        locale="es-mx" @input="menu1 = false">
                                        <v-spacer />

                                        <v-btn color="secondary" x-small @click="menu1 = false">
                                        Cancelar
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-autocomplete v-model="model_vista_factura.factura_estatus"
                                :items="estatus_factura" :hide-no-data="true"
                                item-text="val" item-value="val" label="Estatus" :rules="[rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" md="3" lg="3">
                                <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-on="on" v-model="model_vista_factura.fecha_recibio_mercancia" label="Fecha Recibió Mercancía"
                                        @input="setVencimiento()" readonly clearable
                                        color="secondary" prepend-icon="event" @blur="model_vista_factura.fecha_recibio_mercancia = parse_date(model_vista_factura.fecha_recibio_mercancia);" :rules="[valida_fecha]">
                                        </v-text-field>
                                    </template>

                                    <v-date-picker v-model="model_vista_factura.fecha_recibio_mercancia" color="secondary" scrollable
                                        @change="setVencimiento()"
                                        locale="es-mx" @input="menu2 = false">
                                        <v-spacer />

                                        <v-btn color="secondary" x-small @click="menu2 = false">
                                        Cancelar
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col sm="2" md="2" lg="2">
                                <v-text-field label="Días Plazo para Pago" v-model="model_vista_factura.plazo_pago"
                                    @input="setVencimiento()"
                                    :rules="[validaCantidadEntera]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Fecha Vencimiento" v-model="model_vista_factura.factura_fecha_vencimiento" disabled readonly
                                    ></v-text-field>
                            </v-col>                        
                            <v-col sm="2" md="2" lg="2">
                                <v-text-field label="% Descuento" v-model="model_vista_factura.factura_descuento"
                                hint="Descuento sobre el total de la mercancía recibida"
                                @input="setDescuento()" :rules="[ValidaPorcentajeMaximo]">
                                </v-text-field>
                            </v-col>
                            <v-col sm="2" md="2" lg="2">
                                <v-text-field label="Concepto Descuento" v-model="model_vista_factura.factura_descuento_concepto"></v-text-field>
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col sm="6" md="6" lg="6">
                                <v-text-field label="Observaciones Factura" v-model="model_vista_factura.factura_observaciones"></v-text-field>
                            </v-col>
                            
                        </v-row>
                         <v-row>
                            <v-col>                                                            
                                <v-layout row wrap>
                                    <v-flex col xs6 s6 m6 l6>
                                        <div style="display: flex;justify-content: space-between;">
                                            <div style="text-align:left" >
                                               <h4><v-icon>list</v-icon> Artículos en el Pedido</h4> 
                                            </div>
                                            <div style="text-align:right">
                                                <v-btn small color="success" v-show="model_vista_factura.estatus!='Orden Finalizada'"
                                                    @click="agregar_articulos_pedido_orden_normal()">Agregar todos</v-btn>
                                                <br>
                                                <v-btn small color="info" v-show="model_vista_factura.estatus!='Orden Finalizada'"
                                                    @click="agregar_articulos_pedido_orden_normal(1)">Agregar solo artículos restantes</v-btn>
                                            </div>
                                        </div>
                                            <v-divider />
                                        <v-card-text>
                                        <v-client-table id="table_izquierda" ref="vuetable_articulos_all" :data="model_vista.articulos" :columns="columns_lista" :options="options_lista" 
                                            class="elevation-2 pa-2" :key="keyTableListaIzquierda">
                                            <template slot="codigo_articulo" slot-scope="props">
                                                <div style="font-size:10px !important;" :class="(props.row.agregado_a_factura && props.row.agregado_a_factura==1)?'bg-green':''"
                                                 @dblclick="valida_seleccionar(props.row)">
                                                    {{props.row.codigo_articulo.join(", ")}}
                                                 </div>
                                            </template>
                                            <template slot="nombre_articulo" slot-scope="props">
                                                <div style="font-size:10px !important;" :class="(props.row.agregado_a_factura && props.row.agregado_a_factura==1)?'bg-green':''"
                                                @dblclick="valida_seleccionar(props.row)">
                                                    {{props.row.nombre_articulo}}
                                                </div>
                                            </template>
                                            <template slot="actions" slot-scope="props">
                                            <div style="text-align:center;">
                                                <v-icon x-small color="success" @click.native="valida_seleccionar(props.row)">fa-forward</v-icon>
                                            </div>
                                            </template>
                                        </v-client-table>
                                        </v-card-text>
                                    </v-flex>

                                    <v-flex col xs6 s6 m6 l6 style="border-left: 1px solid; !important" >
                                        <div style="display: flex;justify-content: space-between;">
                                            <div style="text-align:left" >
                                               <h4><v-icon>check</v-icon> Artículos en la Factura </h4>
                                            </div>
                                            <div style="text-align:right">
                                                <v-btn small color="error" v-show="model_vista_factura.estatus!='Orden Finalizada'"
                                                    @click="remover_articulos_orden_directa()">Quitar todos</v-btn>
                                            </div>
                                        </div>
                                        <v-divider />                                        
                                        <v-card-text>
                                            <v-client-table id="table_derecha" ref="vuetable_articulos_proveedor" :data="model_vista_factura.articulos" :columns="columns_lista_derecha" :options="options_lista_derecha" class="elevation-2 pa-2">
                                                <template slot="codigo_articulo" slot-scope="props">
                                                    <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.codigo_articulo.join(", ")}}</div>
                                                </template>                                                
                                                <template slot="nombre_articulo" slot-scope="props">
                                                    <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.nombre_articulo}}</div>
                                                </template>                                                
                                            <template slot="actions" slot-scope="props">
                                                <div style="text-align:center;">
                                                    <v-icon x-small color="error" @click.native="remover_articulo(props.row)">fa-backward</v-icon>
                                                </div>
                                            </template>
                                            </v-client-table>
                                        </v-card-text>
                                    </v-flex>
                                </v-layout>
                            </v-col>    
                        </v-row><br>

                        <v-row justify="center">
                            <v-btn @click.native="modalFactura = false">
                                <v-icon>cancel</v-icon> Cerrar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="guardarDatosFactura()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <!-- MODAL DATOS FACTURA DIRECTA -->
    <v-dialog v-model="modalFacturaDirecta" max-width="90%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Datos de la Factura Directa &nbsp;&nbsp;&nbsp;&nbsp;Proveedor: {{model_vista_factura.proveedor.nombre_proveedor}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalFacturaDirecta = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidadplazo" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Folio Factura" v-model="model_vista_factura.factura_folio" 
                                    ref="ingresafolio" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Folio SAT" v-model="model_vista_factura.factura_folio_sat" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-menu offset-x ref="menu5" v-model="menu5" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-on="on" v-model="model_vista_factura.factura_fecha" label="Fecha Factura"
                                        @input="setVencimiento()" readonly clearable
                                        color="secondary" prepend-icon="event" @blur="model_vista_factura.factura_fecha = parse_date(model_vista_factura.factura_fecha);" :rules="[rules.required, valida_fecha]">
                                        </v-text-field>
                                    </template>

                                    <v-date-picker v-model="model_vista_factura.factura_fecha" color="secondary" scrollable
                                        locale="es-mx" @input="menu5 = false">
                                        <v-spacer />

                                        <v-btn color="secondary" x-small @click="menu5 = false">
                                        Cancelar
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-autocomplete v-model="model_vista_factura.factura_estatus"
                                :items="estatus_factura" :hide-no-data="true"
                                item-text="val" item-value="val" label="Estatus" :rules="[rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" md="3" lg="3">
                                <v-menu offset-x ref="menu6" v-model="menu6" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-on="on" v-model="model_vista_factura.fecha_recibio_mercancia" label="Fecha Recibió Mercancía"
                                        @input="setVencimiento()" readonly clearable
                                        color="secondary" prepend-icon="event" @blur="model_vista_factura.fecha_recibio_mercancia = parse_date(model_vista_factura.fecha_recibio_mercancia);" :rules="[valida_fecha]">
                                        </v-text-field>
                                    </template>

                                    <v-date-picker v-model="model_vista_factura.fecha_recibio_mercancia" color="secondary" scrollable
                                        @change="setVencimiento()"
                                        locale="es-mx" @input="menu6 = false">
                                        <v-spacer />

                                        <v-btn color="secondary" x-small @click="menu6 = false">
                                        Cancelar
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col sm="2" md="2" lg="2">
                                <v-text-field label="Días Plazo para Pago" v-model="model_vista_factura.plazo_pago"
                                    @input="setVencimiento()"
                                    :rules="[validaCantidadEntera]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Fecha Vencimiento" v-model="model_vista_factura.factura_fecha_vencimiento" disabled readonly
                                    ></v-text-field>
                            </v-col>                        
                            <v-col sm="2" md="2" lg="2">
                                <v-text-field label="% Descuento" v-model="model_vista_factura.factura_descuento"
                                hint="Descuento sobre el total de la mercancía recibida"
                                @input="setDescuento()" :rules="[ValidaPorcentajeMaximo]">
                                </v-text-field>
                            </v-col>
                            <v-col sm="2" md="2" lg="2">
                                <v-text-field label="Concepto Descuento" v-model="model_vista_factura.factura_descuento_concepto"></v-text-field>
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col sm="6" md="6" lg="6">
                                <v-text-field label="Observaciones Factura" v-model="model_vista_factura.factura_observaciones"></v-text-field>
                            </v-col>
                            
                        </v-row>
                         <v-row>
                            <v-col>                                                            
                                <v-layout row wrap>
                                    <v-flex col xs6 s6 m6 l6>
                                        <div style="display: flex;justify-content: space-between;">
                                            <div style="text-align:left" >
                                               <h4><v-icon>list</v-icon> Artículos del Proveedor <v-icon @click="modal_proveedor_articulo()" color="blue" title="Agregar Artículo a Proveedor">mdi-account-settings</v-icon></h4>                                                                                               
                                            </div>
                                            <div style="text-align:right">
                                                <v-btn small color="success" v-show="model_vista_factura.estatus!='Orden Finalizada'"
                                                @click="agregar_articulos_proveedor_orden_directa()">Agregar todos</v-btn>
                                            </div>
                                        </div>
                                        
                                            <v-divider /> 
                                        <v-card-text>
                                        <v-client-table id="table_izquierda_directa" ref="vuetable_articulos_all" :data="articulos_proveedor_seleccionado_directo" :columns="columns_lista_directa" :options="options_lista_directa" 
                                            class="elevation-2 pa-2" :key="keyTableListaIzquierdaDirecta" >
                                            <template slot="codigo_barras" slot-scope="props">
                                                <div style="font-size:10px !important;" :class="(props.row.agregado_a_factura && props.row.agregado_a_factura==1)?'bg-green':''"
                                                @dblclick="valida_seleccionar_directa(props.row)">
                                                    {{props.row.codigo_barras.join(", ")}}
                                                </div>
                                            </template>
                                            <template slot="nombre" slot-scope="props">
                                                <div style="font-size:10px !important;" :class="(props.row.agregado_a_factura && props.row.agregado_a_factura==1)?'bg-green':''"
                                                @dblclick="valida_seleccionar_directa(props.row)">
                                                    {{props.row.nombre}}
                                                </div>
                                            </template>
                                            <template slot="actions" slot-scope="props">
                                            <div style="text-align:center;">
                                                <v-icon x-small color="success" @click.native="valida_seleccionar_directa(props.row)">fa-forward</v-icon>
                                            </div>
                                            </template>
                                        </v-client-table>
                                        </v-card-text>
                                    </v-flex>

                                    <v-flex col xs6 s6 m6 l6 style="border-left: 1px solid; !important" >
                                        <div style="display: flex;justify-content: space-between;">
                                            <div style="text-align:left" >
                                               <h4><v-icon>check</v-icon> Artículos en la Factura </h4>
                                            </div>
                                            <div style="text-align:right">
                                                <v-btn small color="error" v-show="model_vista_factura.estatus!='Orden Finalizada'"
                                                @click="remover_articulos_orden_directa()">Quitar todos</v-btn>
                                            </div>
                                        </div>
                                        <v-divider />                                        
                                        <v-card-text>
                                            <v-client-table id="table_derecha_directa" ref="vuetable_articulos_proveedor" :data="model_vista_factura.articulos" :columns="columns_lista_derecha" :options="options_lista_derecha" class="elevation-2 pa-2">
                                                <template slot="codigo_articulo" slot-scope="props">
                                                    <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.codigo_articulo.join(", ")}}</div>
                                                </template>
                                                <template slot="nombre_articulo" slot-scope="props">
                                                    <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.nombre_articulo}}</div>
                                                </template>                                                
                                            <template slot="actions" slot-scope="props">
                                                <div style="text-align:center;">
                                                    <v-icon x-small color="error" @click.native="remover_articulo(props.row)">fa-backward</v-icon>
                                                </div>
                                            </template>
                                            </v-client-table>
                                        </v-card-text>
                                    </v-flex>
                                </v-layout>
                            </v-col>    
                        </v-row><br>

                        <v-row justify="center">
                            <v-btn @click.native="modalFacturaDirecta = false">
                                <v-icon>cancel</v-icon> Cerrar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="guardarDatosFactura()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <!-- MODAL TABLA FACTURAS -->
    <v-dialog v-model="modalVistaFacturas" max-width="90%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Facturas de la orden: {{model_vista.no_orden}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Proveedor: {{model_vista.proveedor.nombre_proveedor}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalVistaFacturas = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>                
                    <v-container>
                        
                        
                        <v-client-table :data="facturas_items" :columns="columnsFacturas" :options="optionsFacturas" class="elevation-2 pa-2">
                            <template slot="fecha" slot-scope="props">
                                    {{ parse_date(props.row.fecha) }}
                            </template>
                            <template slot="factura_fecha" slot-scope="props">
                                    {{ parse_date(props.row.factura_fecha) }}
                            </template>
                            <template slot="articulos" slot-scope="props">
                                <v-chip color="blue" label outlined x-small @click="verArticulosFactura(props.row)">
                                    Ver {{ props.row.articulos.length }} artículos
                                </v-chip>
                            </template>
                            <template slot="faltante_factura" slot-scope="props">
                                <div style="text-align:center;">
                                    {{ get_faltante_factura(props.row.articulos) }}
                                </div>
                            </template>
                            <template slot="total_factura" slot-scope="props">
                                <div style="text-align:center;">
                                    ${{ formatNumberDec(get_total_orden_factura(props.row.articulos),2) }}
                                </div>
                            </template>
                            <template slot="fecha_recibio_mercancia" slot-scope="props">
                                    {{ parse_date(props.row.fecha_recibio_mercancia) }}
                            </template>
                            <template slot="estatus_mercancia" slot-scope="props">
                                <span v-if="props.row.estatus=='Orden Cancelada'">Orden Cancelada</span>
                                <span v-else>{{ props.row.estatus=="Orden Finalizada" ? "Entrada Finalizada" : "Entrada Pendiente" }}</span>
                                    
                            </template>
                            <template slot="actions" slot-scope="props">
                                <v-speed-dial direction="left" open-on-hover>
                                    <template v-slot:activator>
                                        <v-btn x-small fab dark color="primary">
                                            <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                                            <v-icon v-else>reorder</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-btn x-small fab dark color="info" @click.native="setDatosFactura(props.row, 'opcion_editar')" title="Editar" v-tippy>
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                    <v-btn x-small fab dark color="primary" v-if="props.row.estatus!='Orden Cancelada'" @click.native="imprimirOrdenEntrada(props.row)" title="Orden Entrada" v-tippy>
                                        <v-icon>mdi-package-down</v-icon>
                                    </v-btn>
                                    <v-btn x-small fab dark color="red" v-if="props.row.estatus!='Orden Finalizada'" @click.native="eliminarFactura(props.row)" title="Eliminar Factura" v-tippy>
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                    <v-btn x-small fab dark color="purple darken-1" v-if="props.row.estatus!='Pendiente'" @click.native="editarObservaciones(props.row)" title="Observaciones" v-tippy>
                                        <v-icon>mdi-note-plus</v-icon>
                                    </v-btn>                                    
                                </v-speed-dial>

                            </template>
                        </v-client-table>                        
                                                
                    </v-container>                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalVistaFacturas = false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="success" @click.native="inicializaFactura()">
                    <v-icon>add</v-icon> Agregar Factura
                </v-btn>
          </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalCantidad" max-width="40%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Cantidad a Ordenar</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCantidad = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            Artículo: &nbsp; <strong>{{row_selected.articulo?row_selected.articulo.nombre:''}}</strong>                            
                        </v-row>
                        <v-row justify="center">
                            Proveedor: &nbsp; <strong>{{nombre_proveedor_seleccionado}}</strong><br>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="8" md="8" lg="8" style="text-align:center !important;">
                                <v-autocomplete :items="sucursales_tipo" v-model="sucursal_seleccionada"
                                    item-text="nombre" return-object :rules="[rules.required]"
                                    placeholder="Seleccione sucursal" label="Sucursal">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="8" md="8" lg="8" style="text-align:center !important;">
                                <v-text-field label="Cantidad" v-model="cantidad_editable" ref="ingresacantidad" 
                                v-on:keydown.enter.prevent='1' @keyup.enter="validarArticulo()"
                                :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="8" md="8" lg="8" style="text-align:center !important;">
                                <v-text-field label="Precio Unitario Neto (C/Imp)" v-model="precio_editable"
                                persistent-hint
                                :hint="'Campo Opcional - Precio Compra Anterior: $'+formatNumberDec(row_selected.articulo?row_selected.articulo.precio_compra_con_iva:0,4)"
                                v-on:keydown.enter.prevent='1' @keyup.enter="validarArticulo()"
                                :rules="[validaCantidadFloatCero]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn @click.native="modalCantidad = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="validarArticulo()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalCantidadAbastos" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Cantidad para Abastos</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCantidadAbastos = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                                <v-text-field label="Cantidad" v-model="cantidad_editable" ref="ingresacantidad"                                    
                                    v-on:keydown.enter.prevent='1' @keyup.enter="validarArticuloAbastos()"
                                    :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn @click.native="modalCantidadAbastos = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="validarArticuloAbastos()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modal_agregar_articulo" max-width="90%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Datos del artículo en factura: {{articulo_seleccionado.nombre_articulo}}</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modal_agregar_articulo = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="2" md="2" lg="2" style="text-align:center !important;">
                                <v-text-field label="Cantidad en Factura" v-model="articulo_seleccionado.cantidad" 
                                ref="ingresacantidad" v-on:keydown.enter.prevent='1' 
                                :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                            <v-col sm="2" md="2" lg="2" style="text-align:center !important;">
                                <v-text-field label="Cantidad Faltante" v-model="articulo_seleccionado.cantidad_faltante" 
                                v-on:keydown.enter.prevent='1' 
                                :rules="[validaCantidadFloatCero]"></v-text-field>
                            </v-col>
                            <v-col sm="2" md="2" lg="2" style="text-align:center !important;">
                                <v-text-field label="Cantidad Devuelta" v-model="articulo_seleccionado.cantidad_devuelta" 
                                v-on:keydown.enter.prevent='1' 
                                :rules="[validaCantidadFloatCero]"></v-text-field>
                            </v-col>
                            <v-col sm="2" md="2" lg="2" style="text-align:center !important;">
                                <v-text-field label="Precio Pedido" :value="'$'+articulo_seleccionado.precio_proveedor" 
                                disabled readonly ></v-text-field>
                            </v-col>
                            <v-col sm="1" md="1" lg="1" style="text-align:center !important;">
                                <v-text-field label="Exist Matriz" :value="existencia_matriz" 
                                :hint="'Total: '+existencia_articulo" persistent-hint
                                disabled readonly ></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3" style="text-align:center !important;">
                                <v-text-field label="Precio Unitario Factura" v-model="articulo_seleccionado.precio_factura" 
                                v-on:keydown.enter.prevent='1' @keyup.enter="seleccionar_articulo()"
                                :hint="'Precio Compra Anterior: $'+formatNumberDec(articulo_seleccionado.precio_compra_con_iva,4)" persistent-hint
                                :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn @click.native="modal_agregar_articulo = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="seleccionar_articulo()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalEstatus" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Editar Estatus</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalEstatus = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="10" md="10" lg="10" style="text-align:center !important;">
                                <v-autocomplete v-model="estatus_editable" :items="options.listColumns.estatus" :hide-no-data="true" item-text="id" item-value="id" label="Seleccione Estatus" :rules="[rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalEstatus = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="changeEstatus()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalCorreo" max-width="50%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">email</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Enviar por Correo</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCorreo = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcorreo" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Enviar a" v-model="correo.correos" persistent-hint hint="Correos separados por coma" :rules="[rules.required, validaCorreo]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Asunto" v-model="correo.asunto" :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Texto del Correo" v-model="correo.mensaje" :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Responder a" v-model="correo.responder_a" persistent-hint hint="Correos separados por coma" :rules="[rules.required, validaCorreo]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalCorreo = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="enviarCorreo()">
                                <v-icon>done</v-icon> Enviar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalAdjuntarDocumentos" max-width="55%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">attach_file</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Adjuntar Documentos</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalAdjuntarDocumentos = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formadjuntos" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col lg="9" md="9" sm="9">
                                <v-file-input label="Seleccione un archivo" :rules="[rules.required]" v-model="file_adjunto" :show-size="1000"></v-file-input>
                            </v-col>
                            <v-col lg="3" md="3" sm="3">
                                <br />
                                <v-btn small color="info" @click.native="adjuntarArchivo()">
                                    Adjuntar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-client-table :data="model_adjuntos" :columns="columnsAdjuntos" :options="optionsAdjuntos" class="elevation-2 pa-2">
                                    <template slot="actions" slot-scope="props">
                                        <v-btn x-small fab dark color="success" @click.native="descargarAdjunto(props.row)" title="Descargar Adjunto" v-tippy>
                                            <v-icon>file_download</v-icon>
                                        </v-btn>
                                        &nbsp;
                                        <v-btn x-small fab dark color="pink" @click.native="eliminarAdjunto(props.row)" title="Eliminar Adjunto" v-tippy>
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-client-table>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click.native="modalAdjuntarDocumentos = false">
                    <v-icon>cancel</v-icon> Cancelar
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalVistaPrevia" max-width="65%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">mdi-file-excel</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Excel de Orden de Compra</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalVistaPrevia = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-actions>
                <v-row>
                    <v-col>
                        <br>
                        <v-btn color="primary" small @click.native="cargarExcel()">
                            <v-icon>mdi-file-import</v-icon> Cargar Excel de Proveedor
                        </v-btn>
                    </v-col>
                    <v-col>
                        <br>
                        <v-btn color="info" small @click.native="modalCorreo=true">
                            <v-icon>email</v-icon> Enviar por Correo
                        </v-btn>
                    </v-col>                
                    <v-col>
                        <br>
                        <v-btn color="success" small @click.native="descargaExcel()">
                            <v-icon>mdi-file-excel</v-icon> Exportar a Excel
                        </v-btn>
                    </v-col>                    
                </v-row>
                
            </v-card-actions>
            <hr>
            <v-card-text>
                <div id="orden_excel" v-if="model_vista != null">
                    <meta charset="utf-8">

                    <table width="100%">
                        <tr>
                            <td colspan="6" style="color:red;font-weight:bolder;text-align:center;font-size:small;">
                                LA BODEGUITA DE GUADALUPE
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6" style="font-weight:bolder;text-align:center;font-size:small;">
                                {{model_vista.proveedor.nombre_proveedor}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6" style="font-weight:bolder;text-align:center;font-size:small;">
                                No. Orden: {{model_vista.no_orden}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                    </table>

                    <table border=1 width="100%">
                        <tr>
                            <th class="celda_titulo" width="10%">CÓDIGO BARRAS</th>
                            <th class="celda_titulo" width="26%">NOMBRE</th>
                            <th class="celda_titulo" width="10%">DESCRIPCIÓN</th>
                            <th class="celda_titulo" width="10%">EXISTENCIA</th>
                            <th class="celda_titulo" width="10%">PEDIDO</th>
                            <th class="celda_titulo" width="12%">$ ANTERIOR</th>
                            <th class="celda_titulo" width="12%">$ NUEVO</th>
                            <th class="celda_titulo" width="14%">OBS</th>
                        </tr>

                        <tr v-for="(articulo, index) in model_vista.articulos">
                            <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')">{{ articulo.codigo_articulo[0] }}</td>
                            <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')">{{articulo.nombre_articulo}}</td>
                            <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')">{{articulo.descripcion}}</td>
                            <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')">{{articulo.existencia_articulo}}</td>
                            <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')">{{articulo.cantidad}}</td>
                            <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')">${{formatNumberDec(articulo.precio_compra_con_iva,2)}}</td>
                            <td style="text-align:center;" :class="(articulo.precio_proveedor!=null && articulo.precio_proveedor!=''
                            && parseFloat(formatNumberDec(articulo.precio_proveedor,2).replace(',',''))>parseFloat(formatNumberDec(articulo.precio_compra_con_iva,2).replace(',',''))?'celda_datos bg-rojo':
                            (articulo.precio_proveedor!=null && articulo.precio_proveedor!=''
                            && parseFloat(formatNumberDec(articulo.precio_proveedor,2).replace(',',''))<parseFloat(formatNumberDec(articulo.precio_compra_con_iva,2).replace(',',''))?'celda_datos bg-seleccionado':
                            (articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')))">${{formatNumberDec(articulo.precio_proveedor,2)}}</td>
                            <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')">{{articulo.observaciones_proveedor}}</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style="text-align:center;font-weight:bolder;">{{sumatoria_pedido}}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </table>

                    <table width="100%">
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4" style="font-weight:bolder;font-size:small;">
                                Vendedor: {{model_vista.proveedor?model_vista.proveedor.contacto.toString().toUpperCase():''}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4" style="font-weight:bolder;font-size:small;">
                                Fecha: {{parse_date_time_mx(model_vista.fecha)}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4" style="font-weight:bolder;font-size:small;">
                                Condiciones: {{model_vista.condiciones_orden}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4" style="font-weight:bolder;font-size:small;">
                                Fecha de Entrega Estimada: {{parse_date_mx(model_vista.fecha_entrega_estimada)}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4" style="font-weight:bolder;font-size:small;">
                                {{model_vista.proveedor.nombre_proveedor}}&nbsp;&nbsp;ASESOR DE VENTAS<br>
                                {{model_vista.proveedor?model_vista.proveedor.contacto.toString().toUpperCase():''}} E-MAIL: {{model_vista.proveedor.email}}<br>
                                CEL. {{model_vista.proveedor.telefono_1}}
                            </td>
                            <td></td>
                        </tr>                        
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="4" style="text-align:center;font-weight:bolder;;font-size:small;">
                                Firma: _________________________________
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        
                        <tr>
                            <td></td>
                            <td v-if="model_vista.observaciones_orden" colspan="4" style="font-weight:bolder;font-size:small;" v-html="model_vista.observaciones_orden.replaceAll('\n','<br>')">
                                                                
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        
                    </table>
                </div>

            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modal_proveedor" max-width="100%">
      <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Asignar Artículos al Proveedor: {{nombre_proveedor_seleccionado}}</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_proveedor = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container fluid fill-height>
              <v-layout row wrap>
                  <v-flex col xs6 s6 m6 l6>
                      <h4><v-icon>list</v-icon> Artículos Activos</h4>                     
                        <v-divider />
                      <v-card-text>
                      <v-client-table id="table_izquierda" ref="vuetable_articulos_all" :data="articulos" :columns="columns_lista_proveedor" :options="options_lista_proveedor" class="elevation-2 pa-2">
                          <template slot="nombre" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="asignar_articulo_proveedor(props.row)">{{props.row.nombre}}</div>
                          </template>
                          <template slot="descripcion" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="asignar_articulo_proveedor(props.row)">{{props.row.descripcion}}</div>
                          </template>
                          <template slot="categoria" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="asignar_articulo_proveedor(props.row)">{{props.row.categoria.join(", ")}}</div>
                          </template>
                          <template slot="actions" slot-scope="props">
                            <div style="text-align:center;">
                              <v-icon x-small color="success" @click.native="asignar_articulo_proveedor(props.row)">fa-forward</v-icon>
                          </div>
                        </template>
                      </v-client-table>
                    </v-card-text>
                  </v-flex>

                  <v-flex col xs6 s6 m6 l6 style="border-left: 1px solid; !important" >
                      <h4><v-icon>check</v-icon> Artículos del Proveedor </h4>
                      <v-divider />                      
                      <v-card-text>
                          <v-client-table id="table_derecha" ref="vuetable_articulos_proveedor" :data="articulos_proveedor" :columns="columns_lista_derecha_proveedor" :options="options_lista_derecha_proveedor" class="elevation-2 pa-2">
                            <template slot="nombre" slot-scope="props">
                              <div style="font-size:10px !important;" @dblclick="remover_articulo_proveedor(props.row)">{{props.row.nombre}}</div>
                            </template>
                            <template slot="descripcion" slot-scope="props">
                              <div style="font-size:10px !important;" @dblclick="remover_articulo_proveedor(props.row)">{{props.row.descripcion}}</div>
                            </template>
                            <template slot="categoria" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="remover_articulo_proveedor(props.row)">{{props.row.categoria}}</div>
                          </template>
                          <template slot="actions" slot-scope="props">
                            <div style="text-align:center;">
                                <v-icon x-small color="error" @click.native="remover_articulo_proveedor(props.row)">fa-backward</v-icon>
                            </div>
                          </template>
                        </v-client-table>
                      </v-card-text>
                  </v-flex>
              </v-layout>
          </v-container>
  </v-card-text>
    </v-card>
  </v-dialog>

    <upload-excel-component title="Cargar Excel" v-show="false" :headerRow=4 :firstRow=5 :headersExcel="headersExcel" :on-success="handleSuccess" :before-upload="beforeUpload" />


</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import Vue from "vue";
import "jsbarcode";
import {
    jsPDF
} from "jspdf";
import 'jspdf-autotable';
import $ from 'jquery';
//import XLSX from 'xlsx';
export default {
    components: {
        "PaginacionComponent": PaginacionComponent
    },
    watch: {
        'model_vista.articulos': {
            handler(newArticulos) {
                this.model_vista.articulos = newArticulos;
            },
            deep: true
        },
        model_filters: {
            handler(val) {
                // this.$nextTick(() => {
                //   this.registros.items = [];
                //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                // });
            },
            deep: true
        },
        search(val) {
            //Si no ha escrito 2 letras o mas
            if (val == null || val.length < 2) return
            // Si todavía esta buscando
            if (this.isLoading) return
            this.isLoading = true
            let data = {
                "selector": {
                    "type": "articulos",
                    "nombre": {
                        "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    },
                    "estatus": "Activo"
                },
                "sort": ["nombre"],
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        this.articulos = response.data.docs;
                    } else
                        this.articulos = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(() => {
                    this.isLoading = false;
                });
        },
        searchProveedor(val) {
            //Si no ha escrito 2 letras o mas
            if (val == null || val.length < 2) return
            // Si todavía esta buscando
            if (this.isLoading) return
            this.isLoading = true
            let data = {
                "selector": {
                    "type": "proveedores",
                    "nombre": {
                        "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    },
                    "estatus": "Activo"
                },
                "sort": ["nombre"],
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        this.proveedores = response.data.docs;
                    } else
                        this.proveedores = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(() => {
                    this.isLoading = false;
                });
        },
        searchCategoria(val) {
            //Si no ha escrito 2 letras o mas
            if (val == null || val.length < 2) return
            // Si todavía esta buscando
            if (this.isLoading) return
            this.isLoading = true
            let data = {
                "selector": {
                    "type": "categorias",
                    "nombre": {
                        "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    },
                    "estatus": "Activo"
                },
                "sort": ["nombre"],
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        this.categorias = response.data.docs;
                    } else
                        this.categorias = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las categorias.",
                        footer: ""
                    });
                }).then(() => {
                    this.isLoading = false;
                });
        },
    },
    mounted: function () {
        this.model_filters = this.$refs.vuetable.$refs.table.query;
        if (!this.verificaPermiso('t.compras.ordenes')) {
            this.$router.replace("AccessDenied").catch(() => {});
        }
        //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
        const select = document.getElementsByName('vf__estatus')[0];
        select.addEventListener('change', this.onStatusChanged);
    },
    created: function () {
        self.articulos_encontrados = [];
        this.usuario = this.$local_storage.get("sb_usuario");
        this.email_usuario = this.$local_storage.get("sb_email_usuario");
        
        this.get_sucursales();
        //this.getImpuestoIVA();
        this.getDescripciones();
        this.getEquivalencias();
        this.getArticulos();
        this.getProveedoresCombo();
        this.getCategorias();
        this.getPedidosVencidos();
        var today = window.moment().format("YYYY-MM-DD");
        this.fecha_de_venta = window.moment().subtract(12, 'months').format("YYYY-MM-DD");
        this.fecha_a_venta = today;
    },
    computed: {
        datafiltered() {
            if (this.$refs.vuetablearticulos != null) {
                return this.$refs.vuetablearticulos.allFilteredData;
            } else {
                return this.articulos_encontrados;
            }

        },
    },

    data() {
        return {   
            articulos_equivalecias:[],
            sumatoria_pedido:"",
            loading_counter:false,         
            pedidos_vencidos:[],
            modal_vencidos:false,
            vencidos_options: {
                columns: ["no_orden","fecha","estatus","fecha_estimada",                
                "proveedor.nombre_proveedor","proveedor.contacto",
                "proveedor.email","proveedor.telefono_1"
                ],
                options: {
                    // sortable: ["fecha", "cantidad", "precio", "total"],
                    headings: {
                        no_orden:"No. Pedido",
                        "fecha_estimada":"Fecha Entrega Estimada",
                        "proveedor.nombre_proveedor":"Proveedor",                        
                        "proveedor.contacto":"Contacto",
                        "proveedor.email":"Email",
                        "proveedor.telefono_1":"Teléfono"
                    },
                }
            },

            compras_articulos:{},
            modal_compras:false,
            loadingPrecioPromedio: false,            
            items_compras_historico: [],
            compras_historico: {
                columns: ["no_orden","fecha","estatus","existencia",
                "cantidad","cantidad_recibida","fecha_estimada","fecha_recibio",                
                "proveedor","observaciones_orden","observaciones_entrada"
                ],
                options: {
                    filterable: false,
                    // sortable: ["fecha", "cantidad", "precio", "total"],
                    headings: {
                        no_orden:"No. Pedido/Factura",
                        "fecha_recibio":"Fecha Recibio/Finalizó",
                        "fecha_estimada":"Fecha Entrega Estimada"
                    },
                }
            },
            selectedRowIndex: null,
            articulos_facturas_previas:[],
            sucursales_tipo:[],
            sucursal_seleccionada:"",
            existencia_matriz:0,
            saveTimeout: null,
            bloquear_fechas: "1",
            guardando: false,
            model_proveedor: "",
            articulos_proveedor:[],
            articulos_proveedor_seleccionado_directo:[],
            search:"",
            modal_proveedor: false,
            nombre_proveedor_seleccionado:"",
            columns_lista_proveedor: [
                "nombre","descripcion","categoria",
                "actions"
                ],
            columns_lista_derecha_proveedor: [
                "actions",
                "nombre","descripcion","categoria",
            ],
            options_lista_proveedor: {
                filterable: ["nombre", "descripcion","categoria"],
                sortable: ["nombre", "descripcion","categoria"],
                perPage: 25,
                pagination: {
                show: true
                },
                headings: {
                nombre: "Artículo",
                "descripcion": "Descripción",
                "categoria":"Categoría",
                actions: "Agregar"
                },
                listColumns:{
                categoria:[],
                }
            },
            options_lista_derecha_proveedor: {
                filterable: ["nombre", "descripcion","categoria"],
                sortable: ["nombre", "descripcion","categoria"],
                perPage: 25,
                pagination: {
                show: true
                },
                headings: {
                nombre: "Artículo",
                "descripcion": "Descripción",
                "categoria":"Categoría",
                actions: "Remover"
                },
                listColumns:{
                categoria:[],
                }
            },

            mensaje_tab_pedido:"",
            active_tab: "busqueda",
            observaciones_entrada_facturas:[],
            keyTablaBusqueda:"articulos_buscados",
            keyTableArticulosOrden:"articulosorden",
            keyTableArticulosFactura:"articulosfactura",
            keyTableCaducidadArticulosFactura:"caducidadarticulosfactura",
            filters: {}, // Objeto para almacenar los filtros aplicados
            keyTableListaIzquierda:"articulosizquierda",
            keyTableListaIzquierdaDirecta:"articulosizquierdadirecta",
            total_factura_mercancia_recibida:0,
            total_factura_mercancia_faltante: 0,
            total_factura_mercancia_devuelta: 0,
            articulo_seleccionado:"",
            cantidad_a_recibir_factura:"",
            precio_unitario_factura:"",
            modal_agregar_articulo:false,
            columns_lista: [
                "codigo_articulo","nombre_articulo","actions"
                ],
            columns_lista_derecha: [
                "actions","codigo_articulo","nombre_articulo","cantidad"
            ],
            columns_lista_directa: [
                "codigo_barras","nombre","actions"
            ],            
            options_lista: {
                filterable: ["codigo_articulo","nombre_articulo","cantidad"],
                sortable: ["codigo_articulo","nombre_articulo","cantidad"],
                perPage: 25,
                pagination: {
                show: true
                },
                headings: {
                nombre_articulo: "Artículo",                
                actions: "Agregar"
                },
            },
            options_lista_derecha: {
                filterable: ["codigo_articulo","nombre_articulo"],
                sortable: ["codigo_articulo", "nombre_articulo"],
                perPage: 25,
                pagination: {
                show: true
                },
                headings: {
                nombre_articulo: "Artículo",                
                actions: "Remover"
                },
            },
            options_lista_directa: {
                filterable: ["codigo_barras", "nombre","cantidad"],
                sortable: ["codigo_barras", "nombre","cantidad"],
                perPage: 25,
                pagination: {
                show: true
                },
                headings: {
                nombre: "Artículo",                
                actions: "Agregar"
                },
            },
            
            descripciones: [],
            nombre_proveedor_seleccionado:"",
            id_articulo_editable:"",
            TASA_IVA: 16, //Por default
            estatus_factura: [{'val':'Por Pagar'},{'val':'Pagada'}],
            suc_matriz:"",
            suc_abastos:"",
            proveedores_combo:[],
            loadingCategorias:false,
            loadingProveedores:false,
            modal_opcion:1,
            modalFactura:false,
            modalFacturaDirecta:false,
            modalVistaFacturas:false,
            loadingCompras: false,
            loadingArticulos: false,
            loadingVentas: false,
            columnsExistencias: ["sucursal","existencia"],
            optionsExistencias: {
                filterable: false,
            },
            articulo_existencias:[],
            items_compras: [],
            items_ventas: [],
            facturas_items:[],
            columnsFacturas:[
                "no_orden","fecha","factura_folio","factura_folio_sat","factura_fecha","factura_estatus","articulos",
                "faltante_factura","total_factura",
                "fecha_recibio_mercancia","estatus_mercancia","actions"
            ],
            optionsFacturas:{
                filterable: ["no_orden","fecha","factura_folio","factura_folio_sat","factura_fecha","factura_estatus","faltante_factura","total_factura","fecha_recibio_mercancia","estatus_mercancia"],
                sortable: ["no_orden","fecha","factura_folio","factura_folio_sat","factura_fecha","factura_estatus","faltante_factura","total_factura","fecha_recibio_mercancia","estatus_mercancia"],
                headings: {
                    "no_orden":"Suborden",
                    "fecha":"Fecha",
                    "factura_folio":"Folio Factura",
                    "factura_folio_sat":"Folio SAT",
                    "factura_fecha":"Fecha Factura",                    
                    "factura_estatus":"Estatus",
                    "articulos":"Articulos Factura",
                    "faltante_factura":"Faltante(s)",
                    "total_factura":"Total Factura",
                    "fecha_recibio_mercancia":"Fecha Recibió Mercancía",
                    "estatus_mercancia":"Estatus Mercancía",
                    "actions":"Operaciones"
                }
            },
            compras: {
                columns: [
                "fecha",
                "existencia",
                "cantidad",
                "precio",
                "proveedor",
                "orden",
                "total"
                ],
                options: {
                    filterable: false,
                    // sortable: ["fecha", "cantidad", "precio", "total"],
                    headings: {
                        fecha: "Fecha",
                        "existencia":"Existían",
                        cantidad: "Cantidad",
                        precio: "Precio Proveedor",
                        "proveedor":"Proveedor",
                        total: "Acciones"
                    },
                }
            },
            ventas: {
                columns: [
                "fecha",
                "cantidad",
                "precio",
                ],
                options: {
                    filterable: false,
                    // sortable: ["fecha", "cantidad", "precio", "total"],
                    headings: {
                        fecha: "Fecha",
                        cantidad: "Cantidad",
                        precio: "Precio Venta",
                    },
                }
            },

            id_articulo_calculo:"",
            existencia_articulo:"",
            plazo_calculo:"",
            periodo:"1",
            fecha_de_venta: "",
            fecha_a_venta:"",
            fecha_de:"",
            fecha_a: "",
            ultimos_vendidos: 0,
            total_compra: 0,
            promedio_compra: 0,
            precio_promedio_compra: 0,
            total_venta: 0,
            promedio_venta: 0,
            precio_promedio_venta: 0,
            minimo: 0,
            reorden: 0,
            maximo: 0,
            cantidad_pedir: 0,
            consumo_minimo: 0,
            consumo_medio: 0,
            consumo_maximo: 0,
            existencia: 0,

            precios_articulo:[],
            columnsPrecios: ["tipo_precio","precio_con_impuestos","de","a"],
            optionsPrecios:{
                filterable: false,
                headings:{
                    "precio_con_impuestos":"Precio Venta Neto",
                },
            },
            fila_anterior: '',
            proveedores_all:[],
            categorias_all:[],
            modalBusqueda_copy:false,
            g_articulo: "",
            g_cantidad: 0,
            file_adjunto: null,
            model_adjuntos: [], //nombre, _id, _rev
            model_adjuntos_rev: "",
            columnsAdjuntos: ["nombre", "actions"],
            optionsAdjuntos: {
                filterable: ["nombre"],
                headings: {
                    "nombre": "Archivo",
                    "actions": "Operaciones"
                }
            },
            estatus_editable: "",
            modalSugeridos: false,
            props_historico: {
                modal: false,
                id_articulo: ""
            },
            headersExcel: [
                "CÓDIGO BARRAS",
                "NOMBRE",
                "DESCRIPCIÓN",
                "EXISTENCIA",
                "PEDIDO",
                "$ ANTERIOR",
                "$ NUEVO",
                "OBS"
            ],
            tipo_busqueda: "",
            tipos_busqueda: [{
                    "id": "proveedor",
                    "nombre": "Proveedor"
                },
                {
                    "id": "categoria",
                    "nombre": "Categoría"
                },
                {
                    "id": "articulo",
                    "nombre": "Artículo"
                },
            ],
            isLoading: false,
            sb_sucursal: "",
            nombre_sucursal: "",
            usuario: "",
            email_usuario:"",
            model_vista: {
                articulos: [],
                proveedor: ""
            },
            model_filters: "",
            props_paginacion: {
                infiniteHandler: this.infiniteHandler,
                total_registros: 0
            },
            fab: [],
            modalCorreo: false,
            modalAdjuntarDocumentos: false,
            modalEstatus: false,
            modalVistaPrevia: false,
            modalObservacion: false,
            modalBusqueda: false,
            modalProveedor: false,
            modalCambioProveedor: false,
            proveedor_editable:"",
            modalEditable: false,
            fecha_editable:"",
            modalfechacaducidad:false,
            modalFechaEntrega: false,
            menu_fecha:false,
            modalEditableFactura:false,
            modalEditar: false,
            modalArticulos: false,
            modalArticulosFactura:false,
            modalCaducidadArticulosFactura:false,
            modalCaducidadArticulo:false,
            modalCantidad: false,
            modalCantidadAbastos: false,
            modalCantidadFaltante: false,
            cantidad_editable: "",
            precio_editable: "",
            articulo_editable: {},
            barcode_orden: "",
            row_selected: "",
            //articulos_vista: [],
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            name: "datagrid",
            columnsProveedores:["nombre","rfc","referencias","plazo"],
            optionsProveedores: {
                filterable: ["nombre","rfc","referencias","plazo"],
            },
            columns: [
                "no_orden", "nombre_sucursal_destino", "fecha", "no_subasta", "usuario", "proveedor.nombre_proveedor","facturas", "articulos", "estatus", "fecha_finalizada", "actions"
            ],
            options: {
                rowClassCallback: this.getRowClass,
                filterable: ["no_orden", "nombre_sucursal_destino","fecha", "no_subasta", "usuario", "proveedor.nombre_proveedor","facturas", "articulos", "estatus", "fecha_finalizada"],
                headings: {
                    "no_orden": "# Orden",
                    "nombre_sucursal_destino":"Sucursal",
                    "proveedor.nombre_proveedor": "Proveedor",
                    "facturas":"Facturas",
                    "fecha_finalizada": "Finalizada",                    
                    "actions": ""
                },
                listColumns: {
                    //Orden Abierta, En Proceso de Cotización, Cotización Recibida,
                    //Pedido en Proceso, Orden Cancelada, En Proceso de Entrada, Pedido Recibido, Orden Finalizada
                    estatus: [{
                            id: "Orden Abierta",
                            text: "Orden Abierta"
                        },
                        {
                            id: "En Proceso de Cotización",
                            text: "En Proceso de Cotización"
                        },
                        {
                            id: "Cotización Recibida",
                            text: "Cotización Recibida"
                        },
                        {
                            id: "Pedido en Proceso",
                            text: "Pedido en Proceso"
                        },
                        {
                            id: "Orden Cancelada",
                            text: "Orden Cancelada"
                        },
                        {
                            id: "En Proceso de Entrada",
                            text: "En Proceso de Entrada"
                        },
                        {
                            id: "Pedido Recibido",
                            text: "Pedido Recibido"
                        },
                        {
                            id: "Orden Finalizada",
                            text: "Orden Finalizada"
                        },
                    ],
                }

            },
            breadcrumbs: [{
                    text: "Inicio",
                    disabled: false,
                    to: "/index"
                },
                {
                    text: "Compras",
                    href: "",
                    disabled: true,
                },
                {
                    text: "Órdenes de Compra",
                    href: ""
                }
            ],

            update: false,
            modelEditable: {},
            correo: {
                correos: "",
                responder_a: "",
                asunto: "",
                mensaje: ""
            },
            descuento_factura_modal:"",
            model: {
                _id: "",
                _rev: "",
                type: "pedidos", //(11-ABR-2023): SE CAMBIO A type=pedidos PARA INTEGRAR LAS FACTURAS DE CADA PEDIDO CON EL type=compras Y HACER MENOS MODIFICACIONES EN TODO LO QUE YA ESTABA
                no_orden: "",
                fecha: "",
                facturas:[],
                id_sucursal_destino:"",
                nombre_sucursal_destino:"",
                tipo_sucursal:"matriz", //matriz|abastos
                plazo_pago:0, //dias de plazo para pago a proveedor
                factura_folio:"",
                factura_fecha:"",
                fecha_recibio_mercancia:"", //Fecha en que se recibió la mercancia
                factura_fecha_vencimiento:"",
                factura_folio_sat: "",
                factura_observaciones:"",
                factura_estatus:"Por Pagar", // "Por Pagar", "Pagada"
                factura_descuento: 0, //porcentaje, al capturar porcentaje se le descuenta a cada articulo de su precio de compra
                                      //hay que guardar el precio original de compra, por si vuelven a modificar el porcentaje
                factura_descuento_concepto:"",
                factura_descuento_total:0,
                proveedor: {
                    id_proveedor: "",
                    nombre_proveedor: "",
                    plazo: "",
                    contacto: "",
                    email: "",
                    telefono_1: "",
                    condiciones:"",
                },
                estatus: "", //Orden Abierta, En Proceso de Cotización, Cotización Recibida,
                //Pedido en Proceso, Orden Cancelada, En Proceso de Entrada, Pedido Recibido, Orden Finalizada
                articulos: [],
                usuario: "",
                observaciones_orden: "", //Se necesitan al imprimir la orden
                condiciones_orden:"",
                usuario_entrada: "",
                observaciones_entrada: "",
                fecha_finalizada: "",
                fecha_entrega_estimada: "",
            },
            actual: "",
            cantidad: "",
            actualEditable: "",
            cantidadEditable: "",
            id_articulo: "",
            id_proveedor: "",
            sucursal_select:"",
            nombre_categoria: "",

            articulo: {
                id_articulo: "",
                nombre_articulo: "",
                descripcion: "",
                codigo_articulo: "",
                impuestos_articulo:[],
                cantidad: "",
                precio_compra: "", //Último precio de compra
                existencia_articulo: 0, //La existencia del articulo al momento de crear la orden, y se actualiza al momento de finalizar entrada de mercancía
                precio_sin_iva:"",
                iva:"", //(24-ABR-2023): Solicitó Alvaro que en lugar de iva vaya la suma de porcentajes de los impuestos del articulo ya que puede ser que lleve o no IVA o incluse lleve otros impuestos
                precio_con_iva:"",
                porcentaje_descuento1:"",
                porcentaje_descuento2:"",
                precio_proveedor: "", //Se carga con el excel que contesta el proveedor, es el PRECIO CON DESCUENTO
                precio_proveedor_sin_iva:"", //se calcula
                observaciones_proveedor: "",
                estatus: "Pendiente", //Pendiente, Recibido
                cantidad_recibida: "", //cantidad real recibida en destino
                cantidad_faltante: "", //cantidad faltante
                cantidad_devuelta: "", //cantidad devuelta
                lotes_destino: [], //Esta opción se llena cuando se le da entrada en sucursal destino
                resaltado: 0, // 0, 1  Si está resaltado cambiarlo a color amarillo, para indicar que esos van a Abastos, los demas a central
                sucursal_recibio: "",
                usuario_recibio: "",
                fecha_recibio: "",
            },
            columnsArticulosBakup: ["articulo.nombre", "proveedor.nombre", "articulo.descripcion",
                "articulo.categoria", "articulo.actual", "articulo.actual_total", "articulo.precio_compra_con_iva",
                "articulo.ventas_30_dias", "fvm", "fvm2","articulo.orden_pendiente", "actions","desactivar"],
            
            columnsArticulos: ["articulo.nombre", "proveedor.nombre", "articulo.descripcion",
                "articulo.categoria", "articulo.actual", "articulo.actual_total", "articulo.precio_compra_con_iva",
                "articulo.ventas_30_dias", "fvm", "fvm2", "articulo.orden_pendiente","actions","desactivar"],
            
            optionsArticulos: {
                filterable: ["articulo.nombre", "articulo.descripcion","articulo.categoria", "proveedor.nombre"],
                //filterable: false,
                headings: {
                    "articulo.nombre": "Artículo",
                    "articulo.descripcion":"Unidad","articulo.categoria":"Categoría",
                    "articulo.minimo":"Mínimo","articulo.reorden":"Reorden","articulo.maximo":"Máximo",
                    "articulo.actual":"Exist. Matriz",
                    "articulo.actual_total":"Exist. Total",
                    "articulo.precio_compra_con_iva":"Precio Compra",
                    "articulo.precio_venta":"Precio Venta Neto",
                    "articulo.ventas_30_dias":"Ventas 30 días",     
                    "fvm":"FVM",
                    "fvm2":"FVM %",
                    "articulo.orden_pendiente":"OP",
                    "articulo.ventas_periodo_seleccionado":"Ventas Periodo Seleccionado",
                    "proveedor.nombre": "Proveedor",
                    "actions": "En Orden Abierta?",
                },
                listColumns: {
                    "articulo.descripcion": []
                },
                rowClassCallback: function (row) {
                    return row.articulo.seleccionado == 1 ? 'bg-seleccionado' : 'bg-default';
                },
                selectable:{
                    mode: 'single', // or 'multiple'
                    only: function(row) {
                    return true // any condition
                    },
                    selectAllMode: 'all', // or 'page',
                    programmatic: false
                }
            },
            data_sugeridos: [],
            columnsArticulosSugeridos: ["articulo.nombre", "articulo.descripcion", "actual","articulo.categoria", "minimo", "reorden", "maximo", "pedir", "actions"],
            optionsArticulosSugeridos: {
                filterable: ["articulo.nombre", "articulo.descripcion","articulo.categoria"],
                //filterable: false,
                headings: {
                    "articulo.nombre": "Artículo",
                    "articulo.descripcion":"Unidad","articulo.categoria":"Categoría",
                    "minimo": "Minimo",
                    "reorden": "Reorden",
                    "maximo": "Maximo",
                    "actual": "Exist. Matriz",
                    "pedir": "Por Pedir",
                    "actions": "En Orden Abierta?",
                },
                listColumns: {
                    "articulo.descripcion": []
                },
                rowClassCallback: function (row) {
                    return row.articulo.seleccionado == 1 ? 'bg-seleccionado' : 'bg-default'
                },
            },
            columnsArticulosVista: ["nombre_articulo","descripcion",
                "precio_compra", "precio_sin_iva", "iva", "precio_con_iva",
                "observaciones_proveedor",
                "existencia_articulo","cantidad","cantidad_recibida_facturas", "monto", "actions"
            ],            
            optionsArticulosVista: {
                filterable: ["nombre_articulo","descripcion", "precio_compra",
                "precio_sin_iva", "iva", "precio_con_iva",
                "porcentaje_descuento1", "porcentaje_descuento2",
                "precio_proveedor", "observaciones_proveedor", "existencia_articulo","cantidad", "cantidad_recibida","cantidad_faltante","cantidad_devuelta", "fecha_recibio"],
                headings: {
                    "precio_compra":"$ P. Compra S/Imp",
                    "precio_sin_iva":"$ Proveedor S/Imp",
                    "iva":"% Impuestos", "precio_con_iva":"$ Proveedor C/Imp",
                    "porcentaje_descuento1":"%Des1", "porcentaje_descuento2":"%Des2",
                    "existencia_articulo":"Existencia",
                    "cantidad":"Cant. Pedido",
                    "cantidad_recibida_facturas":"Cant. Recibida Facturas",
                    "monto":"Monto Pedido",
                    "actions": "Opciones"
                },
                rowClassCallback: function (row) {
                    return row.resaltado == 1 ? 'bg-resaltado' : 'bg-default'
                },
                /*cellClasses: {
                    precio_proveedor: [{
                        class: 'bg-seleccionado',
                        condition: row => row.precio_proveedor != null && row.precio_proveedor != '' && row.precio_proveedor < row.precio_compra_con_iva
                    },
                    {
                        class: 'bg-rojo',
                        condition: row => row.precio_proveedor != null && row.precio_proveedor != '' && row.precio_proveedor > row.precio_compra_con_iva
                    }]
                }*/
            },
            columnsCaducidadArticulosVistaFactura: ["nombre_articulo","descripcion",
                "precio_factura","cantidad","caducidad","fecha_caducidad"
            ],
            optionsCaducidadArticulosVistaFactura: {
                filterable: ["nombre_articulo", "descripcion",
                "caducidad", "fecha_caducidad","precio_factura","cantidad"], 
                listColumns: {
                    caducidad: [{
                            id: 1,
                            text: "Con Caducidad"
                        },               
                        {
                            id: 0,
                            text: "Sin Caducidad"
                        },               
                ]},
            },
            columnsArticulosVistaFactura: ["nombre_articulo","descripcion",
                //"precio_compra", 
                "precio_sin_iva", "iva", "precio_con_iva",
                "porcentaje_descuento1", "porcentaje_descuento2","precio_factura","precio_pedido","diferencia",
                "cantidad", "monto","cantidad_recibida","cantidad_faltante","cantidad_devuelta", "fecha_recibio", "actions"
            ],
            optionsArticulosVistaFactura: {
                filterable: ["nombre_articulo", "descripcion",
                //"precio_compra",
                "precio_sin_iva", "iva", "precio_con_iva",
                "porcentaje_descuento1", "porcentaje_descuento2",
                "precio_factura","precio_pedido","diferencia","cantidad", "cantidad_recibida","cantidad_faltante","cantidad_devuelta", "fecha_recibio"],
                headings: {
                    //"precio_compra":"$ P. Compra S/Imp",
                    "precio_pedido":"$ P. Pedido C/Imp",
                    "precio_sin_iva":"$ Factura S/Imp",
                    "iva":"% Impuestos", "precio_con_iva":"$ Factura C/Imp",
                    "porcentaje_descuento1":"%Des1", "porcentaje_descuento2":"%Des2",
                    "precio_factura":"Precio Factura",  
                    "diferencia":"Dif. P.Factura - P.Pedido",
                    "cantidad_recibida":"Cant. Recibida",
                    "cantidad_faltante":"Cant. Faltante",
                    "cantidad_devuelta":"Cant. Devuelta",
                    "actions": "Opciones"
                },
                rowClassCallback: function (row) {
                    return row.resaltado == 1 ? 'bg-resaltado' : 'bg-default'
                },
                /*cellClasses: {
                    precio_proveedor: [{
                        class: 'bg-seleccionado',
                        condition: row => row.precio_proveedor != null && row.precio_proveedor != '' && parseFloat(parseFloat(row.precio_proveedor).toFixed(2)) < parseFloat(row.precio_compra_con_iva)
                    },
                    {
                        class: 'bg-rojo',
                        condition: row => row.precio_proveedor != null && row.precio_proveedor != '' && parseFloat(parseFloat(row.precio_proveedor).toFixed(2)) > parseFloat(row.precio_compra_con_iva)
                    }]
                }*/
            },

            rules: {
                required: v => !!v || "Este campo es requerido",
                entero: function(numero) {
                    const pattern = /^[\d]*$/;
                    return pattern.test(numero) || "Debe ser un número entero";
                },
            },
            search: "",
            searchProveedor: "",
            searchCategoria: "",
            registros: {
                selected: [],
                items: []
            },
            sucursales: [],
            all_sucursales: [],
            articulos: [],
            proveedores: [],
            categorias: [],
            articulos_encontrados: [],
            model_vista_factura: {
                proveedor:{
                    nombre_proveedor:"",
                }
            },
        };
    },
    methods: {
        onFilterChange(filter) {            
            // Actualiza los filtros guardados cuando cambian
            if(filter.name){
                this.filters[filter.name] = filter.value;
            }        
        },
        onStatusChanged(event) {
            this.registros.items = []; 
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        },
        goHome() {
               this.$router.replace("/").catch(()=>{});
        },
        set_fechas_periodo:function(){
            //Al bloquear las fechas se pone el periodo de los ultimos 12 meses y se desabilitan las fechas
            if(this.bloquear_fechas && this.bloquear_fechas.toString() == "1"){
                var today = window.moment().format("YYYY-MM-DD");
                this.fecha_de_venta = window.moment().subtract(12, 'months').format("YYYY-MM-DD");
                this.fecha_a_venta = today;
            }            
        },
        modal_proveedor_articulo: function(){
            if(this.id_proveedor != null && this.id_proveedor!=""){
                var self = this;
                window.dialogLoader.show('Espere un momento por favor..');
                
                var proveedor = this.getProveedor(this.id_proveedor);
                this.nombre_proveedor_seleccionado = proveedor.nombre;
                var busca = encodeURI(escape(this.id_proveedor));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                .then(async responseArticulo => {

                    if(responseArticulo.data != null && responseArticulo.data.rows!=null && responseArticulo.data.rows.length>0){
                        var rows = responseArticulo.data.rows;
                        this.articulos_proveedor = [];
                        for(var k in rows){
                            var record = {
                                "_id": rows[k].value._id,
                                "nombre": rows[k].value.nombre,
                                "codigo_barras": rows[k].value.codigo_barras,
                                "descripcion": rows[k].value.descripcion,
                                "categoria": rows[k].value.categoria.join(", "),
                            };
                            this.articulos_proveedor.push(record);
                        }
                    } else {
                        this.articulos_proveedor = [];
                    }
                    //this.model = response.data;
                    this.modal_proveedor = true;
                    this.search = "";
                    })
                    .catch(error => {

                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos",
                        footer: ""
                    });
                    }).then(()=>{
                        window.dialogLoader.hide();                        
                    });
                

            } else {
                this.$swal({
                    type: "warning",
                    title: "Seleccione un proveedor!",
                    text: "Para asignar artículos a proveedor, primero seleccione uno.",
                    footer: ""
                });
            }
            
        },
        get_model_factura: function(){
            return {
                type: "compras", //Para las facturas se dejó type=compras para no afectar los demás modulos
                no_orden: "",
                secuencia:1, // 1, 2, 3, ...
                no_orden_origen:"", //Es el no_orden del pedido al que pertenece la factura
                id_orden_origen:"",
                fecha: "",
                id_sucursal_destino:"",
                nombre_sucursal_destino:"",
                tipo_sucursal:"matriz", //matriz|abastos
                plazo_pago:0, //dias de plazo para pago a proveedor
                factura_folio:"",
                factura_fecha:"",
                fecha_recibio_mercancia:"", //Fecha en que se recibió la mercancia
                factura_fecha_vencimiento:"",
                factura_folio_sat: "",
                factura_observaciones:"",
                factura_estatus:"Por Pagar", // "Por Pagar", "Pagada"
                factura_descuento: 0, //porcentaje, al capturar porcentaje se le descuenta a cada articulo de su precio de compra
                                      //hay que guardar el precio original de compra, por si vuelven a modificar el porcentaje
                factura_descuento_concepto:"",
                factura_descuento_total:0,                
                proveedor: {
                    id_proveedor: "",
                    nombre_proveedor: "",
                    plazo: "",
                    contacto: "",
                    email: "",
                    telefono_1: "",
                    condiciones:"",
                },
                estatus: "En Proceso de Entrada", //Orden Abierta, En Proceso de Cotización, Cotización Recibida,
                //Pedido en Proceso, Orden Cancelada, En Proceso de Entrada, Pedido Recibido, Orden Finalizada
                articulos: [],
                usuario: "",
                observaciones_orden: "", //Se necesitan al imprimir la orden
                condiciones_orden:"",
                usuario_entrada: "",
                observaciones_entrada: "",
                fecha_finalizada: "",
            };
        },
        setVencimiento: function(){
            if(this.model_vista_factura.fecha_recibio_mercancia != null && this.model_vista_factura.fecha_recibio_mercancia != ""){
                if(this.model_vista_factura.plazo_pago != null && this.model_vista_factura.plazo_pago != "" && parseInt(this.model_vista_factura.plazo_pago)>0){
                    this.model_vista_factura.factura_fecha_vencimiento = window.moment(this.model_vista_factura.fecha_recibio_mercancia).add(parseInt(this.model_vista_factura.plazo_pago), 'days').format("YYYY-MM-DD");
                } else{
                    this.model_vista_factura.factura_fecha_vencimiento = "";
                }
            } else{
                    this.model_vista_factura.factura_fecha_vencimiento = "";
                }
        },
        setDescuento: function(){
            if(this.model_vista_factura.factura_descuento!=null && this.model_vista_factura.factura_descuento!="" && parseFloat(this.model_vista_factura.factura_descuento)>0 ){
                var total = this.get_total_orden_sin_descuento(this.model_vista_factura.articulos);
                var descuento = (total * parseFloat(this.model_vista_factura.factura_descuento) / 100).toFixed(4);
                this.model_vista_factura.factura_descuento_total = descuento;
            } else {
                this.model_vista_factura.factura_descuento = 0;
                this.model_vista_factura.factura_descuento_total = 0;
            }
        },
        get_precio_articulo_descuento:function(articulo_in){
            try {
                var articulo = JSON.parse(JSON.stringify(articulo_in));
                if(articulo.precio_sin_iva!=null && articulo.precio_sin_iva!="" && parseFloat(articulo.precio_sin_iva)>0){

                    if(isNaN(articulo.iva))
                        articulo.iva = 0;
                    articulo.precio_con_iva = parseFloat((parseFloat(articulo.precio_sin_iva) * (1 + (parseFloat(articulo.iva)/100))).toFixed(4));
                    var descuento = 0;
                    if(articulo.porcentaje_descuento1!=null && articulo.porcentaje_descuento1!="" && parseFloat(articulo.porcentaje_descuento1)>0)
                        descuento += parseFloat(articulo.porcentaje_descuento1)
                    if(articulo.porcentaje_descuento2!=null && articulo.porcentaje_descuento2!="" && parseFloat(articulo.porcentaje_descuento2)>0)
                        descuento += parseFloat(articulo.porcentaje_descuento2)

                    if(!isNaN(descuento)){
                        articulo.precio_proveedor = parseFloat((articulo.precio_con_iva - (articulo.precio_con_iva * (descuento/100))).toFixed(4));
                    } else {
                        articulo.precio_proveedor = articulo.precio_con_iva;
                    }
                    return articulo.precio_proveedor;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }

        },
        get_total_orden_sin_descuento: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].cantidad_recibida != "" && parseFloat(articulos[k].cantidad_recibida)>0){
                    total += parseFloat(articulos[k].precio_con_iva) * parseFloat(articulos[k].cantidad_recibida);
                }                 
            }
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        get_total_orden: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].precio_proveedor != null && articulos[k].precio_proveedor!="" && parseFloat(articulos[k].precio_proveedor)>0
                    && articulos[k].cantidad != null && articulos[k].cantidad != "" && parseFloat(articulos[k].cantidad)>0){
                    total += parseFloat(articulos[k].precio_proveedor) * parseFloat(articulos[k].cantidad);
                }
            }            
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        get_total_orden_factura: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].precio_factura != null && articulos[k].precio_factura!="" && parseFloat(articulos[k].precio_factura)>0
                    && articulos[k].cantidad != null && articulos[k].cantidad != "" && parseFloat(articulos[k].cantidad)>0){
                    total += parseFloat(articulos[k].precio_factura) * parseFloat(articulos[k].cantidad);
                }
            }            
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        get_total_orden_recibida: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].precio_factura != null && articulos[k].precio_factura!="" && parseFloat(articulos[k].precio_factura)>0
                    && articulos[k].cantidad_recibida != null &&articulos[k].cantidad_recibida != "" && parseFloat(articulos[k].cantidad_recibida)>0){
                    total += parseFloat(articulos[k].precio_factura) * parseFloat(articulos[k].cantidad_recibida);
                }
            }
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        get_total_a_pagar: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].precio_proveedor != null && articulos[k].precio_proveedor!="" && parseFloat(articulos[k].precio_proveedor)>0
                    && articulos[k].cantidad_recibida != null &&articulos[k].cantidad_recibida != "" && parseFloat(articulos[k].cantidad_recibida)>0){
                    total += parseFloat(articulos[k].precio_proveedor) * parseFloat(articulos[k].cantidad_recibida);
                }
            }
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        //Obtiene el total usando los precios cotizados del pedido, pero solo de los articulos que coinciden en la factura
        get_total_pedido_factura: function(articulos_orden, articulos_factura){
            var total = 0;
            for(var k in articulos_factura){
                var art = articulos_orden.find(a => a.id_articulo == articulos_factura[k].id_articulo)
                if(art){
                    total += parseFloat(articulos_factura[k].cantidad) * parseFloat(art.precio_proveedor);
                }                    
            }
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        getPrecioPedido: function(row){
            var precio = 0;
            var art = this.model_vista.articulos.find(a => a.id_articulo == row.id_articulo)
            if(art){
                precio = parseFloat(art.precio_proveedor);
            }                    
            
            if(isNaN(precio))
                return 0;
            else
                return parseFloat(precio.toFixed(4));
        },
        get_total_diferencia: function(articulos){
            var total_pagar = this.get_total_a_pagar(articulos);
            var total_recibido = this.get_total_orden_recibida(articulos);
            var diferencia = parseFloat(total_recibido) - parseFloat(total_pagar)
            return diferencia.toFixed(4);
        },
        //Obtiene la cantidad total faltante
        get_faltante_factura:function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].cantidad_faltante != null &&articulos[k].cantidad_faltante != "" && parseFloat(articulos[k].cantidad_faltante)>0){
                    total += parseFloat(articulos[k].cantidad_faltante);
                }
            }
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(2);
        },
        get_total_faltante: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].precio_factura != null && articulos[k].precio_factura!="" && parseFloat(articulos[k].precio_factura)>0
                    && articulos[k].cantidad_faltante != null &&articulos[k].cantidad_faltante != "" && parseFloat(articulos[k].cantidad_faltante)>0){
                    total += parseFloat(articulos[k].precio_factura) * parseFloat(articulos[k].cantidad_faltante);
                }
            }
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        get_total_devuelto: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].precio_proveedor != null && articulos[k].precio_proveedor!="" && parseFloat(articulos[k].precio_proveedor)>0
                    && articulos[k].cantidad_devuelta != null &&articulos[k].cantidad_devuelta != "" && parseFloat(articulos[k].cantidad_devuelta)>0){
                    total += parseFloat(articulos[k].precio_proveedor) * parseFloat(articulos[k].cantidad_devuelta);
                }
            }
            if(isNaN(total))
                return 0;
            else
                return total.toFixed(4);
        },
        actualizar_precio_proveedor_facturas: function(orden){
            let data = {
                selector: {"type":"compras","id_orden_origen":orden._id},
                use_index:"_design/a382e7c18fba466ff6baeb139f153de0bcf96ece"
            };
            window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
            .then(responseFacturas => {
                if (responseFacturas.data.docs.length) {
                    var facturas = responseFacturas.data.docs;
                    for(var k in facturas){


                        var factura = facturas[k];
                        for(var ka in orden.articulos){
                            for(var kf in factura["articulos"]){
                                if(factura["articulos"][kf].id_articulo == orden.articulos[ka].id_articulo){
                                    factura["articulos"][kf].precio_proveedor = orden.articulos[ka].precio_proveedor;
                                    factura["articulos"][kf].precio_proveedor_sin_iva = orden.articulos[ka].precio_proveedor_sin_iva;
                                }
                            }
                        }

                        window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + factura._id + '?conflicts=true', factura)
                        .then(response => {
                            
                        })
                        .catch(error => {
                            console.log("Error al actualizar factura: "+factura.no_orden);
                            console.log("Error: ",error);
                        })
                    
                    }

                }                            
                
            })
            .catch(error => {
                console.log("Error al actualziar articulos en facturas: ", error);
            }); 
        },
        infiniteHandler: function ($state) {
            var rows_per_page = 10;
            var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
            var skip = page * rows_per_page;
            var where = {
                "type": "pedidos",
                "estatus":{"$exists":true},
                "fecha": {"$exists":true}
            };

            this.options.filterable.forEach(x => {
                if (this.model_filters[x]) {
                    where[x] = {
                        "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    }
                }
            });

            window.axios({
                    method: 'POST',
                    url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
                    withCredentials: true,
                    data: {
                        limit: rows_per_page,
                        skip: skip,
                        selector: where,
                        sort: [{"type": "desc"},{"fecha": "desc"}], //Si se pone sort tambien debe estar en el indice para mas rapido
                        "use_index": "_design/060eba6482245aa90249dd488f5ee4f0"                        
                    },
                }).then(response => {
                    if (response.data.docs.length) {
                        var unique_records = new Set(this.registros.items.map(item => item._id));
                        unique_records = Array.from(unique_records.values());                    
                        var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
                        this.registros.items = this.registros.items.concat(nuevos);  
                        this.props_paginacion.total_registros = this.registros.items.length;
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getProveedoresCombo() {
            this.loadingProveedores = true;
            let data = {
                "selector": {
                    "type": "proveedores",
                    "estatus":"Activo"
                },
                //"fields":["_id", "nombre"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.proveedores_combo = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(()=>{
                    this.loadingProveedores=false;
                });
        },

        getProveedor: function(id_proveedor){
            var filtro = this.proveedores_combo.find(function(e){
                return e._id == id_proveedor;
            });
            if(filtro){
                return filtro;
            } else {
                return false;
            }
        },
        blobToBase64: function (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(resolve => {
                reader.onloadend = () => {
                    var dataUrl = reader.result;
                    var base64 = dataUrl.split(',')[1];
                    resolve(base64);
                };
            });
        },

        validaCorreo: function (val) {
            if (val != null && val != "") {
                var correos = val.split(",");
                for (var k in correos) {
                    var correo = correos[k].toString().trim();
                    if (!window.funciones.validaCorreo(correo)) {
                        return "Hay un correo no valido. Favor de verificar.";
                        break;
                    }
                }
            }
            return true;
        },

        get_nombre_corto_proveedor: function(nombre_proveedor){
            if(nombre_proveedor==undefined || nombre_proveedor==null || nombre_proveedor=="")
                return "";

            var split = nombre_proveedor.split(" ");
            var palabras = [];
            var corto = ""
            for(var k in split){
                if(split[k].length>3 && palabras.length<2){
                    palabras.push(split[k].replaceAll(",","").replaceAll(".",""));
                }
            }

            return palabras.join("");
        },

        enviarCorreo: function () {
            if (this.$refs.formcorreo.validate()) {
                var self = this;

                window.dialogLoader.show('Espere un momento por favor..');

                /*var tabla = document.getElementById("orden_excel");
                var nombreArchivo = "Orden_" + self.model_vista.no_orden;

                var options = {
                    preserveColors: true,
                    exclude: ".noExcel",
                    name: nombreArchivo,
                    sheetName: self.model_vista.no_orden,
                    filename: nombreArchivo, //do not include extension
                    fileext: ".xls", // file extension
                    download: 0, //1 - Para que descargue el archivo, 0 - para que regrese el blob
                }

                var blobData = self.exportExcel(tabla, options);
                */

                let datos = {"id_compra":this.model_vista._id,
                          "fecha": window.moment().format("DD-MM-YYYY HH:mm")};

                window.axios
                .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_compra_proveedor/", datos, {                    
                            "Content-type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Allow": "POST",
                            "cache-control": "no-cache",
                            "responseType": "arraybuffer"
                    })
                    .then(response => {
                        var bytes = response.data;
                        let blob = new Blob([bytes], {
                            type: response.headers["content-type"]
                        });
                        self.blobToBase64(blob).then(base64 => {
                            let data = {
                                correos: self.correo.correos,
                                responder_a: self.correo.responder_a,
                                asunto: self.correo.asunto,
                                mensaje: self.correo.mensaje,
                                archivos: [{
                                    mimetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    data: base64,
                                    nombre: self.get_nombre_corto_proveedor(self.model_vista.proveedor.nombre_proveedor)+"_"+ self.model_vista.no_orden+"_"+ window.moment().format("DD-MM-YYYY")+".xlsx"
                                }]
                            };
                            window.funciones.enviarEmail(data).then(result => {
                                window.dialogLoader.showSnackbar('Correo enviado correctamente', {
                                    color: 'success'
                                });
                                self.modalCorreo = false;
                            }).catch(err => {
                                console.log(err);
                                self.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al enviar el correo. Intente nuevamente por favor.",
                                    footer: ""
                                });
                            }).then(() => {
                                window.dialogLoader.hide();
                            })

                        });

                    })
                    .catch(error => {
                        console.log(error);
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al enviar el correo. Intente nuevamente por favor.",
                            footer: ""
                        });
                        window.dialogLoader.hide();
                    });




            }
        },
        articuloEnOrden: function (id_articulo, id_proveedor) {
            var seleccionado = 0;
            var abiertas = this.registros.items.filter(function(e){
                return e.estatus == "Orden Abierta";
            });
            if( abiertas.length>0){
                for (var k in abiertas) {
                    var mapArt = abiertas[k].articulos.map(a => {
                        return a.id_articulo;
                    });
                    if (abiertas[k].proveedor.id_proveedor == id_proveedor && mapArt.includes(id_articulo)){
                        seleccionado = 1;
                        break;
                    }
                }
            }

            if (seleccionado == 1)
                return true;
            else
                return false;
        },

        consultarSugeridos: function () {
            if (this.id_proveedor != null && this.id_proveedor != "") {
                window.dialogLoader.show('Espere un momento por favor..');

                    var self = this;
                    var proveedor = self.getProveedor(self.id_proveedor);

                    self.limpiartablas();

                    var busca = encodeURI(escape(this.id_proveedor));
                    window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                    .then(async responseArticulo => {

                        if(responseArticulo.data != null && responseArticulo.data.rows!=null && responseArticulo.data.rows.length>0){

                                self.data_sugeridos = [];
                                var articulos_ids = [];
                                //  for (var k in responseArticulo.data.docs) {
                                await Promise.all(responseArticulo.data.rows.map(async (val) => {
                                    var x = val.value;

                                    articulos_ids.push(x._id);

                                    let _max = 0;
                                    let _min = 0;
                                    let _reorden = 0;
                                    let _actual = 0;
                                    for (var j in x.stock) {
                                        _max += parseFloat(x.stock[j].maximo);
                                        _min += parseFloat(x.stock[j].minimo);
                                        _reorden += parseFloat(x.stock[j].reorden);

                                    }

                                        let datos_consulta = {
                                            "id_articulo": x._id,
                                            //Martin solicitó que se muestre la existencia de matriz en la tabla
                                            "id_sucursal": self.suc_matriz._id
                                        };

                                        await window.funciones_lotes.consultaExistencia(datos_consulta).then(async result => {
                                            //console.log("Existencias: ", result);
                                            if (result.length > 0) {
                                                result.forEach(el => {
                                                    _actual += el.existencia;
                                                });
                                            }
                                            //_actual += result;

                                            var record = {
                                                "articulo": x,
                                                "proveedor": proveedor,
                                                "minimo": _min,
                                                "reorden": _reorden,
                                                "maximo": _max,
                                                "actual": _actual,
                                                "pedir": _max - _actual,
                                            };

                                            if(record.pedir >= record.reorden && record.pedir > 0)
                                                self.data_sugeridos.push(record);

                                        }).catch(err => {
                                            console.log("Ocurrió un error al consultar existencia de artículo.");

                                        });

                                    //  _actual = Math.floor(Math.random() * 400) + 1;

                                }));

                            } else {
                                self.data_sugeridos = [];
                                self.$swal({
                                    type: "warning",
                                    title: "¡Proveedor sin artículos!",
                                    text: "El proveedor no tiene artículos relacionados. Favor de verificar.",
                                    footer: ""
                                });
                            }

                        })
                        .catch(error => {
                            self.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al obtener los proveedores del artículo.",
                                footer: ""
                            });
                            console.log(error);
                        }).then(() => {
                            window.dialogLoader.hide();
                        });
            }
        },
        consultarArticulo: function () {
            if (this.id_articulo != null && this.id_articulo != "") {
                this.id_proveedor = null;
                this.columnsArticulos = JSON.parse(JSON.stringify(this.columnsArticulosBakup));
                this.nombre_categoria = null;
                this.$refs.vuetablearticulos.resetQuery();
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;

                self.limpiartablas();

                var busca = encodeURI(escape(self.id_articulo));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_articulo?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        //Solo los articulos que tengan proveedor
                        var rows = response.data.rows.filter(function(item){
                            return item.value.proveedor.id_proveedor != undefined;
                        });  

                        var articulos = [];
                        var proveedores = {};
                        //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                        //Ultimos 30 días
                        var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                        const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                        //Ultimos 60 días
                        var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
                        const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
                        const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");

                        //for (var k in rows) {
                        await Promise.all(rows.map(async (item) => {

                            var proveedor = false;
                            if(proveedores[item.value.proveedor.id_proveedor] != null){
                                proveedor = proveedores[item.value.proveedor.id_proveedor];
                            } else {
                                proveedor = self.getProveedor(item.value.proveedor.id_proveedor);
                                proveedores[item.value.proveedor.id_proveedor] = proveedor;
                            }

                            if(proveedor == false){
                                proveedor = {"nombre":"(INACTIVO) "+item.value.proveedor.nombre_proveedor, "plazo":0, "_id":item.value.proveedor.id_proveedor};
                            }

                            var record = {
                                "articulo": item.value.articulo,
                                "proveedor": proveedor,
                            };
                            record.articulo["ventas_30_dias"] = 0;
                            record.articulo["ventas_60_dias"] = 0;
                            let urlConditions = `&startkey=["${record.articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${record.articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                            let urlConditions2 = `&startkey=["${record.articulo._id}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${record.articulo._id}", "${year_a2}", "${month_a2}", "${day_a2}"]`;

                            await axios
                            .get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        record.articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                //(19-04-2024): Se comenta ventas de 60 dias para aumentar un poco la velocidad de carga
                                /*await axios
                                .get(url + urlConditions2)
                                .then(async response => {

                                    if (response.data.rows.length > 0) {
                                        response.data.rows.forEach(x => {
                                            record.articulo["ventas_60_dias"] += x.value.cantidad;
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });*/

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(record.articulo._id).then(function(conteo){
                                    record.articulo["ventas_30_dias"] += conteo["v30"];
                                    //record.articulo["ventas_60_dias"] += conteo["v60"];                                    
                                });                            

                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _max = 0;
                                let _min = 0;
                                let _reorden = 0;
                                let _actual = 0;
                                let _actual_total = 0;
                                for (var j in record.articulo.stock) {
                                    _max += record.articulo.stock[j].maximo;
                                    _min += record.articulo.stock[j].minimo;
                                    _reorden += record.articulo.stock[j].reorden;
                                }

                                let datos_consulta = {
                                    "id_articulo": record.articulo._id,                                                                        
                                };
                                
                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                    //console.log("Existencias: ", result);
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            if(el.id_sucursal == self.suc_matriz._id){
                                                _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                            }
                                            _actual_total += el.existencia;
                                        });
                                    }
                                    //_actual += result;
                                    record.articulo['minimo'] = _min;
                                    record.articulo['reorden'] = _reorden;
                                    record.articulo['maximo'] = _max;
                                    record.articulo['actual'] = _actual;
                                    record.articulo['actual_total'] = _actual_total;
                                    record.articulo["orden_pendiente"] = self.articuloEnPedidoPendiente(record.articulo._id);
                                    
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo.");

                                });
                            });

                            articulos.push(record);
                        }));
                        if( articulos.length>0)
                            self.articulos_encontrados = articulos;
                        else{
                            self.articulos_encontrados = [];
                            self.$swal({
                                type: "warning",
                                title: "¡Artículo no relacionado!",
                                text: "El artículo, no está relacionado a ningún proveedor. Favor de verificar.",
                                footer: ""
                            });

                        }
                    } else {
                        self.articulos_encontrados = [];
                        self.$swal({
                            type: "warning",
                            title: "¡Artículo no relacionado!",
                            text: "El artículo, no está relacionado a ningún proveedor. Favor de verificar.",
                            footer: ""
                        });
                    }
                })
                .catch(err => {
                    console.log("Error en consultarArticulo: ", err);
                }).then(()=>{
                    window.dialogLoader.hide();
                });

            }
        },
        get_conteo_ventas_articulo: function(id_articulo_padre, cantidad_padre = 1){
            var self = this;
            var conteo = {"v30":0, "v60":0};
            let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
            //Ultimos 30 días
            var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
            var fecha_a_venta = window.moment().format("YYYY-MM-DD");
            const [year_de, month_de, day_de] = fecha_de_venta.split("-");
            const [year_a, month_a, day_a] = fecha_a_venta.split("-");
            //Ultimos 60 días
            var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
            var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
            const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
            const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");

            return new Promise(async function (resolve, reject) {                
                               
                var filter_equivalencias = self.articulos_equivalecias.filter(e => e.id_articulo == id_articulo_padre);
                if(filter_equivalencias.length > 0){
                    
                    for(var ke in filter_equivalencias){
                        var equivalente = filter_equivalencias[ke];
                        
                        var urlConditions = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a}", "${month_a}", "${day_a}"]`;
                        let urlConditions2 = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a2}", "${month_a2}", "${day_a2}"]`;

                        await axios.get(url + urlConditions)
                        .then(async response => {

                            if (response.data.rows.length > 0) {
                                response.data.rows.forEach(x => {
                                    var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                    conteo["v30"] += cantidad_calculada;                                        
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });    
                        
                        //(19-04-2024): Se comenta ventas de 60 dias para aumentar un poco la velocidad de carga
                        /*await axios.get(url + urlConditions2)
                        .then(async response => {

                            if (response.data.rows.length > 0) {
                                response.data.rows.forEach(x => {
                                    var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                    conteo["v60"] += cantidad_calculada;                                        
                                });
                            }
                            
                        })
                        .catch(error => {
                            console.log(error);
                        });*/

                        await self.get_conteo_ventas_articulo(equivalente['id_articulo_equivalente'], equivalente["cantidad"]).then(function(conteo2){
                            conteo["v30"] += conteo2["v30"];
                            //conteo["v60"] += conteo2["v60"];                                
                        });

                    }
                    return resolve(conteo);
                } else {
                    return resolve(conteo);
                }

            });
            
        },
        
        getMonto(row){
            var total = 0;
            if(row.cantidad!=null && row.cantidad!="" && parseFloat(row.cantidad)>0 &&
                row.precio_proveedor!=null && row.precio_proveedor!="" && parseFloat(row.precio_proveedor)>0
            ){
                total = parseFloat(row.cantidad) * parseFloat(row.precio_proveedor)
            }
            return this.formatNumberDec(total.toFixed(4),2);
        },
        get_articulos_proveedor: function(id_proveedor=""){
            if (id_proveedor != "")//Si trae el proveedor como parametro es que estan editando una orden directa
                this.id_proveedor = id_proveedor;
            if(this.id_proveedor != null && this.id_proveedor!=""){
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;
                var busca = encodeURI(escape(self.id_proveedor));
                this.articulos_proveedor_seleccionado_directo = [],
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        var rows = response.data.rows;
                        for(var k in rows){
                            var record = {
                                "_id": rows[k].value._id,
                                "nombre": rows[k].value.nombre,
                                "codigo_barras": rows[k].value.codigo_barras,
                                "descripcion": rows[k].value.descripcion,
                                "categoria": rows[k].value.categoria,
                                "impuestos":rows[k].value.impuestos,
                                "precio_compra":rows[k].value.precio_compra,
                                "precio_compra_con_iva":rows[k].value.precio_compra_con_iva,
                            };
                            this.articulos_proveedor_seleccionado_directo.push(record);
                        }
                        this.update_colors_lista_izquierda();
                    }
                }).catch(err => {
                    console.log("Error en get_articulos_proveedor: ", err);
                }).then(()=>{
                    window.dialogLoader.hide();
                });
            }
        },
        consultarProveedor_combo: function () {
            if(this.id_proveedor != null && this.id_proveedor!=""){
                this.id_articulo = null;
                this.columnsArticulos = JSON.parse(JSON.stringify(this.columnsArticulosBakup));
                this.columnsArticulos = this.columnsArticulos.filter(e => e!="proveedor.nombre");//quitamos columna proveedor
                this.nombre_categoria = null;
                this.$refs.vuetablearticulos.resetQuery();
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;
                var proveedor = self.getProveedor(self.id_proveedor);

                self.limpiartablas();

                var busca = encodeURI(escape(self.id_proveedor));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        var rows = response.data.rows;

                        var articulos = [];
                        //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                        //Ultimos 30 días
                        var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                        const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                        //Ultimos 60 días
                        var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
                        const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
                        const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");

                        
                        //Existencias
                        var articulos_existencias = [];
                        if(rows.length > 300){ //Solo si son mas de 300 artículos, traemos las existencias de todos, sino que lo haga de uno en uno
                            await window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_articulo?group_level=2')
                            .then(response => {
                                if(response.data!=null && response.data.rows!=null){
                                    response.data.rows.forEach(element => {
                                        if(rows.find(item => item.value._id == element.key[0]) && element.value > 0)
                                            articulos_existencias.push({"id_articulo":element.key[0],"id_sucursal":element.key[1], "existencia":element.value})
                                    });                                
                                }
                            })
                            .catch(err => {
                                self.$swal({
                                    icon: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al obtener las existencias. Intente nuevamente.",
                                    footer: err
                                });
                                return false;
                            });
                        }
                        
                        
                        //for (var k in rows) {
                        await Promise.all(rows.map(async (item) => {
                            var record = {
                                "articulo": item.value,
                                "proveedor": proveedor,
                            }

                            record.articulo["ventas_30_dias"] = 0;
                            record.articulo["ventas_60_dias"] = 0;
                            let urlConditions = `&startkey=["${record.articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${record.articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                            let urlConditions2 = `&startkey=["${record.articulo._id}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${record.articulo._id}", "${year_a2}", "${month_a2}", "${day_a2}"]`;

                            await axios
                            .get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        record.articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                //(19-04-2024): Se comenta ventas de 60 dias para aumentar un poco la velocidad de carga
                                /*await axios
                                .get(url + urlConditions2)
                                .then(async response => {

                                    if (response.data.rows.length > 0) {
                                        response.data.rows.forEach(x => {
                                            record.articulo["ventas_60_dias"] += x.value.cantidad;
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });*/

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(record.articulo._id).then(function(conteo){
                                    record.articulo["ventas_30_dias"] += conteo["v30"];
                                    //record.articulo["ventas_60_dias"] += conteo["v60"];                                    
                                });

                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _max = 0;
                                let _min = 0;
                                let _reorden = 0;
                                let _actual = 0;
                                let _actual_total = 0;
                                for (var j in record.articulo.stock) {
                                    _max += record.articulo.stock[j].maximo;
                                    _min += record.articulo.stock[j].minimo;
                                    _reorden += record.articulo.stock[j].reorden;
                                }

                                if(articulos_existencias.length > 0){
                                    var exis_art = articulos_existencias.filter(e => e.id_articulo == record.articulo._id);
                                    if(exis_art.length > 0){
                                        exis_art.forEach(function(el){
                                            if(el.id_sucursal == self.suc_matriz._id){
                                                _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                            }
                                            _actual_total += el.existencia;
                                        });
                                    }
                                    record.articulo['minimo'] = _min;
                                    record.articulo['reorden'] = _reorden;
                                    record.articulo['maximo'] = _max;
                                    record.articulo['actual'] = _actual;
                                    record.articulo['actual_total'] = _actual_total;
                                    record.articulo["orden_pendiente"] = self.articuloEnPedidoPendiente(record.articulo._id);
                                } else {
                                    let datos_consulta = {
                                        "id_articulo": record.articulo._id,                                                                        
                                    };
                                    await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                        //console.log("Existencias: ", result);
                                        if (result.length > 0) {
                                            result.forEach(el => {
                                                if(el.id_sucursal == self.suc_matriz._id){
                                                    _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                                }
                                                _actual_total += el.existencia;
                                            });
                                        }
                                        //_actual += result;
                                        record.articulo['minimo'] = _min;
                                        record.articulo['reorden'] = _reorden;
                                        record.articulo['maximo'] = _max;
                                        record.articulo['actual'] = _actual;
                                        record.articulo['actual_total'] = _actual_total;
                                        record.articulo["orden_pendiente"] = self.articuloEnPedidoPendiente(record.articulo._id);
                                        
                                    }).catch(err => {
                                        console.log("Ocurrió un error al consultar existencia de artículo.");

                                    });
                                }
                                
                            });

                            articulos.push(record);
                        }));

                        self.articulos_encontrados = articulos;

                    } else {
                        self.articulos_encontrados = [];
                        self.$swal({
                            type: "warning",
                            title: "Proveedor sin artículos!",
                            text: "El proveedor, no tiene artículos relacionados. Favor de verificar.",
                            footer: ""
                        });
                    }
                })
                .catch(err => {
                    console.log("Error en consultarProveedor: ", err);
                }).then(()=>{
                    window.dialogLoader.hide();
                });
            }

        },

        getCategorias() {

            this.loadingCategorias = true;
            let data = {
                "selector": {
                    "type": "categorias",
                    "estatus": "Activo"
                },
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.categorias = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las categorias.",
                        footer: ""
                    });
                }).then(() => {
                    this.loadingCategorias = false;
                });
        },
        getDescripciones: function() {
            let data = {
                "selector": {
                "type": "descripciones",
                },
            };

            window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
            if (response.data.docs.length > 0) {
                response.data.docs.sort(this.ordenar_descripcion);
                this.descripciones = response.data.docs;                
                this.optionsArticulos.listColumns["articulo.descripcion"] = this.descripciones.map(function(item) {
                    return {"id":item.descripcion,"text":item.descripcion}
                });
                this.optionsArticulosSugeridos.listColumns["articulo.descripcion"] = this.optionsArticulos.listColumns["articulo.descripcion"];
            } else{
                this.descripciones = [];                
            }
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las descripciones.",
                    footer: ""
                });
                console.log(error);
            });
        },
        getEquivalencias: function(){
            //Equivalencias
            var self = this;
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias')
            .then(responseEquivalencias => {
                if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {                    
                    responseEquivalencias.data.rows.forEach(function(item){
                        self.articulos_equivalecias.push(item.value);
                    });                        
                } 
            })
            .catch(error => {
                console.log("ERROR al buscar ventas de quivalencia: ", error);                   
            });
        },
        ordenar_descripcion: function(a, b) {
            if (a.descripcion.toLowerCase() < b.descripcion.toLowerCase()) {
                return -1;
            }
            if (a.descripcion.toLowerCase() > b.descripcion.toLowerCase()) {
                return 1;
            }
            return 0;
        },
        ordenar_nombre: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
        getArticulos:function(){
            this.loadingArticulos = true;
            let data = {
                "selector": {
                    "type": "articulos",
                    "estatus":"Activo",
                    //"proveedores.0": { "$exists": true }
                },
                "fields":["_id","nombre","descripcion","categoria","codigo_barras","caducidad","impuestos","precio_compra","precio_compra_con_iva"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };
            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.articulos = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(() => {
                    this.loadingArticulos = false;
                });
        },
        getArticulo: function(id_articulo){
            var filtro = this.articulos.find(function(e){
                return e._id == id_articulo;
            });
            if(filtro){
                return filtro;
            } else {
                return false;
            }
        },
        consultarCategoria: function () {
            if (this.nombre_categoria != null && this.nombre_categoria != "") {                
                this.id_articulo = null;
                this.id_proveedor = null;
                this.columnsArticulos = JSON.parse(JSON.stringify(this.columnsArticulosBakup));
                this.columnsArticulos = this.columnsArticulos.filter(e => e!="articulo.categoria");//quitamos columna categoria
                this.$refs.vuetablearticulos.resetQuery();
                window.dialogLoader.show('Espere un momento por favor..');

                var self = this;

                self.limpiartablas();

                var busca = encodeURI(escape(this.nombre_categoria.toString().trim().toLowerCase()));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_categoria?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        //Solo los articulos que tengan proveedor
                        var rows = response.data.rows.filter(function(item){
                            return item.value.proveedor.id_proveedor != undefined;
                        });                      

                        var articulos = [];
                        var proveedores = {};
                        //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                        //Ultimos 30 días
                        var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                        const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                        //Ultimos 60 días
                        var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
                        const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
                        const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");

                        //Existencias
                        var articulos_existencias = [];
                        if(rows.length > 300){ //Solo si son mas de 300 artículos, traemos las existencias de todos, sino que lo haga de uno en uno
                            await window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_articulo?group_level=2')
                            .then(response => {
                                if(response.data!=null && response.data.rows!=null){
                                    response.data.rows.forEach(element => {
                                        if(rows.find(item => item.value.articulo._id == element.key[0]) && element.value > 0)
                                            articulos_existencias.push({"id_articulo":element.key[0],"id_sucursal":element.key[1], "existencia":element.value})
                                    });                                
                                }
                            })
                            .catch(err => {
                                self.$swal({
                                    icon: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al obtener las existencias. Intente nuevamente.",
                                    footer: err
                                });
                                return false;
                            });
                        }

                        //for (var k in rows) {
                        await Promise.all(rows.map(async (item) => {
                            var proveedor = false;
                            if(item.value.proveedor.id_proveedor != undefined){
                                if(proveedores[item.value.proveedor.id_proveedor] != null){
                                    proveedor = proveedores[item.value.proveedor.id_proveedor];
                                } else {
                                    proveedor = self.getProveedor(item.value.proveedor.id_proveedor);
                                    proveedores[item.value.proveedor.id_proveedor] = proveedor;
                                }

                                if(proveedor == false){
                                    proveedor = {"nombre":"(INACTIVO) "+item.value.proveedor.nombre_proveedor, "plazo":0, "_id":item.value.proveedor.id_proveedor};
                                }
                            } else {
                                proveedor = {};
                            }


                            var record = {
                                "articulo": item.value.articulo,
                                "proveedor": proveedor,
                            };

                            record.articulo["ventas_30_dias"] = 0;
                            record.articulo["ventas_60_dias"] = 0;
                            let urlConditions = `&startkey=["${record.articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${record.articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                            let urlConditions2 = `&startkey=["${record.articulo._id}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${record.articulo._id}", "${year_a2}", "${month_a2}", "${day_a2}"]`;

                            await axios
                            .get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        record.articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                //(19-04-2024): Se comenta ventas de 60 dias para aumentar un poco la velocidad de carga
                                /*await axios
                                .get(url + urlConditions2)
                                .then(async response => {

                                    if (response.data.rows.length > 0) {
                                        response.data.rows.forEach(x => {
                                            record.articulo["ventas_60_dias"] += x.value.cantidad;
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });*/

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(record.articulo._id).then(function(conteo){
                                    record.articulo["ventas_30_dias"] += conteo["v30"];
                                    //record.articulo["ventas_60_dias"] += conteo["v60"];                                    
                                });

                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _max = 0;
                                let _min = 0;
                                let _reorden = 0;
                                let _actual = 0;
                                let _actual_total = 0;
                                for (var j in record.articulo.stock) {
                                    _max += record.articulo.stock[j].maximo;
                                    _min += record.articulo.stock[j].minimo;
                                    _reorden += record.articulo.stock[j].reorden;
                                }

                                if(articulos_existencias.length > 0){
                                    var exis_art = articulos_existencias.filter(e => e.id_articulo == record.articulo._id);
                                    if(exis_art.length > 0){
                                        exis_art.forEach(function(el){
                                            if(el.id_sucursal == self.suc_matriz._id){
                                                _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                            }
                                            _actual_total += el.existencia;
                                        });
                                    }
                                    record.articulo['minimo'] = _min;
                                    record.articulo['reorden'] = _reorden;
                                    record.articulo['maximo'] = _max;
                                    record.articulo['actual'] = _actual;
                                    record.articulo['actual_total'] = _actual_total;
                                    record.articulo["orden_pendiente"] = self.articuloEnPedidoPendiente(record.articulo._id);
                                } else {
                                    let datos_consulta = {
                                        "id_articulo": record.articulo._id,                                                                        
                                    };
                                    
                                    await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                        //console.log("Existencias: ", result);
                                        if (result.length > 0) {
                                            result.forEach(el => {
                                                if(el.id_sucursal == self.suc_matriz._id){
                                                    _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                                }
                                                _actual_total += el.existencia;
                                            });
                                        }
                                        //_actual += result;
                                        record.articulo['minimo'] = _min;
                                        record.articulo['reorden'] = _reorden;
                                        record.articulo['maximo'] = _max;
                                        record.articulo['actual'] = _actual;
                                        record.articulo['actual_total'] = _actual_total;
                                        record.articulo["orden_pendiente"] = self.articuloEnPedidoPendiente(record.articulo._id);
                                        
                                    }).catch(err => {
                                        console.log("Ocurrió un error al consultar existencia de artículo.");
                                    });
                                }
                                
                            });

                            articulos.push(record);
                        }));
                        if( articulos.length>0){
                            self.articulos_encontrados = articulos;                            
                        } else{
                            self.articulos_encontrados = [];
                            self.$swal({
                                type: "warning",
                                title: "¡Artículo no relacionado!",
                                text: "Los artículos de la categoría, no está relacionados a ningún proveedor. Favor de verificar.",
                                footer: ""
                            });

                        }
                    } else {
                        self.articulos_encontrados = [];
                        window.dialogLoader.hide();
                        self.$swal({
                            type: "warning",
                            title: "¡Artículos no encontrados!",
                            text: "No hay artículos en esta categoría. Favor de verificar.",
                            footer: ""
                        });
                    }

                }).catch(error => {
                    window.dialogLoader.hide();
                    console.log(error);
                    self.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos filtrados.",
                        footer: ""
                    });
                }).then(()=>{
                    window.dialogLoader.hide();
                });
            }
        },
        formatNumber: function (x) {
            if (x == undefined) return 0;
            x = x.toString();
            var pattern = /(-?\d+)(\d{3})/;
            while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
            return x;
        },
        formatNumberDec: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                return "0";
            numero = Number(numero);
            if (isNaN(numero)){
                return "0";
            } else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        parse_date(date) {
            if (!date) return null;
            return window.moment(String(date)).format("YYYY-MM-DD");
        },
        parse_date_mx(date) {
            if (!date) return null;
            return window.moment(String(date)).format("DD-MM-YYYY");
        },
        parse_date_time(date) {
            if (!date) return null;
            return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
        },
        parse_date_time_mx(date) {
            if (!date) return null;
            return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
        },
        
        reloadFacturas: function(row_in){
            this.facturas_items = [];
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row_in._id)
            .then(response => {
                var row = response.data;
                this.model_vista = row;

                let data = {
                    selector: {"type":"compras","id_orden_origen":row_in._id},
                    use_index:"_design/a382e7c18fba466ff6baeb139f153de0bcf96ece"
                };
                window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
                .then(responseFacturas => {
                    if (responseFacturas.data.docs.length) {
                        this.facturas_items = responseFacturas.data.docs;
                    }                    
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las facturas. Intente nuevamente.",
                        footer: ""
                    });
                });                 
                
            })
            .catch(error => {                
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las facturas. Intente nuevamente.",
                    footer: ""
                });
            }); 
        },
        showFacturas: function(row_in, model_factura=""){
            var cumple = 0;
            this.articulos_facturas_previas = [];
            if(row_in.orden_creada && row_in.orden_creada=="directa"){//Si es orden directa no se verifica que hayan cargado cotización                
                cumple = 1;
            } else { //Si no es directa se verifica que para agregar facturas se haya cargado cotización, ya que al agregar articulos a factura se necesita el precio_proveedor
                var articulos_cumplen = 0;
                //Si todos los articulos ya tienen su precio_proveedor cumple, si no no
                for(var k in row_in.articulos){
                    if(row_in.articulos[k]["precio_proveedor"] && row_in.articulos[k]["precio_proveedor"]!="" && parseFloat(row_in.articulos[k]["precio_proveedor"]) >= 0){
                        articulos_cumplen += 1;
                    }
                }
                if(articulos_cumplen >= row_in.articulos.length){
                    cumple = 1;
                } else {
                    cumple = 0;
                }
            }

            if(cumple == 1){
                //buscar las facturas type=compras, que tengan el id_orden_origen = row_in._id
                //mostrar modal con tabla de facturas y boton de agregar facturas
                this.facturas_items = [];
            window.dialogLoader.show('Espere un momento por favor..');
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row_in._id)
                .then(response => {
                    var row = response.data;
                    this.model_vista = row;

                    let data = {
                        selector: {"type":"compras","id_orden_origen":row_in._id},
                        use_index:"_design/a382e7c18fba466ff6baeb139f153de0bcf96ece"
                    };
                    window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
                    .then(responseFacturas => {
                        if (responseFacturas.data.docs.length) {
                            this.facturas_items = responseFacturas.data.docs;
                            this.facturas_items.forEach(factura => {
                                factura["articulos"].forEach(articulo => {
                                    //Checar si el articulo de la factura está en el pedido y si ya cumplió con la cantidad pedida
                                    var art_pedido = this.model_vista.articulos.find(e => e.id_articulo == articulo["id_articulo"]);
                                    if(art_pedido && parseFloat(art_pedido.cantidad) <= parseFloat(articulo["cantidad"])){
                                        this.articulos_facturas_previas.push(articulo["id_articulo"]);
                                    }
                                });
                            });
                        }
                        
                        this.modalVistaFacturas = true;
                        if(model_factura != ""){
                            this.setDatosFactura(model_factura);
                        } 
                        
                        
                    })
                    .catch(error => {
                        window.dialogLoader.hide();
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al obtener las facturas. Intente nuevamente.",
                            footer: ""
                        });
                    }).then(()=>{
                        window.dialogLoader.hide();
                    });                 
                    
                })
                .catch(error => {
                    window.dialogLoader.hide();
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las facturas. Intente nuevamente.",
                        footer: ""
                    });
                }); 
            } else {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Para agregar facturas, primero debe cargar la cotización del proveedor.",
                    footer: "Verifique que todos los artículos del pedido tengan precio del proveedor"
                });
            }
            
        },
        inicializaFactura: function(){

            this.model_vista_factura = this.get_model_factura();
            var model_copy = JSON.parse(JSON.stringify(this.model_vista));

            this.model_vista_factura.no_orden_origen = model_copy.no_orden; //Es el no_orden del pedido al que pertenece la factura
            this.model_vista_factura.id_orden_origen = model_copy._id;
            if(model_copy.orden_creada)//Solo si es directa traerá este campo
                this.model_vista_factura.orden_creada = model_copy.orden_creada;
            this.model_vista_factura.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
            this.model_vista_factura.factura_fecha = window.moment().format("YYYY-MM-DD");
            this.model_vista_factura.fecha_recibio_mercancia = window.moment().format("YYYY-MM-DD");
            this.model_vista_factura.id_sucursal_destino = model_copy.id_sucursal_destino;
            this.model_vista_factura.nombre_sucursal_destino = model_copy.nombre_sucursal_destino;
            this.model_vista_factura.tipo_sucursal = model_copy.tipo_sucursal;
            this.model_vista_factura.proveedor = model_copy.proveedor;
            this.model_vista_factura.estatus = model_copy.estatus;
            this.model_vista_factura.usuario = this.$local_storage.get("sb_usuario");

            if(model_copy.orden_creada && model_copy.orden_creada=="directa"){
                this.get_articulos_proveedor(this.model_vista_factura.proveedor.id_proveedor);
                this.modalFacturaDirecta = true;
            } else {//normal
                this.modalFactura = true;
                this.update_colors_lista_izquierda();
            }
            
            
            setTimeout(() => {
                this.$refs["vuetable_articulos_all"].$refs.table.resetQuery();
                this.$refs["vuetable_articulos_proveedor"].$refs.table.resetQuery();
            }, 150);
                

        },
        get_numero_suborden:function(modelo){
            var suborden = 1;
            var conjunto1 = []; 
            for (var i = 1; i <= 100; i++) {//Suponemos que no serán mas de 100 facturas en una orden
                conjunto1.push(i);
            }
            
            if( modelo.facturas && modelo.facturas.length){
                var secuencia = modelo.facturas.map(item => item.secuencia);
                //console.log("Secuencia: ", secuencia);
                let diferentes = conjunto1.filter((numero) => !secuencia.includes(numero));
                diferentes.sort((a, b) => a - b);
                //console.log("Diferentes: ", diferentes);
                suborden = diferentes[0]; //Solo nos importa el que falta primero en la secuencia
            } 
            return suborden;
        },
        
        setDatosFactura: function(row_in, opcion=""){
            //this.modalFactura = false;
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row_in._id)
            .then(response => {                
                var row = response.data;
                this.model_vista_factura = row;

                if(row.factura_fecha != null && row.factura_fecha != ""){
                        this.model_vista_factura.factura_fecha = moment(String(row.factura_fecha)).format("YYYY-MM-DD");
                }
                if(row.fecha_recibio_mercancia != null && row.fecha_recibio_mercancia != ""){
                        this.model_vista_factura.fecha_recibio_mercancia = moment(String(row.fecha_recibio_mercancia)).format("YYYY-MM-DD");
                }
                if(row.factura_fecha_vencimiento != null && row.factura_fecha_vencimiento != ""){
                    this.model_vista_factura.factura_fecha_vencimiento = moment(String(row.factura_fecha_vencimiento)).format("YYYY-MM-DD");
                }

                this.descuento_factura_modal = parseFloat(row.factura_descuento);

                if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){                    
                    this.modalFacturaDirecta = true;
                } else {
                    this.modalFactura = true;
                }

                if(opcion == "opcion_editar"){//Solo si se manda llamar de la opcion de editar factura
                    this.get_articulos_proveedor(this.model_vista_factura.proveedor.id_proveedor);
                } else {
                    this.update_colors_lista_izquierda();
                }
                                                    
                setTimeout(() => {
                    this.$refs["vuetable_articulos_all"].$refs.table.resetQuery();
                    this.$refs["vuetable_articulos_proveedor"].$refs.table.resetQuery();
                }, 150);
                                                            
            })
            .catch(error => {
                console.log("Error: ", error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{                
                window.dialogLoader.hide();
            });

            
        },

        verArticulos: function (row) {
            window.dialogLoader.show('Espere un momento por favor..');
            this.observaciones_entrada_facturas = [];
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(async response => {
                row = response.data;
                //this.articulos_vista = row.articulos;
                this.model_vista = row;
                var articulos_en_factura = {};
                this.total_factura_mercancia_recibida = 0;
                this.total_factura_mercancia_faltante = 0;
                this.total_factura_mercancia_devuelta = 0; 
                //Obtener total de facturas de la mercancía recibida
                let data = {
                    selector: {"type":"compras","id_orden_origen":this.model_vista._id},
                    use_index:"_design/a382e7c18fba466ff6baeb139f153de0bcf96ece"
                };
                await window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
                .then(responseFacturas => {
                    if (responseFacturas.data.docs.length) {
                        for(var k in responseFacturas.data.docs){
                            var arts = responseFacturas.data.docs[k].articulos;
                            for(var ka in arts){
                                this.total_factura_mercancia_recibida += parseFloat(arts[ka].cantidad_recibida) * parseFloat(arts[ka].precio_proveedor);
                                if(arts[ka].cantidad_faltante && parseFloat(arts[ka].cantidad_faltante)>0)
                                    this.total_factura_mercancia_faltante += parseFloat(arts[ka].cantidad_faltante) * parseFloat(arts[ka].precio_proveedor);
                                if(arts[ka].cantidad_devuelta && parseFloat(arts[ka].cantidad_devuelta)>0)
                                    this.total_factura_mercancia_devuelta += parseFloat(arts[ka].cantidad_devuelta) * parseFloat(arts[ka].precio_proveedor);
                                if(articulos_en_factura[arts[ka].id_articulo]){
                                    articulos_en_factura[arts[ka].id_articulo] += parseFloat(arts[ka].cantidad_recibida);
                                } else {
                                    articulos_en_factura[arts[ka].id_articulo] = parseFloat(arts[ka].cantidad_recibida);
                                }
                            }

                            if(responseFacturas.data.docs[k].observaciones_entrada && responseFacturas.data.docs[k].observaciones_entrada!=""){
                                //console.log("Obs Entrada: ", responseFacturas.data.docs[k].observaciones_entrada);
                                var texto = "Factura "+responseFacturas.data.docs[k].secuencia+": "+responseFacturas.data.docs[k].observaciones_entrada;
                                this.observaciones_entrada_facturas.push(texto);
                            }
                                
                            
                        }
                        
                        
                    }                    
                })
                .catch(error => {
                    console.log("Error al obtener facturas: ", error);
                }).then(()=>{
                    //console.log("Art en fac: ", articulos_en_factura);
                    for(var k in this.model_vista.articulos){
                        if(articulos_en_factura[this.model_vista.articulos[k].id_articulo]){
                            this.model_vista.articulos[k]["cantidad_recibida_facturas"] = articulos_en_factura[this.model_vista.articulos[k].id_articulo];
                        }
                    }
                    this.modalArticulos = true;
                    this.keyTableArticulosOrden = moment(new Date()).format("x"); //Refrescar tabla
                    window.dialogLoader.hide();
                });  

            })
            .catch(error => {
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            });
        },

        verArticulosFactura: function (row) {
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                this.model_vista_factura = row;
                this.modalArticulosFactura = true;

                if(this.model_vista_factura.estatus == "Orden Finalizada" && !this.verificaPermiso('t.compras.editar_factura')){
                    this.$swal({
                        icon: "info",
                        title: "Importante!",
                        text: "La factura ya ha sido recibida en almacén. Para modificarla necesita permisos especiales. Comuníquese con el Administrador.",                    
                        footer: ""
                    });                
                }else if (this.model_vista_factura.estatus == "Orden Finalizada" && this.verificaPermiso('t.compras.editar_factura')){
                    this.$swal({
                        icon: "info",
                        title: "Cuidado!",
                        text: "La factura ya ha sido recibida en almacén. Si modifica la información tendrá que hacer ajustes manuales al inventario.",
                        footer: ""
                    });                    
                }
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
        },

        validaCantidadFloatRequerido: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) <= 0)
                    return "Debe ser mayor que cero";
                else if (parseFloat(value) >= 1000000 )
                    return "Cantidad demasiado grande";
            } else if (value != null && value == "")
                return "Campo requerido";
            return true;
        },
        validaCantidadEnteraRequerido: function(value) {
            const pattern = /^[\d]*$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseInt(value) <= 0)
                    return "Debe ser mayor que cero";
            } else if (value != null && value == "")
                return "Campo requerido";
            return true;
        },
        validaCantidadEntera: function(value) {
            const pattern = /^[\d]*$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            }
            return true;
        },

        validaCantidadFloat: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) <= 0)
                    return "Debe ser mayor que cero";
            }
            return true;
        },
        calculaPrecioProveedor: function(factura_descuento=null){
            if(this.articulo_editable.precio_sin_iva!=null && this.articulo_editable.precio_sin_iva!="" && parseFloat(this.articulo_editable.precio_sin_iva)>0){
                this.articulo_editable.precio_con_iva = (this.articulo_editable.precio_sin_iva * (1 + (parseFloat(this.articulo_editable.iva)/100))).toFixed(4);
                var descuento = 0;
                if(this.articulo_editable.porcentaje_descuento1!=null && this.articulo_editable.porcentaje_descuento1!="" && parseFloat(this.articulo_editable.porcentaje_descuento1)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento1)
                if(this.articulo_editable.porcentaje_descuento2!=null && this.articulo_editable.porcentaje_descuento2!="" && parseFloat(this.articulo_editable.porcentaje_descuento2)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    this.articulo_editable.precio_proveedor = (this.articulo_editable.precio_con_iva - (this.articulo_editable.precio_con_iva * (descuento/100))).toFixed(4);
                } else {
                    this.articulo_editable.precio_proveedor = this.articulo_editable.precio_con_iva;
                }
            } else {
                this.articulo_editable.precio_proveedor = "";
                this.articulo_editable.precio_con_iva = "";
            }
        },
        calculaPrecioProveedorFactura: function(factura_descuento=null){
            if(this.articulo_editable.precio_sin_iva!=null && this.articulo_editable.precio_sin_iva!="" && parseFloat(this.articulo_editable.precio_sin_iva)>0){
                this.articulo_editable.precio_con_iva = (this.articulo_editable.precio_sin_iva * (1 + (parseFloat(this.articulo_editable.iva)/100))).toFixed(4);
                var descuento = 0;
                if(this.articulo_editable.porcentaje_descuento1!=null && this.articulo_editable.porcentaje_descuento1!="" && parseFloat(this.articulo_editable.porcentaje_descuento1)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento1)
                if(this.articulo_editable.porcentaje_descuento2!=null && this.articulo_editable.porcentaje_descuento2!="" && parseFloat(this.articulo_editable.porcentaje_descuento2)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    this.articulo_editable.precio_factura = (this.articulo_editable.precio_con_iva - (this.articulo_editable.precio_con_iva * (descuento/100))).toFixed(4);
                } else {
                    this.articulo_editable.precio_factura = this.articulo_editable.precio_con_iva;
                }

                if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                    this.articulo_editable.precio_proveedor = this.articulo_editable.precio_factura;
                }

            } else {
                this.articulo_editable.precio_proveedor = "";
                this.articulo_editable.precio_con_iva = "";
            }
        },
        
        soloNumeros: function(value){ 
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;

            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (!pattern.test(value) ){
                value = value.substring(0,value.length-1);
                // Actualiza el valor del input en la vista
                input.value = value;
                this.soloNumeros(value);
            }     
            input.setSelectionRange(start, end);       
        },
        //Se usa en los input text
        calculaPrecioProveedorInput: function(row, referencia, factura_descuento=null){    
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;        

            if(row.precio_sin_iva!=null && row.precio_sin_iva!="" && parseFloat(row.precio_sin_iva)>0 && !isNaN(row.precio_sin_iva)){
                row.precio_con_iva = (row.precio_sin_iva * (1 + (parseFloat(row.iva)/100))).toFixed(4);
                var descuento = 0;
                if(row.porcentaje_descuento1!=null && row.porcentaje_descuento1!="" && parseFloat(row.porcentaje_descuento1)>0)
                    descuento += parseFloat(row.porcentaje_descuento1)
                if(row.porcentaje_descuento2!=null && row.porcentaje_descuento2!="" && parseFloat(row.porcentaje_descuento2)>0)
                    descuento += parseFloat(row.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    row.precio_proveedor = (row.precio_con_iva - (row.precio_con_iva * (descuento/100))).toFixed(4);
                } else {
                    row.precio_proveedor = row.precio_con_iva;
                }                
            } else if(row.precio_sin_iva==null || row.precio_sin_iva=="" || parseFloat(row.precio_sin_iva)==0) {
                row.precio_proveedor = "";
                row.precio_con_iva = "";
            }
            this.updateTablaArticulosOrden(row, referencia, start, end);
        },        
        updateTablaArticulosOrden: function(row, referencia, start, end){
            for(var k in this.model_vista.articulos){
                if(this.model_vista.articulos[k].id_articulo == row.id_articulo){
                    this.model_vista.articulos[k] = row;
                    break;
                }
            }
            this.keyTableArticulosOrden = moment(new Date()).format("x"); //Refrescar tabla
            // Restablece la posición del cursor
            this.$nextTick(() => {
                this.$refs[referencia].focus();
                this.$refs[referencia].scrollIntoView({ behavior: 'smooth' });
                this.$refs[referencia].setSelectionRange(start, end);
            });          
        },
        calculaPrecioProveedorFacturaInput: function(row, referencia, factura_descuento=null){    
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;        

            if(row.precio_sin_iva!=null && row.precio_sin_iva!="" && parseFloat(row.precio_sin_iva)>0 && !isNaN(row.precio_sin_iva)){
                row.precio_con_iva = (row.precio_sin_iva * (1 + (parseFloat(row.iva)/100))).toFixed(4);
                var descuento = 0;
                if(row.porcentaje_descuento1!=null && row.porcentaje_descuento1!="" && parseFloat(row.porcentaje_descuento1)>0)
                    descuento += parseFloat(row.porcentaje_descuento1)
                if(row.porcentaje_descuento2!=null && row.porcentaje_descuento2!="" && parseFloat(row.porcentaje_descuento2)>0)
                    descuento += parseFloat(row.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    row.precio_factura = (row.precio_con_iva - (row.precio_con_iva * (descuento/100))).toFixed(4);                    
                } else {
                    row.precio_factura = row.precio_con_iva;
                }                

                if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                    row.precio_proveedor = row.precio_factura;
                }

            } else if(row.precio_sin_iva==null || row.precio_sin_iva=="") {
                row.precio_factura = "";
                row.precio_con_iva = "";
            }
            this.updateTablaArticulosFactura(row, referencia, start, end);
        },        
        updateTablaArticulosFactura: function(row, referencia, start, end){
            for(var k in this.model_vista_factura.articulos){
                if(this.model_vista_factura.articulos[k].id_articulo == row.id_articulo){
                    this.model_vista_factura.articulos[k] = row;
                    break;
                }
            }
            this.keyTableArticulosFactura = moment(new Date()).format("x"); //Refrescar tabla
            // Restablece la posición del cursor
            this.$nextTick(() => {
                this.$refs[referencia].focus();
                this.$refs[referencia].scrollIntoView({ behavior: 'smooth' });
                this.$refs[referencia].setSelectionRange(start, end);
            });          
        },
        calculaPrecioSinIVA: function(factura_descuento=null){
            if(this.articulo_editable.precio_con_iva!=null && this.articulo_editable.precio_con_iva!="" && parseFloat(this.articulo_editable.precio_con_iva)>0){
                this.articulo_editable.precio_sin_iva = (this.articulo_editable.precio_con_iva / (1 + (parseFloat(this.articulo_editable.iva)/100))).toFixed(4);                
            } else {
                this.articulo_editable.precio_sin_iva = "";                
            }
            this.calculaPrecioProveedorDescuento(factura_descuento);
        },
        calculaPrecioSinIVAFactura: function(factura_descuento=null){
            if(this.articulo_editable.precio_con_iva!=null && this.articulo_editable.precio_con_iva!="" && parseFloat(this.articulo_editable.precio_con_iva)>0){
                this.articulo_editable.precio_sin_iva = (this.articulo_editable.precio_con_iva / (1 + (parseFloat(this.articulo_editable.iva)/100))).toFixed(4);                
            } else {
                this.articulo_editable.precio_sin_iva = "";                
            }
            this.calculaPrecioProveedorDescuentoFactura(factura_descuento);
        },
        actualizaArticuloOrdenInput: function(row, referencia){
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;            
            this.updateTablaArticulosOrden(row, referencia, start, end);
        },
        actualizaArticuloFacturaInput: function(row, referencia){
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;            
            this.updateTablaArticulosFactura(row, referencia, start, end);
        },
        calculaPrecioSinIVAInput: function(row, referencia, factura_descuento=null){
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;

            if(row.precio_con_iva!=null && row.precio_con_iva!="" && parseFloat(row.precio_con_iva)>0 && !isNaN(row.precio_con_iva)){
                row.precio_sin_iva = (row.precio_con_iva / (1 + (parseFloat(row.iva)/100))).toFixed(4);                

                var descuento = 0;
                if(row.porcentaje_descuento1!=null && row.porcentaje_descuento1!="" && parseFloat(row.porcentaje_descuento1)>0)
                    descuento += parseFloat(row.porcentaje_descuento1)
                if(row.porcentaje_descuento2!=null && row.porcentaje_descuento2!="" && parseFloat(row.porcentaje_descuento2)>0)
                    descuento += parseFloat(row.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    row.precio_proveedor = (parseFloat(row.precio_con_iva) - (parseFloat(row.precio_con_iva) * (descuento/100))).toFixed(4);
                } else {
                    row.precio_proveedor = parseFloat(row.precio_con_iva);
                }

            } else if(row.precio_con_iva==null || row.precio_con_iva=="" || parseFloat(row.precio_con_iva)==0) {
                row.precio_sin_iva = ""; 
                row.precio_proveedor = "";               
            }
            this.updateTablaArticulosOrden(row, referencia, start, end);
        },
        scheduleSave(funcion, row=null) {            
            // Cancelar cualquier temporizador existente
            try {
                clearTimeout(this.saveTimeout);   
            } catch (error) {
                
            }            
            // Programar la ejecución de la función saveCambios** después de medio segundo
            if (funcion=="saveCambiosOrden"){
                this.saveTimeout = setTimeout(() => {
                    this.saveCambiosOrden(row);
                }, 750);
            } else if (funcion=="saveCambiosFactura"){
                this.saveTimeout = setTimeout(() => {
                    this.saveCambiosFactura(row);
                }, 750);
            }
            
        },
        saveCambiosOrden: function (row=null) {
            try {

                if(row != null){
                    //Editar articulo del modelo con los cambios
                    for (var key in this.model_vista.articulos) {
                        if (this.model_vista.articulos[key].id_articulo == row.id_articulo) {
                            this.model_vista.articulos[key].cantidad = parseFloat(row.cantidad);                                

                            if (row.precio_compra != "" && parseFloat(row.precio_compra)>=0)
                                this.model_vista.articulos[key].precio_compra = parseFloat(row.precio_compra);
                            else
                                this.model_vista.articulos[key].precio_compra = 0;

                            if (row.iva != "" && parseFloat(row.iva)>=0)
                                this.model_vista.articulos[key].iva = parseFloat(row.iva);
                            else
                                this.model_vista.articulos[key].iva = 0;

                            if (row.precio_sin_iva != null && row.precio_sin_iva != "")
                                this.model_vista.articulos[key].precio_sin_iva = parseFloat(row.precio_sin_iva);
                            else
                                this.model_vista.articulos[key].precio_sin_iva = "";

                            if (row.precio_con_iva != null && row.precio_con_iva != "")
                                this.model_vista.articulos[key].precio_con_iva = parseFloat(row.precio_con_iva);
                            else
                                this.model_vista.articulos[key].precio_con_iva = "";

                            if (row.porcentaje_descuento1 != null && row.porcentaje_descuento1 != "")
                                this.model_vista.articulos[key].porcentaje_descuento1 = parseFloat(row.porcentaje_descuento1);
                            else
                                this.model_vista.articulos[key].porcentaje_descuento1 = "";

                            if (row.porcentaje_descuento2 != null && row.porcentaje_descuento2 != "")
                                this.model_vista.articulos[key].porcentaje_descuento2 = parseFloat(row.porcentaje_descuento2);
                            else
                                this.model_vista.articulos[key].porcentaje_descuento2 = "";

                            if (row.precio_proveedor != null && row.precio_proveedor != ""){
                                this.model_vista.articulos[key].precio_proveedor = parseFloat(row.precio_proveedor);
                                this.model_vista.articulos[key].precio_proveedor_sin_iva = parseFloat((row.precio_proveedor / (1 + (this.model_vista.articulos[key].iva/100))).toFixed(4));
                            }else{
                                this.model_vista.articulos[key].precio_proveedor = "";
                                this.model_vista.articulos[key].precio_proveedor_sin_iva = "";
                            }                            

                            if (row.observaciones_proveedor != null && row.observaciones_proveedor != "")
                                this.model_vista.articulos[key].observaciones_proveedor = row.observaciones_proveedor;
                            else
                                this.model_vista.articulos[key].observaciones_proveedor = "";
                        }
                    } 
                    //Actualizamos el precio proveedor de los articulos en las facturas, por en caso de que modifiquen el precio cuando ya habían agregado facturas
                    this.actualizar_precio_proveedor_facturas(this.model_vista);
                }
                

                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                    .then(response => {
                        this.model_vista._rev = response.data.rev;
                        this.registros.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    })
                    .catch(error => {
                        //Si ocurre un conflicto que vuelva a intentarlo
                        if ((error.response && error.response.data && error.response.data['error'] && error.response.data['error']=='conflict') || 
                            (error.response && error.response.status && error.response.status==409)) {

                            //Traemos el modelo actualizado
                            window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
                            .then(response => {
                                this.model_vista = response.data;
                                this.saveCambiosOrden(row);
                            }).catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el artículo. Intente nuevamente por favor.",
                                    footer: "saveCambiosOrden"
                                });
                            });
                                                       
                        } else {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                footer: "saveCambiosOrden"
                            });
                        }
                    }).then(() => {                            
                    });                

            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);                    
            }
        },
        calculaPrecioSinIVAFacturaInput: function(row, referencia, factura_descuento=null){
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;            

            if(row.precio_con_iva!=null && row.precio_con_iva!="" && parseFloat(row.precio_con_iva)>0 && !isNaN(row.precio_con_iva)){
                row.precio_sin_iva = (row.precio_con_iva / (1 + (parseFloat(row.iva)/100))).toFixed(4);                

                var descuento = 0;
                if(row.porcentaje_descuento1!=null && row.porcentaje_descuento1!="" && parseFloat(row.porcentaje_descuento1)>0)
                    descuento += parseFloat(row.porcentaje_descuento1)
                if(row.porcentaje_descuento2!=null && row.porcentaje_descuento2!="" && parseFloat(row.porcentaje_descuento2)>0)
                    descuento += parseFloat(row.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    row.precio_factura = (parseFloat(row.precio_con_iva) - (parseFloat(row.precio_con_iva) * (descuento/100))).toFixed(4);
                } else {
                    row.precio_factura = parseFloat(row.precio_con_iva);
                }

                if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                    row.precio_proveedor = row.precio_factura;
                }

            } else if(row.precio_con_iva==null || row.precio_con_iva=="" || parseFloat(row.precio_con_iva)==0) {
                row.precio_sin_iva = ""; 
                row.precio_proveedor = "";               
                row.precio_factura = "";
            }
            this.updateTablaArticulosFactura(row, referencia, start, end);
        },
        saveCambiosFactura: function (row=null) {
            try {

                if(row != null){
                    //Editar articulo del modelo con los cambios
                    for (var key in this.model_vista_factura.articulos) {
                        if (this.model_vista_factura.articulos[key].id_articulo == row.id_articulo) {
                            this.model_vista_factura.articulos[key].cantidad = parseFloat(row.cantidad); 
                            this.model_vista_factura.articulos[key].cantidad_faltante = parseFloat(row.cantidad_faltante);
                            this.model_vista_factura.articulos[key].cantidad_devuelta = parseFloat(row.cantidad_devuelta);

                            if (row.precio_compra != "" && parseFloat(row.precio_compra)>=0)
                                this.model_vista_factura.articulos[key].precio_compra = parseFloat(row.precio_compra);
                            else
                                this.model_vista_factura.articulos[key].precio_compra = 0;

                            if (row.iva != "" && parseFloat(row.iva)>=0)
                                this.model_vista_factura.articulos[key].iva = parseFloat(row.iva);
                            else
                                this.model_vista_factura.articulos[key].iva = 0;

                            if (row.precio_sin_iva != null && row.precio_sin_iva != "")
                                this.model_vista_factura.articulos[key].precio_sin_iva = parseFloat(row.precio_sin_iva);
                            else
                                this.model_vista_factura.articulos[key].precio_sin_iva = "";

                            if (row.precio_con_iva != null && row.precio_con_iva != "")
                                this.model_vista_factura.articulos[key].precio_con_iva = parseFloat(row.precio_con_iva);
                            else
                                this.model_vista_factura.articulos[key].precio_con_iva = "";

                            if (row.porcentaje_descuento1 != null && row.porcentaje_descuento1 != "")
                                this.model_vista_factura.articulos[key].porcentaje_descuento1 = parseFloat(row.porcentaje_descuento1);
                            else
                                this.model_vista_factura.articulos[key].porcentaje_descuento1 = "";

                            if (row.porcentaje_descuento2 != null && row.porcentaje_descuento2 != "")
                                this.model_vista_factura.articulos[key].porcentaje_descuento2 = parseFloat(row.porcentaje_descuento2);
                            else
                                this.model_vista_factura.articulos[key].porcentaje_descuento2 = "";

                            if (row.precio_factura != null && row.precio_factura != ""){
                                this.model_vista_factura.articulos[key].precio_factura = parseFloat(row.precio_factura);
                                this.model_vista_factura.articulos[key].precio_factura_sin_iva = parseFloat((row.precio_factura / (1 + (this.model_vista_factura.articulos[key].iva/100))).toFixed(4));
                            }else{
                                this.model_vista_factura.articulos[key].precio_factura = "";
                                this.model_vista_factura.articulos[key].precio_factura_sin_iva = "";
                            }

                            if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                                this.model_vista_factura.articulos[key].precio_proveedor = this.model_vista_factura.articulos[key].precio_factura;
                                this.model_vista_factura.articulos[key].precio_proveedor_sin_iva = this.model_vista_factura.articulos[key].precio_factura_sin_iva;
                            }

                            if (row.observaciones_proveedor != null && row.observaciones_proveedor != "")
                                this.model_vista_factura.articulos[key].observaciones_proveedor = row.observaciones_proveedor;
                            else
                                this.model_vista_factura.articulos[key].observaciones_proveedor = "";
                        }
                    } 
                }                

                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
                    .then(response => {
                        this.model_vista_factura._rev = response.data.rev;
                        this.reloadFacturas(this.model_vista);
                    })
                    .catch(error => {
                        //Si ocurre un conflicto que vuelva a intentarlo
                        if ((error.response && error.response.data && error.response.data['error'] && error.response.data['error']=='conflict') || 
                            (error.response && error.response.status && error.response.status==409)) {

                            //Traemos el modelo actualizado
                            window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista_factura._id)
                            .then(response => {
                                this.model_vista_factura = response.data;
                                this.saveCambiosFactura(row);
                            }).catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el artículo. Intente nuevamente por favor.",
                                    footer: "saveCambiosFactura"
                                });
                            });
                                                       
                        } else {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                footer: "saveCambiosFactura"
                            });
                        }                        
                    }).then(() => {                            
                    });                

            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);                    
            }
        },
        calculaPrecioProveedorDescuento: function(factura_descuento=null){
            if(this.articulo_editable.precio_con_iva!=null && this.articulo_editable.precio_con_iva!="" && parseFloat(this.articulo_editable.precio_con_iva)>0){
                
                var descuento = 0;
                if(this.articulo_editable.porcentaje_descuento1!=null && this.articulo_editable.porcentaje_descuento1!="" && parseFloat(this.articulo_editable.porcentaje_descuento1)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento1)
                if(this.articulo_editable.porcentaje_descuento2!=null && this.articulo_editable.porcentaje_descuento2!="" && parseFloat(this.articulo_editable.porcentaje_descuento2)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    this.articulo_editable.precio_proveedor = (parseFloat(this.articulo_editable.precio_con_iva) - (parseFloat(this.articulo_editable.precio_con_iva) * (descuento/100))).toFixed(4);
                } else {
                    this.articulo_editable.precio_proveedor = parseFloat(this.articulo_editable.precio_con_iva);
                }
            } else {
                this.articulo_editable.precio_proveedor = "";
            }
        },
        calculaPrecioProveedorDescuentoFactura: function(factura_descuento=null){
            if(this.articulo_editable.precio_con_iva!=null && this.articulo_editable.precio_con_iva!="" && parseFloat(this.articulo_editable.precio_con_iva)>0){
                
                var descuento = 0;
                if(this.articulo_editable.porcentaje_descuento1!=null && this.articulo_editable.porcentaje_descuento1!="" && parseFloat(this.articulo_editable.porcentaje_descuento1)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento1)
                if(this.articulo_editable.porcentaje_descuento2!=null && this.articulo_editable.porcentaje_descuento2!="" && parseFloat(this.articulo_editable.porcentaje_descuento2)>0)
                    descuento += parseFloat(this.articulo_editable.porcentaje_descuento2)

                if(factura_descuento!=null && factura_descuento!="" && parseFloat(factura_descuento)>0)
                    descuento += parseFloat(factura_descuento)

                if(!isNaN(descuento)){
                    this.articulo_editable.precio_factura = (parseFloat(this.articulo_editable.precio_con_iva) - (parseFloat(this.articulo_editable.precio_con_iva) * (descuento/100))).toFixed(4);
                } else {
                    this.articulo_editable.precio_factura = parseFloat(this.articulo_editable.precio_con_iva);
                }

                if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                    this.articulo_editable.precio_proveedor = this.articulo_editable.precio_factura;
                }

            } else {
                this.articulo_editable.precio_proveedor = "";
            }
        },
        validaCantidadFloatCero: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) <= 0)
                    return true;
            }
            return true;
        },
        ValidaPorcentajeMaximo: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) <= 0){
                    return true;
                }else if (parseFloat(value) > 100){
                    return "No puede ser mayor que 100";
                }
            }
            return true;
        },
        remover_articulo: function(row) {

            if(this.model_vista_factura.estatus == "Orden Finalizada" && !this.verificaPermiso('t.compras.editar_factura')){
                this.$swal({
                    icon: "warning",
                    title: "Operación No Permitida!",
                    text: "La factura ya ha sido recibida en almacén. Para modificarla necesita permisos especiales. Comuníquese con el Administrador.",                    
                    footer: ""
                });
            
            }else if (this.model_vista_factura.estatus == "Orden Finalizada" && this.verificaPermiso('t.compras.editar_factura')){
                this.$swal({
                        icon: "info",
                        title: "Cuidado",
                        text: "La factura ya ha sido recibida en almacén. Si modifica la información tendrá que hacer ajustes manuales al inventario ¿Desea remover el artículo?",
                        footer: "",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Si",
                        cancelButtonText: "No"
                    }).then(resultado => {
                        if (resultado.value) {
                            this.remover_articulo_funcion(row);
                        }
                    });
            }else {
                this.remover_articulo_funcion(row);
            }

        },
        remover_articulo_funcion: function(row) {
           
            window.dialogLoader.show('Espere un momento por favor..');

            this.model_vista_factura.articulos = this.model_vista_factura.articulos.filter(x => x.id_articulo != row.id_articulo);

            //Traemos el model_vista actualizado
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
            .then(response => {
                this.model_vista = response.data;
                
                    window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)                        
                    .then(response => {
                        this.model_vista_factura._rev = response.data.rev;
                        window.dialogLoader.showSnackbar('El articulo se eliminó correctamente..', {
                            color: 'success'
                        });                                
                        this.update_arreglo_facturas();
                    }).catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                            footer: ""
                        });
                    }).then(() => {
                        this.update_colors_lista_izquierda();
                        window.dialogLoader.hide();                        
                    });
                
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
                window.dialogLoader.hide();
            });        
        },

            valida_seleccionar: function(row){
                if(this.model_vista_factura.estatus == "Orden Finalizada" && !this.verificaPermiso('t.compras.editar_factura')){
                    this.$swal({
                        icon: "warning",
                        title: "Operación No Permitida!",
                        text: "La factura ya ha sido recibida en almacén. Para modificarla necesita permisos especiales. Comuníquese con el Administrador.",                    
                        footer: ""
                    });
                
                }else if (this.model_vista_factura.estatus == "Orden Finalizada" && this.verificaPermiso('t.compras.editar_factura')){
                    this.$swal({
                            icon: "info",
                            title: "Cuidado",
                            text: "La factura ya ha sido recibida en almacén. Si modifica la información tendrá que hacer ajustes manuales al inventario ¿Desea agregar el artículo?",
                            footer: "",
                            showCancelButton: true,
                            cancelButtonColor: "#d33",
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Si",
                            cancelButtonText: "No"
                        }).then(resultado => {
                            if (resultado.value) {
                                this.valida_seleccionar_funcion(row);
                            }
                        });
                }else {
                    this.valida_seleccionar_funcion(row);
                }
            },
            valida_seleccionar_funcion: function(row){
                //validar que haya ingresado factura_folio, factura_folio_sat, y factura_fecha
                if(this.model_vista_factura.factura_folio && this.model_vista_factura.factura_folio!="" && this.model_vista_factura.factura_folio_sat 
                    && this.model_vista_factura.factura_folio_sat!="" && this.model_vista_factura.factura_fecha && this.model_vista_factura.factura_fecha!=""){
                    var copy_row = JSON.parse(JSON.stringify(row));
                    copy_row["precio_factura"] = ""; //Cuando es por pedido(no directa), el precio_proveedor es el del pedido, no el de la factura, por eso se guarda aparte
                    copy_row["precio_factura_sin_iva"] = "";

                    this.articulo_seleccionado = copy_row;    
                    this.id_articulo_calculo = this.articulo_seleccionado.id_articulo;
                    this.get_existencia();
                    this.modal_agregar_articulo = true;
                    setTimeout(() => {
                        this.$nextTick(() => this.$refs.ingresacantidad.focus());
                    }, 100);
                } else {
                    this.$swal({
                        type: "warning",
                        title: "¡Cuidado!",
                        text: "Primero debe ingresar los folios y la fecha de la factura.",
                        footer: ""
                    });
                } 
            },
            
            //Cuando es directa no se tienen los campos del articulo necesarios y tienen que agregarse
            valida_seleccionar_directa: async function(row){
                var self = this;
                if(this.model_vista_factura.estatus == "Orden Finalizada" && !this.verificaPermiso('t.compras.editar_factura')){
                    this.$swal({
                        icon: "warning",
                        title: "Operación No Permitida!",
                        text: "La factura ya ha sido recibida en almacén. Para modificarla necesita permisos especiales. Comuníquese con el Administrador.",                    
                        footer: ""
                    });
                
                }else if (this.model_vista_factura.estatus == "Orden Finalizada" && this.verificaPermiso('t.compras.editar_factura')){
                    this.$swal({
                            icon: "info",
                            title: "Cuidado",
                            text: "La factura ya ha sido recibida en almacén. Si modifica la información tendrá que hacer ajustes manuales al inventario ¿Desea agregar el artículo?",
                            footer: "",
                            showCancelButton: true,
                            cancelButtonColor: "#d33",
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Si",
                            cancelButtonText: "No"
                        }).then(resultado => {
                            if (resultado.value) {
                                this.valida_seleccionar_directa_funcion(row);
                            }
                        });
                }else {
                    this.valida_seleccionar_directa_funcion(row);
                }
                          
            },  
            valida_seleccionar_directa_funcion: async function(row){
                var self = this;
                //validar que haya ingresado factura_folio, factura_folio_sat, y factura_fecha
                if(this.model_vista_factura.factura_folio && this.model_vista_factura.factura_folio!="" && this.model_vista_factura.factura_folio_sat 
                    && this.model_vista_factura.factura_folio_sat!="" && this.model_vista_factura.factura_fecha && this.model_vista_factura.factura_fecha!=""){
                    
                    var articulo = JSON.parse(JSON.stringify(row));
                    //print("Articulo: ", articulo);

                    await window.funciones_lotes.consultaExistenciaTotal({
                        "id_articulo": articulo._id
                    })
                    .then(async responseExistencia => {
                        var copy_row = {
                            id_articulo: articulo._id,
                            nombre_articulo: articulo.nombre,
                            descripcion: articulo.descripcion,
                            codigo_articulo: articulo.codigo_barras,
                            impuestos_articulo: articulo.impuestos,
                            cantidad: 1,
                            precio_compra: parseFloat(articulo.precio_compra), //Último precio de compra
                            precio_compra_con_iva: parseFloat(articulo.precio_compra_con_iva), //Último precio de compra con iva
                            precio_sin_iva:"",
                            iva: self.getSumaImpuestos(articulo.impuestos), //Ahora guarda la suma de impuestos del articulo
                            precio_con_iva:"",
                            porcentaje_descuento1:"",
                            porcentaje_descuento2:"",
                            precio_factura:"", //Cuando es directa, el precio_factura se debe copiar al precio_proveedor
                            precio_factura_sin_iva:"",
                            precio_proveedor: "",
                            precio_proveedor_sin_iva: "",
                            existencia_articulo: parseFloat(parseFloat(responseExistencia).toFixed(2)),
                            observaciones_proveedor: "",
                            estatus: "Pendiente",
                            cantidad_recibida: 0,
                            cantidad_faltante: 0,
                            cantidad_devuelta: 0,
                            lotes_destino: [],
                            resaltado: 0,
                            sucursal_recibio: "",
                            usuario_recibio: "",
                            fecha_recibio: "",
                        };
                        this.articulo_seleccionado = copy_row;  
                        this.id_articulo_calculo = this.articulo_seleccionado.id_articulo;
                        this.get_existencia();
                        this.modal_agregar_articulo = true;
                        setTimeout(() => {
                            this.$nextTick(() => this.$refs.ingresacantidad.focus());
                        }, 100);
                    }).catch(err => {
                        console.log("Error ", err);
                        self.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                            footer: ""
                        });
                    });
                    
                } else {
                    this.$swal({
                        type: "warning",
                        title: "¡Cuidado!",
                        text: "Primero debe ingresar los folios y la fecha de la factura.",
                        footer: ""
                    });
                }  
            },          
            seleccionar_articulo: async function(row) {
                if (this.$refs.formcantidad.validate()) {

                    window.dialogLoader.show('Espere un momento por favor..');
                    //console.log("SELCCIONADO: ", this.articulo_seleccionado);

                    if(this.model_vista_factura.plazo_pago != null && this.model_vista_factura.plazo_pago != "" && parseInt(this.model_vista_factura.plazo_pago)>=0){
                        this.model_vista_factura.plazo_pago = parseInt(this.model_vista_factura.plazo_pago);
                    }
                    if(this.model_vista_factura.factura_fecha != null && this.model_vista_factura.factura_fecha != ""){
                        this.model_vista_factura.factura_fecha = window.moment(this.model_vista_factura.factura_fecha).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    if(this.model_vista_factura.fecha_recibio_mercancia != null && this.model_vista_factura.fecha_recibio_mercancia != ""){
                        this.model_vista_factura.fecha_recibio_mercancia = window.moment(this.model_vista_factura.fecha_recibio_mercancia).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    if(this.model_vista_factura.factura_fecha_vencimiento != null && this.model_vista_factura.factura_fecha_vencimiento != ""){
                        this.model_vista_factura.factura_fecha_vencimiento = window.moment(this.model_vista_factura.factura_fecha_vencimiento).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    if(this.model_vista_factura.factura_descuento!=null && this.model_vista_factura.factura_descuento!="" && parseFloat(this.model_vista_factura.factura_descuento)>0 ){
                        this.setDescuento();
                        this.model_vista_factura.factura_descuento = parseFloat(this.model_vista_factura.factura_descuento);
                        this.model_vista_factura.factura_descuento_total = parseFloat(this.model_vista_factura.factura_descuento_total);
                    } else {
                        this.model_vista_factura.factura_descuento = 0;
                        this.model_vista_factura.factura_descuento_total = 0;
                    }

                    this.articulo_seleccionado.precio_factura = parseFloat(this.articulo_seleccionado.precio_factura);
                    this.articulo_seleccionado.precio_factura_sin_iva = parseFloat((this.articulo_seleccionado.precio_factura / (1 + (this.articulo_seleccionado.iva/100))).toFixed(4));
                    
                    //Alvaro solicitó que si es directa el precio de factura se pasa al precio_proveedor, sino el precio proveedor es el del pedido                    
                    if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                        this.articulo_seleccionado.precio_proveedor = this.articulo_seleccionado.precio_factura;
                        this.articulo_seleccionado.precio_proveedor_sin_iva = this.articulo_seleccionado.precio_factura_sin_iva;
                    }
                                        
                    this.articulo_seleccionado.precio_con_iva = this.articulo_seleccionado.precio_factura;
                    this.articulo_seleccionado.precio_sin_iva = this.articulo_seleccionado.precio_factura_sin_iva;
                    
                    let existe = this.model_vista_factura.articulos.filter(x => x.id_articulo == this.articulo_seleccionado.id_articulo);

                    if(existe.length > 0) {//Si existe solo se reemplaza
                        for(var k in this.model_vista_factura.articulos){
                            if(this.model_vista_factura.articulos[k].id_articulo == this.articulo_seleccionado.id_articulo){
                                this.model_vista_factura.articulos[k] = this.articulo_seleccionado;
                                break;
                            }
                        }
                    } else {//Si no existe se agrega                        
                        this.model_vista_factura.articulos.push(this.articulo_seleccionado);                        
                    }

                    //Traemos el mode_vista actualizado
                    window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
                    .then(response => {
                        this.model_vista = response.data;                        
                        //Si es modelo nuevo se guarda, sino se updatea
                        if(this.model_vista_factura._id && this.model_vista_factura._id!=undefined && this.model_vista_factura._id!="undefined" && this.model_vista_factura._rev){
                            window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)                        
                            .then(response => {
                                this.model_vista_factura._rev = response.data.rev;
                                window.dialogLoader.showSnackbar('El articulo se agregó correctamente..', {
                                    color: 'success'
                                });                                
                                this.update_arreglo_facturas();
                            }).catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al agregar el artículo. Intente nuevamente.",
                                    footer: ""
                                });
                            }).then(() => {
                                window.dialogLoader.hide();
                                this.modal_agregar_articulo = false;
                            });
                        } else {                            
                            //calcular no_orden con secuencia de suborden
                            var model_copy = JSON.parse(JSON.stringify(this.model_vista));
                            var secuencia_factura = this.get_numero_suborden(model_copy);
                            this.model_vista_factura.secuencia = secuencia_factura;
                            this.model_vista_factura.no_orden = model_copy.no_orden +"S"+ secuencia_factura.toString();
                            window.axios
                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model_vista_factura)
                            .then(response => {
                                this.model_vista_factura["_rev"] = response.data.rev;
                                this.model_vista_factura["_id"] = response.data.id;
                                window.dialogLoader.showSnackbar('El articulo se agregó correctamente..', {
                                    color: 'success'
                                });
                                this.update_arreglo_facturas();
                            }).catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al agregar el artículo. Intente nuevamente.",
                                    footer: ""
                                });
                            }).then(() => {
                                window.dialogLoader.hide();
                                this.modal_agregar_articulo = false;
                            });
                        } 
                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                            footer: ""
                        });
                        window.dialogLoader.hide();
                    }).then(()=>{
                        this.update_colors_lista_izquierda();
                    });

                }
                
            },
        
        update_colors_lista_izquierda: function(){
            //Ponemos en verde el articulo de la lista izquierda para saber que ya esta agregado a la factura
            var articulos_factura = new Set(this.model_vista_factura.articulos.map(item => item.id_articulo));
            articulos_factura = Array.from(articulos_factura.values());
            //console.log("articulos_factura: ", articulos_factura);

            if(this.model_vista.orden_creada=="directa"){ //Si es directa se actualizan articulos del proveedor
                for(var k in this.articulos_proveedor_seleccionado_directo){
                    if(articulos_factura.includes(this.articulos_proveedor_seleccionado_directo[k]._id)){
                        this.articulos_proveedor_seleccionado_directo[k]["agregado_a_factura"] = 1;
                    } else {
                        this.articulos_proveedor_seleccionado_directo[k]["agregado_a_factura"] = 0;
                    }
                }
                this.keyTableListaIzquierdaDirecta = moment(new Date()).format("x"); //Refrescar tabla
            } else { //Si es normal, se actualizan articulos del pedido
                for(var k in this.model_vista.articulos){
                    //(01-08-2024): Martín solicitó que tambien se muestre seleccionado los articulos que ya estén en otra factura previamente agregada
                    //Es decir, que si se agrega una segunda factura, se muestren en verde los articulos que ya se agregaron a la primera, siempre y cuando este el total del articulo
                    //Por ejemplo, si en el pedido se solicitó cantidad 20, y en la primer factura solo se recibieron 10, entonces no lo marque en verde de lo contrario si
                    if(articulos_factura.includes(this.model_vista.articulos[k].id_articulo) || this.articulos_facturas_previas.includes(this.model_vista.articulos[k].id_articulo)){
                        this.model_vista.articulos[k]["agregado_a_factura"] = 1;
                    } else {
                        this.model_vista.articulos[k]["agregado_a_factura"] = 0;
                    }
                }
                this.keyTableListaIzquierda = moment(new Date()).format("x"); //Refrescar tabla
            }            
        },

        eliminarFactura: function(row){
            //eliminar factura "compras" y quitarla del arreglo del pedido origen "pedidos"
            this.$swal({
                type: "info",
                title: "Cuidado",
                text: "Se eliminará la factura: "+row.no_orden+" y toda su información. ¿Desea continuar?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(resultado => {
                if (resultado.value) {
                    window.dialogLoader.show('Espere un momento por favor..');
                    window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
                    .then(response => {
                        this.model_vista = response.data;                            
                        
                            window.axios
                            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id + "?rev=" + row._rev)
                            .then(response => {
                                window.dialogLoader.showSnackbar('La factura se eliminó correctamente..', {
                                    color: 'success'
                                });                                
                                this.update_arreglo_facturas(row._id);
                            }).catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al eliminar factura. Intente nuevamente.",
                                    footer: ""
                                });
                            }).then(() => {
                                window.dialogLoader.hide();                                
                            });
                        
                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                            footer: ""
                        });
                        window.dialogLoader.hide();
                    });
                }
            });
                       
        },
        update_arreglo_facturas: function(id_elimina=""){
            //si la factura es nueva o ya existía, si existía actualizar arreglo, sino solo agregar
            
            if(id_elimina=="" || id_elimina=="actualizar"){
                if(this.model_vista.facturas && this.model_vista.facturas.length){
                    var encontrado = 0;
                    for(var k in this.model_vista.facturas){
                        if(this.model_vista_factura._id == this.model_vista.facturas[k].id){
                            encontrado = 1;
                            this.model_vista.facturas[k].no_orden = this.model_vista_factura.no_orden;
                            this.model_vista.facturas[k].secuencia = this.model_vista_factura.secuencia;
                            this.model_vista.facturas[k].factura_folio = this.model_vista_factura.factura_folio;
                            this.model_vista.facturas[k].factura_folio_sat = this.model_vista_factura.factura_folio_sat;
                            break;
                        }
                    }
                    if(encontrado == 0 && this.model_vista_factura._id && this.model_vista_factura._id!=undefined && this.model_vista_factura._id!="undefined"){
                        var item_factura = {
                            "id": this.model_vista_factura._id, 
                            "no_orden":this.model_vista_factura.no_orden, 
                            "factura_folio":this.model_vista_factura.factura_folio, 
                            "factura_folio_sat":this.model_vista_factura.factura_folio_sat, 
                            "secuencia":this.model_vista_factura.secuencia
                        };
                        this.model_vista["facturas"].push(item_factura);
                    }
                } else {
                    if(this.model_vista_factura._id && this.model_vista_factura._id!=undefined && this.model_vista_factura._id!="undefined"){
                        var item_factura = {
                            "id": this.model_vista_factura._id, 
                            "no_orden":this.model_vista_factura.no_orden, 
                            "factura_folio":this.model_vista_factura.factura_folio, 
                            "factura_folio_sat":this.model_vista_factura.factura_folio_sat, 
                            "secuencia":this.model_vista_factura.secuencia
                        };
                        this.model_vista["facturas"] = [item_factura];
                    }                    
                }
            } else {
                this.model_vista.facturas = this.model_vista.facturas.filter(x => x.id != id_elimina);
            }
            
            
            window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)                        
            .then(response => {
                this.model_vista._rev = response.data.rev;                
            }).catch(error => {
                this.update_arreglo_facturas(id_elimina);
            }).then(() => {
                //this.modalVistaFacturas = false;
                if(id_elimina=="") //Si no es de eliminar, es que agrego/removio articulo, se actualiza la vista
                    this.showFacturas(this.model_vista, this.model_vista_factura);
                else 
                    this.showFacturas(this.model_vista);
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            });            
        },
        openModal: function (type) {
            this.getComprasPeriodo();
            if (type === 1) {
                this.update = false;

            } else {
                this.update = true;
            }
            this.modal_opcion = 1; //1-Articulos por proveedor, 2-Sugeridos
            this.modalBusqueda = true;
            this.active_tab = "busqueda";
            /*setTimeout(() => {
                this.$nextTick(() => this.$refs.selectbusqueda.focus());
            }, 100);*/
        },

        openModalSugeridos: function (type) {
            this.id_proveedor = null;
            this.modal_opcion = 2; //1-Articulos por proveedor, 2-Sugeridos
            this.modalBusqueda = true;
            this.active_tab = "busqueda";
        },
        
        openModalDirecta: function (type) {
            this.id_proveedor = null;
            this.modalProveedor = true;
        },

        valida_fecha: function(fecha) {
            if (fecha == "Invalid date")
                return "Fecha no válida."
            else
                return true;
        },
        agregarArticuloEditable: function(){

            var model = {
                id_articulo: "articulo_nuevo",
                nombre_articulo: "",
                descripcion: "",
                codigo_articulo: [],
                impuestos_articulo: [],
                cantidad: 1, //1 por default
                precio_compra: "", //Último precio de compra
                precio_compra_con_iva: "", //Último precio de compra con iva
                precio_sin_iva:"",
                iva:"", //Ahora iva guarda la suma de impuestos del articulo
                precio_con_iva:"",
                porcentaje_descuento1:"",
                porcentaje_descuento2:"",
                precio_proveedor: "",
                precio_proveedor_sin_iva: "",
                existencia_articulo: 0,
                observaciones_proveedor: "",
                estatus: "Pendiente",
                cantidad_recibida: 0,
                cantidad_faltante: 0,
                cantidad_devuelta: 0,
                lotes_destino: [],
                resaltado: 0,
                sucursal_recibio: "",
                usuario_recibio: "",
                fecha_recibio: "",
            };

            this.articulo_editable = JSON.parse(JSON.stringify(model));
            this.id_articulo_editable = "";
            this.modalEditable = true;
        },
        getArticuloEditable: function(){
            var self = this;
            if (this.id_articulo_editable != null && this.id_articulo_editable != "" && this.id_articulo_editable != this.articulo_editable.id_articulo) {
                //Ver si el nuevo artículo ya existe en el modelo, si existe mostrar mensaje y no actualizar, 
                //si no existe hacer el cambio al nuevo articulo
                
                var find_articulo = this.model_vista.articulos.filter(e => {
                    return e.id_articulo == this.id_articulo_editable;
                });
                if (find_articulo.length > 0) {                                     
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "El artículo ya existe en la orden. Favor de verificar.",
                        footer: ""
                    }).then(()=>{
                        this.id_articulo_editable = this.articulo_editable.id_articulo;                                                
                    });
                    
                } else {
                    var mensaje = "¿Al cambiar el artículo se aplicará el cambio a la orden. Desea continuar?";
                    var pie = "Tenga en cuenta que si el producto ya se recibió en almacén, tendrá que hacer el ajuste manualmente.";
                    if(this.articulo_editable.id_articulo == "articulo_nuevo"){
                        mensaje = "Se agregará el artículo a la orden. Desea continuar?";
                        pie = "Tenga en cuenta que si ya se recibió la orden en almacén, tendrá que cambiar el estatus a En Proceso de Entrada para darle entrada al nuevo artículo";
                    }
                    this.$swal({
                        type: "info",
                        title: "Cuidado",
                        text: mensaje,
                        footer: pie,
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Si",
                        cancelButtonText: "No"
                    }).then(resultado => {
                        if (resultado.value) {
                            window.dialogLoader.show('Espere un momento por favor..');
                            window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.id_articulo_editable)
                            .then(async response => {
                            
                                var res = response.data;

                                //Quitar el articulo anterior y agregar el nuevo al arreglo de articulos
                                var new_articulos = this.model_vista.articulos.filter(e => {
                                    return e.id_articulo != this.articulo_editable.id_articulo;
                                });

                                let existencia = 0;                        
                                await window.funciones_lotes.consultaExistencia({
                                    "id_articulo": this.id_articulo_editable
                                }).then(result => {
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            existencia += el.existencia;                                    
                                        });                                                                
                                    } 
                                    //console.log("se asigno la existencia");

                                }).catch(err => {
                                    console.log("error", err);                            
                                });

                                this.articulo_editable.id_articulo = res._id;
                                this.articulo_editable.nombre_articulo = res.nombre;
                                this.articulo_editable.descripcion = res.descripcion;
                                this.articulo_editable.codigo_articulo = res.codigo_barras;
                                this.articulo_editable.impuestos_articulo = res.impuestos;
                                this.articulo_editable.iva = this.getSumaImpuestos(res.impuestos), //Ahora guarda la suma de impuestos del articulo
                                this.articulo_editable.existencia_articulo = parseFloat(parseFloat(existencia).toFixed(2));
                                this.articulo_editable.precio_compra = parseFloat(res.precio_compra);
                                this.articulo_editable.precio_compra_con_iva = parseFloat(res.precio_compra_con_iva);

                                new_articulos.push(this.articulo_editable);
                                this.model_vista.articulos = new_articulos;
                                this.saveNuevaCantidadArticulo(true); //true es para saber si se cierra o no la modal de editar

                            })
                            .catch(error => {
                                window.dialogLoader.hide();
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al obtener el artículo. Intente nuevamente.",
                                    footer: ""
                                });
                            });
                        } else {
                            this.id_articulo_editable = this.articulo_editable.id_articulo;
                        }
                    });
                    
                }

                
            }
        },

        editArticulo: function (articulo) {
            this.articulo_editable = JSON.parse(JSON.stringify(articulo));
            this.id_articulo_editable = this.articulo_editable.id_articulo;            
            this.modalEditable = true;
        },
        
        saveNuevaCantidadArticulo: function (modal_abierta=false) {
            if (this.$refs.formeditable.validate()) {

                try {

                    var descartado = this.model_vista.articulos.filter(e => {
                        return e.id_articulo == this.articulo_editable.id_articulo;
                    });
                    if (descartado.length > 0) {
                        window.dialogLoader.show('Espere un momento por favor..');

                        //Editar articulo del modelo con los cambios
                        for (var key in this.model_vista.articulos) {
                            if (this.model_vista.articulos[key].id_articulo == this.articulo_editable.id_articulo) {
                                this.model_vista.articulos[key].cantidad = parseFloat(this.articulo_editable.cantidad);                                

                                if (this.articulo_editable.precio_compra != "" && parseFloat(this.articulo_editable.precio_compra)>=0)
                                    this.model_vista.articulos[key].precio_compra = parseFloat(this.articulo_editable.precio_compra);
                                else
                                    this.model_vista.articulos[key].precio_compra = 0;

                                if (this.articulo_editable.iva != "" && parseFloat(this.articulo_editable.iva)>=0)
                                    this.model_vista.articulos[key].iva = parseFloat(this.articulo_editable.iva);
                                else
                                    this.model_vista.articulos[key].iva = 0;

                                if (this.articulo_editable.precio_sin_iva != null && this.articulo_editable.precio_sin_iva != "")
                                    this.model_vista.articulos[key].precio_sin_iva = parseFloat(this.articulo_editable.precio_sin_iva);
                                else
                                    this.model_vista.articulos[key].precio_sin_iva = "";

                                if (this.articulo_editable.precio_con_iva != null && this.articulo_editable.precio_con_iva != "")
                                    this.model_vista.articulos[key].precio_con_iva = parseFloat(this.articulo_editable.precio_con_iva);
                                else
                                    this.model_vista.articulos[key].precio_con_iva = "";

                                if (this.articulo_editable.porcentaje_descuento1 != null && this.articulo_editable.porcentaje_descuento1 != "")
                                    this.model_vista.articulos[key].porcentaje_descuento1 = parseFloat(this.articulo_editable.porcentaje_descuento1);
                                else
                                    this.model_vista.articulos[key].porcentaje_descuento1 = "";

                                if (this.articulo_editable.porcentaje_descuento2 != null && this.articulo_editable.porcentaje_descuento2 != "")
                                    this.model_vista.articulos[key].porcentaje_descuento2 = parseFloat(this.articulo_editable.porcentaje_descuento2);
                                else
                                    this.model_vista.articulos[key].porcentaje_descuento2 = "";

                                if (this.articulo_editable.precio_proveedor != null && this.articulo_editable.precio_proveedor != ""){
                                    this.model_vista.articulos[key].precio_proveedor = parseFloat(this.articulo_editable.precio_proveedor);
                                    this.model_vista.articulos[key].precio_proveedor_sin_iva = parseFloat((this.articulo_editable.precio_proveedor / (1 + (this.model_vista.articulos[key].iva/100))).toFixed(4));
                                }else{
                                    this.model_vista.articulos[key].precio_proveedor = "";
                                    this.model_vista.articulos[key].precio_proveedor_sin_iva = "";
                                }

                                if (this.articulo_editable.observaciones_proveedor != null && this.articulo_editable.observaciones_proveedor != "")
                                    this.model_vista.articulos[key].observaciones_proveedor = this.articulo_editable.observaciones_proveedor;
                                else
                                    this.model_vista.articulos[key].observaciones_proveedor = "";
                            }
                        }

                        window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                            .then(response => {
                                this.model_vista._rev = response.data.rev;
                                this.registros.items = [];
                                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            })
                            .catch(error => {
                                //Si ocurre un conflicto que vuelva a intentarlo
                                if ((error.response && error.response.data && error.response.data['error'] && error.response.data['error']=='conflict') || 
                                    (error.response && error.response.status && error.response.status==409)) {

                                    //Traemos el modelo actualizado
                                    window.axios
                                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
                                    .then(response => {
                                        this.model_vista = response.data;
                                        this.saveNuevaCantidadArticulo(modal_abierta);
                                    }).catch(error => {
                                        this.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al editar el artículo. Intente nuevamente por favor.",
                                            footer: "saveNuevaCantidadArticulo"
                                        });
                                    });
                                } else {
                                    this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                        footer: "saveNuevaCantidadArticulo"
                                    });
                                }
                            }).then(() => {
                                this.modalEditable = modal_abierta;
                                window.dialogLoader.hide();
                            });

                    }

                } catch (error) {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                        footer: ""
                    });
                    console.log(error);
                    window.dialogLoader.hide();
                }

            }
        },

        editArticuloFactura: function (articulo) {
            this.articulo_editable = JSON.parse(JSON.stringify(articulo));
            this.id_articulo_editable = this.articulo_editable.id_articulo;            
            this.modalEditableFactura = true;
        },
        
        saveNuevaCantidadArticuloFactura: function (modal_abierta=false) {
            if (this.$refs.formeditable.validate()) {

                try {

                    var descartado = this.model_vista_factura.articulos.filter(e => {
                        return e.id_articulo == this.articulo_editable.id_articulo;
                    });
                    if (descartado.length > 0) {
                        window.dialogLoader.show('Espere un momento por favor..');

                        //Editar articulo del modelo con los cambios
                        for (var key in this.model_vista_factura.articulos) {
                            if (this.model_vista_factura.articulos[key].id_articulo == this.articulo_editable.id_articulo) {
                                this.model_vista_factura.articulos[key].cantidad = parseFloat(this.articulo_editable.cantidad);
                                this.model_vista_factura.articulos[key].cantidad_faltante = parseFloat(this.articulo_editable.cantidad_faltante);
                                this.model_vista_factura.articulos[key].cantidad_devuelta = parseFloat(this.articulo_editable.cantidad_devuelta);

                                if (this.articulo_editable.precio_compra != "" && parseFloat(this.articulo_editable.precio_compra)>=0)
                                    this.model_vista_factura.articulos[key].precio_compra = parseFloat(this.articulo_editable.precio_compra);
                                else
                                    this.model_vista_factura.articulos[key].precio_compra = 0;

                                if (this.articulo_editable.iva != "" && parseFloat(this.articulo_editable.iva)>=0)
                                    this.model_vista_factura.articulos[key].iva = parseFloat(this.articulo_editable.iva);
                                else
                                    this.model_vista_factura.articulos[key].iva = 0;

                                if (this.articulo_editable.precio_sin_iva != null && this.articulo_editable.precio_sin_iva != "")
                                    this.model_vista_factura.articulos[key].precio_sin_iva = parseFloat(this.articulo_editable.precio_sin_iva);
                                else
                                    this.model_vista_factura.articulos[key].precio_sin_iva = "";

                                if (this.articulo_editable.precio_con_iva != null && this.articulo_editable.precio_con_iva != "")
                                    this.model_vista_factura.articulos[key].precio_con_iva = parseFloat(this.articulo_editable.precio_con_iva);
                                else
                                    this.model_vista_factura.articulos[key].precio_con_iva = "";

                                if (this.articulo_editable.porcentaje_descuento1 != null && this.articulo_editable.porcentaje_descuento1 != "")
                                    this.model_vista_factura.articulos[key].porcentaje_descuento1 = parseFloat(this.articulo_editable.porcentaje_descuento1);
                                else
                                    this.model_vista_factura.articulos[key].porcentaje_descuento1 = "";

                                if (this.articulo_editable.porcentaje_descuento2 != null && this.articulo_editable.porcentaje_descuento2 != "")
                                    this.model_vista_factura.articulos[key].porcentaje_descuento2 = parseFloat(this.articulo_editable.porcentaje_descuento2);
                                else
                                    this.model_vista_factura.articulos[key].porcentaje_descuento2 = "";

                                if (this.articulo_editable.precio_factura != null && this.articulo_editable.precio_factura != ""){
                                    this.model_vista_factura.articulos[key].precio_factura = parseFloat(this.articulo_editable.precio_factura);
                                    this.model_vista_factura.articulos[key].precio_factura_sin_iva = parseFloat((this.articulo_editable.precio_factura / (1 + (this.model_vista_factura.articulos[key].iva/100))).toFixed(4));
                                }else{
                                    this.model_vista_factura.articulos[key].precio_factura = "";
                                    this.model_vista_factura.articulos[key].precio_factura_sin_iva = "";
                                }

                                //Si es directa, el precio de factura se toma como precio_proveedor
                                if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                                    this.model_vista_factura.articulos[key].precio_proveedor = this.model_vista_factura.articulos[key].precio_factura;
                                    this.model_vista_factura.articulos[key].precio_proveedor_sin_iva = this.model_vista_factura.articulos[key].precio_factura_sin_iva;
                                }

                                if (this.articulo_editable.observaciones_proveedor != null && this.articulo_editable.observaciones_proveedor != "")
                                    this.model_vista_factura.articulos[key].observaciones_proveedor = this.articulo_editable.observaciones_proveedor;
                                else
                                    this.model_vista_factura.articulos[key].observaciones_proveedor = "";
                            }
                        }

                        window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
                            .then(response => {
                                this.model_vista_factura._rev = response.data.rev;
                                this.reloadFacturas(this.model_vista);
                            })
                            .catch(error => {
                                //Si ocurre un conflicto que vuelva a intentarlo
                                if ((error.response && error.response.data && error.response.data['error'] && error.response.data['error']=='conflict') || 
                                    (error.response && error.response.status && error.response.status==409)) {

                                    //Traemos el modelo actualizado
                                    window.axios
                                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista_factura._id)
                                    .then(response => {
                                        this.model_vista_factura = response.data;
                                        this.saveNuevaCantidadArticuloFactura(modal_abierta);
                                    }).catch(error => {
                                        this.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al editar el artículo. Intente nuevamente por favor.",
                                            footer: "saveNuevaCantidadArticuloFactura"
                                        });
                                    });
                                                            
                                } else {
                                    this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                        footer: "saveNuevaCantidadArticuloFactura"
                                    });
                                }
                            }).then(() => {
                                this.modalEditableFactura = modal_abierta;
                                window.dialogLoader.hide();
                            });

                    }

                } catch (error) {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                        footer: ""
                    });
                    console.log(error);
                    window.dialogLoader.hide();
                }

            }
        },

        resetSeleccionado: function(){
            //console.log("FILA: ", this.fila_anterior);

            for(var k in this.datafiltered){
                //console.log(this.datafiltered[k].articulo.nombre);
                var fila = "celda_"+this.datafiltered[k].articulo._id+"_"+this.datafiltered[k].proveedor._id;
                var anterior = document.getElementsByClassName(fila);
                for(var i = 0; i < anterior.length; i++) {
                    anterior[i].style.backgroundColor = 'transparent';
                    anterior[i].style.fontWeight = 'normal';
                }
            }

            /*var actual = document.getElementsByClassName(this.fila_anterior);
            for(var i = 0; i < actual.length; i++) {
                actual[i].style.backgroundColor = '#d6dbdf'; //Gris
                actual[i].style.fontWeight = 'bold';
            }*/
        },

        calcular_compras_ventas: function(){
            this.get_existencia();
            this.consultar_compras();
            this.calcular();
        },

        limpiartablas: function(){
            var anterior = document.getElementsByClassName(this.fila_anterior);
            for(var i = 0; i < anterior.length; i++) {
                anterior[i].style.backgroundColor = 'transparent';
                anterior[i].style.fontWeight = 'normal';
            }
            
            this.precios_articulo = [];
            this.articulo_existencias = [];
            this.items_compras = [];
            this.items_ventas = [];
        },
        

        mostrarInformacion: async function(row){
            //console.log(row);

            this.id_articulo_calculo = row.articulo._id;
            this.row_selected = row;

            try {
                this.plazo_calculo = parseFloat(row.proveedor.plazo);
            } catch (error) {
                this.plazo_calculo = 1;
            }
            //Calcular compras
            this.get_existencia();
            this.consultar_compras();
            this.calcular(); //calcular ventas

            this.precios_articulo = row.articulo.precios_venta;

            var clave_fila = "celda_"+row.articulo._id+'_'+row.proveedor._id;

            var anterior = document.getElementsByClassName(this.fila_anterior);
            for(var i = 0; i < anterior.length; i++) {
                anterior[i].style.backgroundColor = 'transparent';
                anterior[i].style.fontWeight = 'normal';
            }

            this.resetSeleccionado();

            var actual = document.getElementsByClassName(clave_fila);
            for(var i = 0; i < actual.length; i++) {
                actual[i].style.backgroundColor = '#d6dbdf'; //Gris #A9A5A5
                actual[i].style.fontWeight = 'bold';
            }

            this.fila_anterior = clave_fila;
        },

        ingresarCantidad: function (row) {
            this.row_selected = row; 
            console.log(this.row_selected);
            try {
                this.nombre_proveedor_seleccionado = row.proveedor.nombre;    
            } catch (error) {
                this.nombre_proveedor_seleccionado = "";
            }            
            this.cantidad_editable = "";            
            this.precio_editable = "";
            this.modalCantidad = true;
            setTimeout(() => {
                this.$nextTick(() => this.$refs.ingresacantidad.focus());
            }, 100);
        },

        ingresarCantidadAbastos: function (row) {
            this.row_selected = row;
            //console.log("SELECTED: ", row);
            this.cantidad_editable = "";            
            this.precio_editable = "";
            this.modalCantidadAbastos = true;
            setTimeout(() => {
                this.$nextTick(() => this.$refs.ingresacantidad.focus());
            }, 100);
        },

        showInfo: function (id_articulo, plazo) {
            //console.log(id_articulo);
            this.props_historico.id_articulo = id_articulo;
            if (plazo == "")
                this.props_historico.plazo = parseFloat(this.model_vista.proveedor.plazo);
            else
                this.props_historico.plazo = parseFloat(plazo);
            this.props_historico.modal = true;
        },
        ver_compra: function(){
            try {

                window.dialogLoader.show('Espere un momento por favor..');            
                var self = this;
                this.mensaje_tab_pedido = "";

                var busca = encodeURI(escape(this.usuario.toString().toLowerCase()));
                window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_abiertas_usuario?key=\"'+busca+'\"')
                    .then(async response => {
                        //console.log(response);

                        var orden_abierta = [];
                        if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                            if (response.data.rows.length>1){
                                this.mensaje_tab_pedido = "El usuario "+this.usuario+" tiene mas de 1 pedido abierto. Se muestra el más reciente.";                                
                            }
                            var ordenado = response.data.rows.sort((a, b) => new Date(b.value.fecha) - new Date(a.value.fecha));
                            var orden_abierta = ordenado[0].value;

                            //this.articulos_vista = orden_abierta.articulos;
                            this.model_vista = orden_abierta;
                            this.$nextTick(() => self.keyTableArticulosOrden = moment(new Date()).format("x") ); //Refrescar tabla

                        } else {    
                            this.active_tab = "busqueda";                    
                            this.$swal({
                                type: "info",
                                title: "¡No Hay Pedidos Abiertos!",
                                text: "El usuario "+this.usuario+" no tiene pedidos en estatus Orden Abierta. Favor de Verificar.",
                                footer: ""
                            });                                               
                        }
                    }).catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al buscar pedidos aiertos. Intente nuevamente.",
                            footer: ""
                        });                    
                    }).then(()=>{
                        window.dialogLoader.hide();
                    });
            } catch(error){
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al buscar pedidos abiertos. Intente Nuevamente.",
                    footer: ""
                });            
                console.log(error);
            }
        },
        
        agregarSugerido: function (row) {
            //console.log(row);
            this.row_selected = row;
            this.cantidad_editable = row.pedir;
            this.agregarArticulo();
        },

        validarArticulo: function () {
            if (this.$refs.formcantidad.validate()) {
                if(this.sucursal_seleccionada && this.sucursal_seleccionada.abastos == 1){
                    this.agregarArticulo('abastos2'); //abastos2 es cuando se agrega a abastos directo desde la ventana de ingresar cantidad
                } else {
                    this.agregarArticulo('matriz');
                }                
            }
        },
        validarArticuloAbastos: async function () {
            if (this.$refs.formcantidad.validate()) {
                var self = this;
                await this.agregarArticulo('abastos');//abastos es cuando se pasa de un pedido de matriz al de abastos

                //Si la cantidad es mayor o igual a la cantidad de matriz, se quita el articulo de matriz
                // si la cantidad es menor se hace la resta para dejar la cantidad restante en matriz
                var articulo_matriz = this.model_vista.articulos.filter(function(e){
                    return e.id_articulo == self.row_selected.id_articulo;
                });
                var restante = 0;
                if(articulo_matriz.length > 0){
                    restante = parseFloat(articulo_matriz[0].cantidad) - parseFloat(this.cantidad_editable);
                }

                if(restante <= 0){ //Se elimina de matriz
                    this.deleteArticulo(this.row_selected.id_articulo, "sinpregunta");
                } else { //Se guarda la cantidad restante en matriz

                    window.dialogLoader.show('Espere un momento por favor..');

                    self.model_vista.articulos.forEach(function(e){
                        if(e.id_articulo == self.row_selected.id_articulo){
                            e.cantidad = restante;
                        }
                    });

                    await window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', self.model_vista)
                        .then(response => {
                            self.model_vista._rev = response.data.rev;
                            window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                color: 'success'
                            });
                            self.registros.items = [];
                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        })
                        .catch(error => {
                            self.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                footer: error
                            });
                        }).then(() => {
                            window.dialogLoader.hide();
                        });

                }

            }
        },

        validarDatosFactura: function () {
            if (this.$refs.formcantidadplazo.validate()) {
                
                if(this.model_vista.factura_descuento!=null && this.model_vista.factura_descuento!=""
                    && parseFloat(this.model_vista.factura_descuento)>=0 && parseFloat(this.descuento_factura_modal)!=parseFloat(this.model_vista.factura_descuento)){
                    this.$swal({
                        type: "info",
                        title: "Descuento de Factura",
                        text: "Se aplicará el "+this.model_vista.factura_descuento+"% de descuento a cada artículo de la orden de compra. ¿Desea Continuar?",
                        footer: "",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Si",
                        cancelButtonText: "No"
                    }).then(resultado => {
                        if (resultado.value) {
                            this.guardarDatosFactura();
                        }
                    });
                } else {
                    this.guardarDatosFactura();
                }
            }
        },

        guardarDatosFactura: async function (opcion="guardar") {
            try {
                                              
                if (this.$refs.formcantidadplazo.validate()) {

                    window.dialogLoader.show('Espere un momento por favor..');

                    var self = this;
                    if(this.model_vista_factura.plazo_pago != null && this.model_vista_factura.plazo_pago != "" && parseInt(this.model_vista_factura.plazo_pago)>=0){
                        this.model_vista_factura.plazo_pago = parseInt(this.model_vista_factura.plazo_pago);
                    }
                    if(this.model_vista_factura.factura_fecha != null && this.model_vista_factura.factura_fecha != ""){
                        this.model_vista_factura.factura_fecha = window.moment(this.model_vista_factura.factura_fecha).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    if(this.model_vista_factura.fecha_recibio_mercancia != null && this.model_vista_factura.fecha_recibio_mercancia != ""){
                        this.model_vista_factura.fecha_recibio_mercancia = window.moment(this.model_vista_factura.fecha_recibio_mercancia).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    if(this.model_vista_factura.factura_fecha_vencimiento != null && this.model_vista_factura.factura_fecha_vencimiento != ""){
                        this.model_vista_factura.factura_fecha_vencimiento = window.moment(this.model_vista_factura.factura_fecha_vencimiento).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    if(this.model_vista_factura.factura_descuento!=null && this.model_vista_factura.factura_descuento!="" && parseFloat(this.model_vista_factura.factura_descuento)>0 ){
                        this.setDescuento();
                        this.model_vista_factura.factura_descuento = parseFloat(this.model_vista_factura.factura_descuento);
                        this.model_vista_factura.factura_descuento_total = parseFloat(this.model_vista_factura.factura_descuento_total);
                    } else {
                        this.model_vista_factura.factura_descuento = 0;
                        this.model_vista_factura.factura_descuento_total = 0;
                    }

                    //Recorremos cada articulo para aplicar el descuento general de factura
                    for(var k in this.model_vista_factura.articulos){
                        var articulo = this.model_vista_factura.articulos[k];
                        var precio_articulo = this.get_precio_articulo_descuento(articulo);
                        articulo.precio_factura = parseFloat((precio_articulo - (precio_articulo * (this.model_vista_factura.factura_descuento/100))).toFixed(4));
                        articulo.precio_factura_sin_iva = parseFloat((articulo.precio_factura / (1 + (articulo.iva/100))).toFixed(4));
                        if(this.model_vista.orden_creada && this.model_vista.orden_creada=="directa"){
                            //Si es directa el precio proveedor es el mismo que el de factura
                            articulo.precio_proveedor = articulo.precio_factura;
                            articulo.precio_proveedor_sin_iva = articulo.precio_factura_sin_iva;
                        }
                        
                    }

                    window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
                    .then(response => {
                        this.model_vista_factura._rev = response.data.rev;
                        this.update_arreglo_facturas("actualizar"); //actualizar, solo para que actualice arreglo, pero no muestre modal de factura
                    })
                    .catch(error => {
                        //Si ocurre un conflicto que vuelva a intentarlo
                        if ((error.response && error.response.data && error.response.data['error'] && error.response.data['error']=='conflict') || 
                            (error.response && error.response.status && error.response.status==409)) {

                            //Traemos el modelo actualizado
                            window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista_factura._id)
                            .then(response => {
                                this.model_vista_factura = response.data;
                                this.guardarDatosFactura(opcion);
                            }).catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el artículo. Intente nuevamente por favor.",
                                    footer: "guardarDatosFactura"
                                });
                            });

                        } else {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                footer: "guardarDatosFactura"
                            });
                        }
                    }).then(()=>{
                        window.dialogLoader.hide();
                        this.modalFactura = false;
                        this.modalFacturaDirecta = false;
                    });
                }
                
            } catch (error) {
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar datos de factura. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
            }

        },

        agregarArticulo: async function (opcion_sucursal="matriz", cantidad_restante=0) {
            try {

                window.dialogLoader.show('Espere un momento por favor..');

                var self = this;
                var proveedor = this.row_selected.proveedor;
                if(proveedor == undefined){//Si es undefined entonces se esta agregando articulo a Abastos
                    proveedor = await self.getProveedor(self.model_vista.proveedor.id_proveedor);
                }
                var articulo = this.row_selected.articulo;
                if(articulo == undefined){//Si es undefined entonces se esta agregando articulo a Abastos
                    articulo = await self.getArticulo(self.row_selected.id_articulo);
                }

                //Precio unitario, opcional
                var precio_editable_sin_imp = "";
                var precio_editable_con_imp = "";                
                if(this.precio_editable != null && this.precio_editable != "" && parseFloat(this.precio_editable)>0){
                    precio_editable_con_imp = parseFloat(parseFloat(this.precio_editable).toFixed(4));                    
                    var imp_art = self.getSumaImpuestos(articulo.impuestos);
                    precio_editable_sin_imp = parseFloat((precio_editable_con_imp / (1+(parseFloat(imp_art)/100))).toFixed(4));                    
                }

                var cantidad = parseFloat(this.cantidad_editable);
                if(cantidad_restante > 0){//Si entra aqui es que se actualizo la cantidad de matriz, al enviar cantidad a abastos
                    cantidad = parseFloat(cantidad_restante);
                    self.model_vista.articulos.forEach(function(e){
                        if(e.id_articulo == articulo._id){
                            e.cantidad = cantidad;                            
                        }
                    });
                }
                
                var sucursal_destino = "";
                var tipo_sucursal_destino = "matriz";
                if(opcion_sucursal == "matriz"){
                    sucursal_destino = self.suc_matriz;
                } else {
                    sucursal_destino = self.suc_abastos;
                    tipo_sucursal_destino = "abastos";
                }
                //console.log("AGREGAR ARTICULO: ", articulo);
                //console.log("SUCURSAL: ", sucursal_destino);
                var busca = encodeURI(escape(proveedor._id));
                await window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_abiertas?key=\"'+busca+'\"')
                    .then(async response => {
                        //console.log(response);
                        //console.log("Buscando Ordenes abiertas: ", response);

                        var orden_abierta = [];
                        if(response.data != null && response.data.rows!=null && response.data.rows.length>0){

                            var ordenado = response.data.rows.reverse();
                            //Verificamos que sea una orden abierta del usuario
                            orden_abierta = ordenado.filter(function(e){
                                return e.value.usuario.toString().trim().toLowerCase() == self.usuario.toString().trim().toLowerCase() && e.value.id_sucursal_destino == sucursal_destino._id;
                            });
                        }
                        if(orden_abierta.length > 0){
                            //Si ya existe la compra se agrega el articulo (si ya existe articulo se sobreescribe cantidad)

                            var articuloEncontrado = orden_abierta[0].value;
                            articuloEncontrado.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss"); //actualizamos la fecha del pedido para tener siempre el mas reciente
                            var articuloExiste = 0;
                            for (var key in articuloEncontrado.articulos) {
                                if (articuloEncontrado.articulos[key].id_articulo == articulo._id) {
                                    articuloExiste = 1;
                                    articuloEncontrado.articulos[key].cantidad += cantidad;
                                    if(precio_editable_sin_imp!==""){
                                        //console.log("Si entra precio_editable_con_imp", precio_editable_con_imp);
                                        articuloEncontrado.articulos[key].precio_sin_iva = precio_editable_sin_imp;                                
                                        articuloEncontrado.articulos[key].precio_con_iva = precio_editable_con_imp;
                                        articuloEncontrado.articulos[key].precio_proveedor_sin_iva = precio_editable_sin_imp;                                
                                        articuloEncontrado.articulos[key].precio_proveedor = precio_editable_con_imp;
                                    }
                                    if(opcion_sucursal == "abastos"){//Si es abastos se copia la observacion y precio del proveedor
                                        articuloEncontrado.articulos[key].precio_sin_iva = self.row_selected.precio_sin_iva;
                                        articuloEncontrado.articulos[key].iva = self.row_selected.iva;
                                        articuloEncontrado.articulos[key].precio_con_iva = self.row_selected.precio_con_iva;
                                        articuloEncontrado.articulos[key].porcentaje_descuento1 = self.row_selected.porcentaje_descuento1;
                                        articuloEncontrado.articulos[key].porcentaje_descuento2 = self.row_selected.porcentaje_descuento2;
                                        articuloEncontrado.articulos[key].precio_proveedor = self.row_selected.precio_proveedor;
                                        articuloEncontrado.articulos[key].precio_proveedor_sin_iva = self.row_selected.precio_proveedor_sin_iva;
                                        articuloEncontrado.articulos[key].observaciones_proveedor = self.row_selected.observaciones_proveedor;
                                    }
                                }
                            }

                            if (articuloExiste == 0) { //Si no lo encontró, se agrega

                                await window.funciones_lotes.consultaExistenciaTotal({
                                        "id_articulo": articulo._id
                                    })
                                    .then(async responseExistencia => {
                                        var model = {
                                            id_articulo: articulo._id,
                                            nombre_articulo: articulo.nombre,
                                            descripcion: articulo.descripcion,
                                            codigo_articulo: articulo.codigo_barras,
                                            impuestos_articulo: articulo.impuestos, 
                                            cantidad: cantidad,
                                            precio_compra: parseFloat(articulo.precio_compra), //Último precio de compra
                                            precio_compra_con_iva: parseFloat(articulo.precio_compra_con_iva), //Último precio de compra con iva
                                            precio_sin_iva: precio_editable_sin_imp==""? "" : precio_editable_sin_imp,
                                            iva: self.getSumaImpuestos(articulo.impuestos), //Ahora guarda la suma de impuestos del articulo
                                            precio_con_iva: precio_editable_con_imp==""? "" : precio_editable_con_imp,
                                            porcentaje_descuento1:"",
                                            porcentaje_descuento2:"",
                                            precio_proveedor:  precio_editable_con_imp==""? "" : precio_editable_con_imp,
                                            precio_proveedor_sin_iva:  precio_editable_sin_imp==""? "" : precio_editable_sin_imp,
                                            existencia_articulo: parseFloat(parseFloat(responseExistencia).toFixed(2)),
                                            observaciones_proveedor: "",
                                            estatus: "Pendiente",
                                            cantidad_recibida: 0,
                                            cantidad_faltante: 0,
                                            cantidad_devuelta: 0,
                                            lotes_destino: [],
                                            resaltado: 0,
                                            sucursal_recibio: "",
                                            usuario_recibio: "",
                                            fecha_recibio: "",
                                        };
                                        if(opcion_sucursal == "abastos"){//Si es abastos se copia la observacion y precio del proveedor
                                            model.precio_sin_iva = self.row_selected.precio_sin_iva;
                                            model.iva = self.row_selected.iva;
                                            model.precio_con_iva = self.row_selected.precio_con_iva;
                                            model.porcentaje_descuento1 = self.row_selected.porcentaje_descuento1;
                                            model.porcentaje_descuento2 = self.row_selected.porcentaje_descuento2;
                                            model.precio_proveedor = self.row_selected.precio_proveedor;
                                            model.precio_proveedor_sin_iva = self.row_selected.precio_proveedor_sin_iva;
                                            model.observaciones_proveedor = self.row_selected.observaciones_proveedor;
                                        }
                                        articuloEncontrado.articulos.push(model);
                                        
                                        await window.axios
                                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', articuloEncontrado)
                                            .then(response => {
                                                if(cantidad_restante > 0){//Si entra aqui es que se actualizo la cantidad de matriz, y se debe actualizar el rev
                                                    self.model_vista._rev = response.data.rev;
                                                }
                                                window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                                    color: 'success'
                                                });
                                                
                                                self.registros.items = [];
                                                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                                
                                            })
                                            .catch(error => {
                                                self.$swal({
                                                    type: "error",
                                                    title: "¡Operación no Permitida!",
                                                    text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                                    footer: error
                                                });
                                            }).then(() => {
                                                window.dialogLoader.hide();
                                            });
                                    }).catch(err => {
                                        window.dialogLoader.hide();
                                        console.log("Error ", err);
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                            footer: ""
                                        });
                                    });


                            } else {
                                await window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', articuloEncontrado)
                                    .then(response => {
                                        if(cantidad_restante > 0){//Si entra aqui es que se actualizo la cantidad de matriz, y se debe actualizar el rev
                                            self.model_vista._rev = response.data.rev;
                                        }
                                        window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                            color: 'success'
                                        });
                                        //if(opcion_sucursal == "matriz"){
                                            self.registros.items = [];
                                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                        //}
                                    })
                                    .catch(error => {
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                            footer: error
                                        });
                                    }).then(() => {
                                        window.dialogLoader.hide();
                                    });
                            }

                        } else {
                            //Si no existe la compra se crea una nueva con el proveedor y el articulo
                            //Obtener consecutivo de Compras
                            //Obtener ultimo precio de compra
                            //console.log("Creando ORDEN NUEVA");
                            let dataConsecutivo = {
                                "id_sucursal": "", //requerido
                                "tabla": "compras", //requerido
                                "longitud": 5 //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                            };
                            await window.funciones.getConsecutivo(dataConsecutivo)
                                .then(async resultConsecutivo => {
                                    //console.log("Obteniendo consecutivo");

                                    var consecutivo = resultConsecutivo;
                                    var anio = window.moment().format("YYYY").toString().substr(-2);
                                    var orden = "C" + anio + consecutivo.toString();

                                    await window.funciones_lotes.consultaExistenciaTotal({
                                        "id_articulo": articulo._id
                                    })
                                    .then(async responseExistencia => {
                                        //console.log("Obteniendo Existencia");
                                        var model = {
                                            type: "pedidos",
                                            no_orden: orden,
                                            id_sucursal_destino: sucursal_destino._id,
                                            nombre_sucursal_destino: sucursal_destino.nombre,
                                            tipo_sucursal: tipo_sucursal_destino,
                                            fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                            facturas:[],
                                            proveedor: {
                                                id_proveedor: proveedor._id,
                                                nombre_proveedor: proveedor.nombre,
                                                rfc: proveedor.rfc,
                                                plazo: proveedor.plazo,
                                                contacto: proveedor.contacto,
                                                email: proveedor.email,
                                                telefono_1: proveedor.telefono_1,                                                
                                            },
                                            estatus: "Orden Abierta",
                                            articulos: [{
                                                id_articulo: articulo._id,
                                                nombre_articulo: articulo.nombre,
                                                descripcion: articulo.descripcion,
                                                codigo_articulo: articulo.codigo_barras,
                                                impuestos_articulo: articulo.impuestos,
                                                cantidad: cantidad,
                                                precio_compra: parseFloat(articulo.precio_compra), //Último precio de compra
                                                precio_compra_con_iva: parseFloat(articulo.precio_compra_con_iva), //Último precio de compra con iva
                                                precio_sin_iva: precio_editable_sin_imp==""? "" : precio_editable_sin_imp,
                                                iva: self.getSumaImpuestos(articulo.impuestos), //Ahora guarda la suma de impuestos del articulo
                                                precio_con_iva: precio_editable_con_imp==""? "" : precio_editable_con_imp,
                                                porcentaje_descuento1:"",
                                                porcentaje_descuento2:"",
                                                precio_proveedor: precio_editable_con_imp==""? "" : precio_editable_con_imp,
                                                precio_proveedor_sin_iva: precio_editable_sin_imp==""? "" : precio_editable_sin_imp,
                                                existencia_articulo: parseFloat(parseFloat(responseExistencia).toFixed(2)),
                                                observaciones_proveedor: "",
                                                estatus: "Pendiente",
                                                cantidad_recibida: 0,
                                                cantidad_faltante: 0,
                                                cantidad_devuelta: 0,
                                                lotes_destino: [],
                                                resaltado: 0,
                                                sucursal_recibio: "",
                                                usuario_recibio: "",
                                                fecha_recibio: "",
                                            }],
                                            usuario: self.usuario,
                                            observaciones_orden: "",
                                            condiciones_orden:proveedor.condiciones,
                                            usuario_entrada: "",
                                            observaciones_entrada: "",
                                            fecha_finalizada: "",
                                        };

                                        if(opcion_sucursal == "abastos"){//Si es abastos se copia la observacion y precio del proveedor
                                            model.articulos[0].precio_sin_iva = self.row_selected.precio_sin_iva;
                                            model.articulos[0].iva = self.row_selected.iva;
                                            model.articulos[0].precio_con_iva = self.row_selected.precio_con_iva;
                                            model.articulos[0].porcentaje_descuento1 = self.row_selected.porcentaje_descuento1;
                                            model.articulos[0].porcentaje_descuento2 = self.row_selected.porcentaje_descuento2;
                                            model.articulos[0].precio_proveedor = self.row_selected.precio_proveedor;
                                            model.articulos[0].precio_proveedor_sin_iva = self.row_selected.precio_proveedor_sin_iva;
                                            model.articulos[0].observaciones_proveedor = self.row_selected.observaciones_proveedor;
                                        }

                                        await window.axios
                                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                                            .then(response => {
                                                //console.log("ORDEN CREADA: ", response);
                                                window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                                    color: 'success'
                                                });
                                                
                                                self.registros.items = [];
                                                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');                                                
                                            })
                                            .catch(error => {
                                                this.$swal({
                                                    type: "error",
                                                    title: "¡Operación no Permitida!",
                                                    text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                                    footer: error
                                                });
                                            }).then(() => {
                                                window.dialogLoader.hide();
                                            });
                                    }).catch(err => {
                                        window.dialogLoader.hide();
                                        console.log("Error ", err);
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                            footer: ""
                                        });
                                    });



                                }).catch(err => {
                                    console.log(err);
                                    self.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al obtener consecutivo. Intente nuevamente.",
                                        footer: ""
                                    });
                                });

                        }

                    }).catch(error => {
                        window.dialogLoader.hide();
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error verificar orden de compra. Intente nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        this.modalCantidad = false;
                        this.modalCantidadAbastos = false;
                    });

            } catch (error) {
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al iniciar la orden de compra. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
            }

        },

        generarOrdenDirecta: async function(){
            if (this.$refs.formproveedor.validate()) {
                //Generar orden con el proveedor y articulos vacío, mostrar ventana de factura y articulos todos para agregar a factura
                window.dialogLoader.show('Espere un momento por favor..');

                var self = this;
                var proveedor = await self.getProveedor(self.id_proveedor);

                var sucursal_destino = self.suc_matriz;
                var tipo_sucursal_destino = "matriz";
                if (this.sucursal_select == "abastos"){                    
                    sucursal_destino = self.suc_abastos;
                    tipo_sucursal_destino = "abastos";
                }

                let dataConsecutivo = {
                    "id_sucursal": "", //requerido
                    "tabla": "compras", //requerido
                    "longitud": 5 //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                };
                await window.funciones.getConsecutivo(dataConsecutivo)
                    .then(async resultConsecutivo => {
                        //console.log("Obteniendo consecutivo");

                        var consecutivo = resultConsecutivo;
                        var anio = window.moment().format("YYYY").toString().substr(-2);
                        var orden = "C" + anio + consecutivo.toString();
                        
                        var model = {
                            type: "pedidos",
                            orden_creada: "directa", //Se agrega este campo para saber si se creo directa o por orden normal
                            no_orden: orden,
                            id_sucursal_destino: sucursal_destino._id,
                            nombre_sucursal_destino: sucursal_destino.nombre,
                            tipo_sucursal: tipo_sucursal_destino,
                            fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                            facturas:[],
                            proveedor: {
                                id_proveedor: proveedor._id,
                                nombre_proveedor: proveedor.nombre,
                                rfc: proveedor.rfc,
                                plazo: proveedor.plazo,
                                contacto: proveedor.contacto,
                                email: proveedor.email,
                                telefono_1: proveedor.telefono_1,                                
                            },
                            estatus: "Orden Abierta",
                            articulos: [],
                            usuario: self.usuario,
                            observaciones_orden: "",
                            condiciones_orden:proveedor.condiciones,
                            usuario_entrada: "",
                            observaciones_entrada: "",
                            fecha_finalizada: "",
                        };

                        await window.axios
                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                            .then(response => {
                                model["_rev"] = response.data.rev;
                                model["_id"] = response.data.id;
                                self.modalProveedor = false;
                                self.showFacturas(model); //Abrimos modal de factura
                                self.registros.items = [];
                                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                
                            })
                            .catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al generar orden directa. Intente nuevamente.",
                                    footer: error
                                });
                            }).then(() => {
                                window.dialogLoader.hide();
                            });                    

                    }).catch(err => {
                        console.log(err);
                        self.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al obtener consecutivo. Intente nuevamente.",
                            footer: ""
                        });
                    });
                                
                
            }
        },

        getImpuestosString: function(arr_impuestos){
            var cadena = "";
            var impuestos = [];
            try {
                if(arr_impuestos && arr_impuestos.length){
                    arr_impuestos.forEach(function(e){                    
                        impuestos.push(e.impuesto +":"+ e.tasa);
                    });
                    cadena = impuestos.join(", ");
                }
                
            } catch (error) {
                console.log("Error en getImpuestosString: ", error);
            }
            return cadena;
        },
        getSumaImpuestos: function(arr_impuestos){
            var sumatoria = 0;
            try {
                if(arr_impuestos && arr_impuestos.length){
                    arr_impuestos.forEach(function(e){
                        sumatoria += parseFloat(e.tasa);
                    });
                }
            } catch (error) {
                console.log("Error en getSumaImpuestos: ", error);
            }
            return sumatoria;
        },

        agregarTodosSugerido: async function () {
            try {
                let self = this;
                window.dialogLoader.show('Espere un momento por favor..');

                var id_proveedor = self.id_proveedor;

                var tipo_sucursal_destino = "matriz"; //Por default es matriz
                var sucursal_destino = self.suc_matriz;

                var busca = encodeURI(escape(id_proveedor));
                await window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_abiertas?key=\"'+busca+'\"')
                    .then(async response => {

                        //console.log("paso 2");
                        var orden_abierta = [];
                        if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                            var ordenado = response.data.rows.reverse();
                            //Verificamos que sea una orden abierta del usuario
                            orden_abierta = ordenado.filter(function(e){
                                return e.value.usuario.toString().trim().toLowerCase() == self.usuario.toString().trim().toLowerCase();
                            });
                        }

                        if(orden_abierta.length > 0){
                            //Si ya existe la compra se agrega el articulo (si ya existe articulo se sobreescribe cantidad)
                            var articuloEncontrado = orden_abierta[0].value;

                             await Promise.all(self.data_sugeridos.map(async (x) => {
                         //  await self.data_sugeridos.forEach( async function (x) {
                                    //console.log("paso 3");
                                    //console.log(x);

                                    var articuloExiste = 0;
                                    for (var key in articuloEncontrado.articulos) {
                                        if (articuloEncontrado.articulos[key].id_articulo == x.articulo._id) {
                                            articuloExiste = 1;
                                            articuloEncontrado.articulos[key].cantidad = parseFloat(x.pedir);
                                        }
                                    }
                                    if (articuloExiste == 0) { //Si no lo encontró, se agrega

                                        await window.funciones_lotes.consultaExistenciaTotal({
                                                "id_articulo": x.articulo._id
                                            })
                                            .then(async responseExistencia => {
                                                var model = {
                                                    id_articulo: x.articulo._id,
                                                    nombre_articulo: x.articulo.nombre,
                                                    descripcion: x.articulo.descripcion,
                                                    codigo_articulo: x.articulo.codigo_barras,
                                                    impuestos_articulo: x.articulo.impuestos,
                                                    cantidad: parseFloat(x.pedir),
                                                    precio_compra: parseFloat(x.articulo.precio_compra), //Último precio de compra
                                                    precio_compra_con_iva: parseFloat(x.articulo.precio_compra_con_iva), //Último precio de compra con iva
                                                    precio_sin_iva:"",
                                                    iva: self.getSumaImpuestos(x.articulo.impuestos), //Ahora guarda la suma de impuestos del articulo
                                                    precio_con_iva:"",
                                                    porcentaje_descuento1:"",
                                                    porcentaje_descuento2:"",
                                                    precio_proveedor: "",
                                                    precio_proveedor_sin_iva: "",
                                                    existencia_articulo: parseFloat(parseFloat(responseExistencia).toFixed(2)),
                                                    observaciones_proveedor: "",
                                                    estatus: "Pendiente",
                                                    cantidad_recibida: 0,
                                                    cantidad_faltante: 0,
                                                    cantidad_devuelta: 0,
                                                    lotes_destino: [],
                                                    resaltado: 0,
                                                    sucursal_recibio: "",
                                                    usuario_recibio: "",
                                                    fecha_recibio: "",
                                                };
                                                articuloEncontrado.articulos.push(model);
                                                //console.log("paso 4");
                                                //console.log(self.g_articulo.articulo);

                                            }).catch(err => {
                                                window.dialogLoader.hide();
                                                console.log("Error ", err);
                                                self.$swal({
                                                    type: "error",
                                                    title: "¡Operación no Permitida!",
                                                    text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                                    footer: ""
                                                });
                                            });

                                    }

                                }));


                                //console.log("paso 5");
                                //console.log(articuloEncontrado);

                                await window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', articuloEncontrado)
                                    .then(response => {
                                        window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                            color: 'success'
                                        });
                                        self.registros.items = [];
                                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                        self.modalSugeridos = false;
                                        //console.log("paso 6");
                                        window.dialogLoader.hide();
                                    })
                                    .catch(error => {
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                            footer: error
                                        });
                                    });

                        } else {
                            //Si no existe la compra se crea una nueva con el proveedor y el articulo
                            //Obtener consecutivo de Compras
                            //Obtener ultimo precio de compra

                            let dataConsecutivo = {
                                "id_sucursal": "", //requerido
                                "tabla": "compras", //requerido
                                "longitud": 5 //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                            };
                            await window.funciones.getConsecutivo(dataConsecutivo)
                                .then(async resultConsecutivo => {

                                    var consecutivo = resultConsecutivo;
                                    var anio = window.moment().format("YYYY").toString().substr(-2);
                                    var orden = "C" + anio + consecutivo.toString();
                                    var proveedor = this.data_sugeridos[0].proveedor;

                                    var model = {
                                        type: "pedidos",
                                        no_orden: orden,
                                        id_sucursal_destino: sucursal_destino._id,
                                        nombre_sucursal_destino: sucursal_destino.nombre,
                                        tipo_sucursal: tipo_sucursal_destino,
                                        fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                        facturas:[],
                                        proveedor: {
                                            id_proveedor: proveedor._id,
                                            nombre_proveedor: proveedor.nombre,
                                            rfc: proveedor.rfc,
                                            plazo: proveedor.plazo,
                                            contacto: proveedor.contacto,
                                            email: proveedor.email,
                                            telefono_1: proveedor.telefono_1,                                            
                                        },
                                        estatus: "Orden Abierta",
                                        articulos: [],
                                        usuario: self.usuario,
                                        observaciones_orden: "",
                                        condiciones_orden:proveedor.condiciones,
                                        usuario_entrada: "",
                                        observaciones_entrada: "",
                                        fecha_finalizada: "",
                                    };

                                   await Promise.all(self.data_sugeridos.map(async (x) => {
                                  //  self.data_sugeridos.forEach(async function (x) {

                                        await window.funciones_lotes.consultaExistenciaTotal({
                                                "id_articulo": x.articulo._id
                                            })
                                            .then(async responseExistencia => {
                                                var reg = {
                                                    id_articulo: x.articulo._id,
                                                    nombre_articulo: x.articulo.nombre,
                                                    descripcion: x.articulo.descripcion,
                                                    codigo_articulo: x.articulo.codigo_barras,
                                                    impuestos_articulo: x.articulo.impuestos,
                                                    cantidad: parseFloat(x.pedir),
                                                    precio_compra: parseFloat(x.articulo.precio_compra), //Último precio de compra
                                                    precio_compra_con_iva: parseFloat(x.articulo.precio_compra_con_iva), //Último precio de compra con iva
                                                    precio_sin_iva:"",
                                                    iva: self.getSumaImpuestos(x.articulo.impuestos), //Ahora el iva guarda la suma de impuestos del articulo
                                                    precio_con_iva:"",
                                                    porcentaje_descuento1:"",
                                                    porcentaje_descuento2:"",
                                                    precio_proveedor: "",
                                                    precio_proveedor_sin_iva: "",
                                                    existencia_articulo: parseFloat(parseFloat(responseExistencia).toFixed(2)),
                                                    observaciones_proveedor: "",
                                                    estatus: "Pendiente",
                                                    cantidad_recibida: 0,
                                                    cantidad_faltante: 0,
                                                    cantidad_devuelta: 0,
                                                    lotes_destino: [],
                                                    resaltado: 0,
                                                    sucursal_recibio: "",
                                                    usuario_recibio: "",
                                                    fecha_recibio: "",
                                                }
                                                model.articulos.push(reg);
                                                //console.log("paso 3");
                                                //console.log(x);
                                            }).catch(err => {
                                                window.dialogLoader.hide();
                                                console.log("Error ", err);
                                                self.$swal({
                                                    type: "error",
                                                    title: "¡Operación no Permitida!",
                                                    text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                                    footer: ""
                                                });
                                            });

                                    }));

                                    await window.axios
                                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                                        .then(response => {
                                            window.dialogLoader.showSnackbar('Orden Creada Correctamente', {
                                                color: 'success'
                                            });
                                             self.registros.items = [];
                                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                            self.modalSugeridos = false;
                                            //console.log("paso 6");
                                            window.dialogLoader.hide();
                                        })
                                        .catch(error => {
                                            this.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                                footer: error
                                            });
                                        });
                                }).catch(err => {
                                    console.log(err);
                                    self.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al obtener consecutivo. Intente nuevamente.",
                                        footer: ""
                                    });
                                });

                        }
                    })
                    .catch(error => {
                        window.dialogLoader.hide();
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error verificar orden de compra. Intente nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        self.modalCantidad = false;
                        this.modalCantidadAbastos = false;
                    });

            } catch (error) {
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al iniciar la orden de compra. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
            }

        },
        deleteArticulo: function (idArticulo, opcion="eliminar") {

             if(opcion == "eliminar"){
                var nombre_articulo = "";
                var art_a_remover = this.model_vista.articulos.find(e => e.id_articulo == idArticulo);
                if (art_a_remover) {
                    nombre_articulo = art_a_remover.nombre_articulo;
                }

                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "¿Realmente desea descartar el artículo: "+nombre_articulo+"?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                }).then(resultado => {
                    if (resultado.value) {
                        var descartado = this.model_vista.articulos.filter(e => {
                            return e.id_articulo == idArticulo;
                        });
                        if (descartado.length > 0) {
                            window.dialogLoader.show('Espere un momento por favor..');

                            //Eliminar articulo del modelo
                            var filter = this.model_vista.articulos.filter(e => {
                                return e.id_articulo != idArticulo;
                            });
                            this.model_vista.articulos = filter;
                            //Si no se elimina se guarda
                            window.axios
                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                                .then(response => {
                                    this.model_vista._rev = response.data.rev;
                                })
                                .catch(error => {
                                    this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                                        footer: error
                                    });
                                }).then(() => {
                                    this.registros.items = [];
                                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                    window.dialogLoader.hide();
                                });
                            

                        }
                    }
                });
             } else {
                var descartado = this.model_vista.articulos.filter(e => {
                    return e.id_articulo == idArticulo;
                });
                if (descartado.length > 0) {
                    window.dialogLoader.show('Espere un momento por favor..');

                    //Eliminar articulo del modelo
                    var filter = this.model_vista.articulos.filter(e => {
                        return e.id_articulo != idArticulo;
                    });
                    this.model_vista.articulos = filter;
                    //se guarda
                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                        .then(response => {
                            this.model_vista._rev = response.data.rev;
                        })
                        .catch(error => {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                                footer: error
                            });
                        }).then(() => {
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            window.dialogLoader.hide();
                        });
                    

                }
             }
             
            
        },
        deleteArticuloFactura: function (idArticulo) {      
            
            var nombre_articulo = "";
            var art_a_remover = this.model_vista_factura.articulos.find(e => e.id_articulo == idArticulo);
            if (art_a_remover) {
                nombre_articulo = art_a_remover.nombre_articulo;
            }
            
            this.$swal({
                type: "info",
                title: "Cuidado",
                text: "¿Realmente desea descartar el artículo de la factura: "+nombre_articulo+"?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(resultado => {
                if (resultado.value) {
                    var descartado = this.model_vista_factura.articulos.filter(e => {
                        return e.id_articulo == idArticulo;
                    });
                    if (descartado.length > 0) {
                        window.dialogLoader.show('Espere un momento por favor..');

                        //Eliminar articulo del modelo
                        var filter = this.model_vista_factura.articulos.filter(e => {
                            return e.id_articulo != idArticulo;
                        });
                        this.model_vista_factura.articulos = filter;
                       
                        window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
                            .then(response => {
                                this.model_vista_factura._rev = response.data.rev;
                            })
                            .catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al decartar el artículo. Intente nuevamente.",
                                    footer: error
                                });
                            }).then(() => {
                                this.showFacturas(this.model_vista);
                                window.dialogLoader.hide();
                            });
                        

                    }
                }
            });                          
            
        },

        imprimirOrdenEntrada: function (row_in) {
            var self = this;
            window.dialogLoader.show('Espere un momento por favor..');
            
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row_in._id)
            .then(response => {                
                var row = response.data;
                var img = document.createElement("img");
                JsBarcode(img, row.no_orden, {
                    format: "CODE128"
                });

                var columns = [{
                        title: "Codigo",
                        dataKey: "codigo_articulo"
                    },
                    {
                        title: "Artículo",
                        dataKey: "nombre_articulo"
                    },
                    {
                        title: "Descripción",
                        dataKey: "descripcion"
                    },
                    {
                        title: "Cantidad",
                        dataKey: "cantidad"
                    },
                ];

                var doc = new jsPDF('p', 'mm', 'letter');

                doc.setFontSize(14);
                doc.setFont("Helvetica", "");
                doc.text('Orden de Entrada', 15, 23);
                doc.setFontSize(9);
                doc.text('Proveedor: ' + row.proveedor.nombre_proveedor, 15, 30);
                doc.setFont("Helvetica", "bold");
                doc.text('Sucursal Destino: '+row.nombre_sucursal_destino, 15, 35);
                doc.setFont("Helvetica", "");
                doc.text('Fecha Elaboración: ' + this.parse_date_time_mx(row.fecha), 15, 40);
                doc.text('Fecha Impresión: ' + this.parse_date_time_mx(window.moment()), 15, 45);
                doc.addImage(img.src, 'PNG', 150, 20, 50, 30);
                doc.autoTable(columns, row.articulos, {
                    startY: 60,
                });

                doc.save(row.no_orden + ".pdf");

                window.dialogLoader.hide();
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
                window.dialogLoader.hide();
            });
            
        },
        
        editarObservaciones: function (row) {
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                this.model = row;
                this.modalObservacion = true;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
            
        },

        guardarObservaciones: function () {
            if (this.$refs.formobservacion.validate()) {
                window.dialogLoader.show('Espere un momento por favor..');

                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                    .then(response => {
                        window.dialogLoader.showSnackbar('Las observaciones se guardaron correctamente', {
                            color: 'success'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar observaciones. Favor de intentar nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        this.modalObservacion = false;
                        this.registros.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        window.dialogLoader.hide();
                    });
            }
        },

        editarFechaEntrega: function (row) {
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                this.model = row;
                if(this.model.fecha_entrega_estimada && this.model.fecha_entrega_estimada!=""){
                    this.model.fecha_entrega_estimada = this.parseDate(this.model.fecha_entrega_estimada);
                }
                this.modalFechaEntrega = true;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
            
        },

        guardarFechaEntrega: function () {
            if (this.$refs.formfechaentrega.validate()) {
                window.dialogLoader.show('Espere un momento por favor..');

                this.model.fecha_entrega_estimada = window.moment(this.model.fecha_entrega_estimada).format("YYYY-MM-DDTHH:mm:ss");
                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                    .then(response => {
                        window.dialogLoader.showSnackbar('La fecha se guardó correctamente', {
                            color: 'success'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar observaciones. Favor de intentar nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        this.modalFechaEntrega = false;                        
                        window.dialogLoader.hide();
                        this.getPedidosVencidos();
                    });
            }
        },

        eliminarOrden: function (row) {
            if(row.facturas && row.facturas.length > 0){
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "La compra tiene facturas registradas y no se puede eliminar. Favor de verificar.",
                    footer: ""
                });
            } else {
                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "¿Realmente desea eliminar la orden: "+row.no_orden+"?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                }).then(resultado => {
                    if (resultado.value) {

                        window.dialogLoader.show('Espere un momento por favor..');

                        window.axios
                            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id + "?rev=" + row._rev)
                            .then(response => {
                                window.dialogLoader.showSnackbar('La orden se eliminó correctamente..', {
                                    color: 'success'
                                });
                            })
                            .catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al eliminar la orden. Intente nuevamente.",
                                    footer: error
                                });
                            }).then(() => {
                                this.modalBusqueda = false;
                                this.registros.items = [];
                                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                window.dialogLoader.hide();
                                if(row.fecha_entrega_estimada){
                                    this.getPedidosVencidos();
                                }                                
                            });

                    }
                });
            }

        },
        cambiarEstatus(row) {
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                this.model_vista = row;
                this.estatus_editable = row.estatus;
                this.modalEstatus = true;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
            
        },

        verifica_facturas_finalizadas: function(id_pedido){
            window.dialogLoader.show('Espere un momento por favor..');
            return new Promise(async function (resolve, reject) {
                
                var result = {"finalizadas":0, "total":0}
                let data = {
                    selector: {"type":"compras","id_orden_origen":id_pedido},
                    use_index:"_design/a382e7c18fba466ff6baeb139f153de0bcf96ece"
                };
                window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
                .then(responseFacturas => {
                    if (responseFacturas.data.docs.length) {
                        result["total"] = responseFacturas.data.docs.length;
                        var facturas  = responseFacturas.data.docs;
                        for(var k in facturas){
                            if(facturas[k]["estatus"] == "Orden Finalizada"){
                                result["finalizadas"] += 1;
                            }
                        }
                    }   
                    return resolve(result);                             
                })
                .catch(error => {
                    window.dialogLoader.hide();
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al revisar las facturas. Intente nuevamente.",
                        footer: ""
                    });
                    return reject(error);
                }).then(()=>{                    
                    window.dialogLoader.hide();                    
                }); 
            });
        },

        //(28-02-2023): Alvaro solicitó que si cancelan el pedido, también se cancelen las facturas para que ya no salgan en los reportes.
        cancelar_facturas: function(id_pedido){
            window.dialogLoader.show('Espere un momento por favor..');
            return new Promise(async function (resolve, reject) {
                
                var result = {"finalizadas":0, "total":0}
                let data = {
                    selector: {"type":"compras","id_orden_origen":id_pedido},
                    use_index:"_design/a382e7c18fba466ff6baeb139f153de0bcf96ece"
                };
                window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
                .then(async responseFacturas => {
                    if (responseFacturas.data.docs.length) {

                        for(var k in responseFacturas.data.docs){
                            var factura = responseFacturas.data.docs[k];
                            factura["estatus"] = "Orden Cancelada";
                            await window.axios
                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + factura["_id"] + '?conflicts=true', factura)
                                .then(response => {
                                    console.log("Factura cancelada: ", factura["_id"]);
                                })
                                .catch(error => {
                                    console.log(error);                                    
                                });                                                            
                        }
                    }   
                    return resolve(result);                             
                })
                .catch(error => {
                    window.dialogLoader.hide();
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al cancelar las facturas. Intente nuevamente.",
                        footer: ""
                    });
                    return reject(error);
                }).then(()=>{                    
                    window.dialogLoader.hide();                    
                }); 
            });
        },

        changeEstatus: async function(){
            try {
                var self = this;
                this.model_vista.estatus = this.estatus_editable;
                const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
                if (this.model_vista.estatus == "Orden Finalizada"){
                    //revisamos que las facturas hayan sido recibidas(que tengan estatus de Orden finalziada)
                    self.verifica_facturas_finalizadas(self.model_vista._id).then(facturas_finalizadas => {
                        
                        if(facturas_finalizadas["total"]>0 && facturas_finalizadas["finalizadas"] === facturas_finalizadas["total"]){
                            self.$swal({
                                type: "info",
                                title: "Finalizar Orden de Compra",
                                text: "Al finalizar, se entiende que las facturas recibidas completan el pedido. ¿Desea continuar?",
                                footer: "",
                                showCancelButton: true,
                                cancelButtonColor: "#d33",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Si",
                                cancelButtonText: "No"
                            }).then(resultado => {
                                if (resultado.value) {
                                    window.dialogLoader.show('Espere un momento por favor..');
                                    self.model_vista.fecha_finalizada = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                                    window.axios
                                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model_vista._id + '?conflicts=true', self.model_vista)
                                    .then(async response => {
                                        self.model_vista._rev = response.data.rev;                           

                                        self.modalEstatus = false;
                                        self.registros.items = [];
                                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                        if(self.model_vista.fecha_entrega_estimada)
                                            self.getPedidosVencidos();
                                        window.dialogLoader.hide();
                                    })
                                    .catch(error => {
                                        console.log(error);
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                                            footer: ""
                                        });
                                    });
                                } else {
                                    self.modalEstatus = false;
                                    self.registros.items = [];
                                    self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                    window.dialogLoader.hide();
                                }
                            });
                        } else {
                            if(facturas_finalizadas["total"] == 0){
                                self.$swal({
                                    type: "info",
                                    title: "¡Favor de Verificar!",
                                    text: "Se debe registrar al menos 1 factura al pedido.",
                                    footer: "",                            
                                });    
                            } else {
                                self.$swal({
                                    type: "info",
                                    title: "¡Favor de Verificar!",
                                    text: "Verifique que se haya finalizado la entrada de mercancía de todas las facturas registradas.",
                                    footer: "",
                                });
                            }                        
                        }
                    });
                    
                } else {
                    if (this.model_vista.estatus == "Orden Cancelada"){
                        //Revisamos si tiene facturas
                        self.verifica_facturas_finalizadas(self.model_vista._id).then(facturas_finalizadas => {
                            if(facturas_finalizadas["total"]>0 ){
                                this.$swal({
                                    type: "info",
                                    title: "¡CUIDADO!",
                                    text: "La orden tiene facturas registradas. Si ya se recibió mercancía tendrá que hacer los ajustes de inventario de manera manual. Las facturas registradas también se cancelarán. ¿Desea Continuar?",
                                    footer: "",
                                    showCancelButton: true,
                                    cancelButtonColor: "#d33",
                                    confirmButtonColor: "#3085d6",
                                    confirmButtonText: "Si",
                                    cancelButtonText: "No"
                                }).then(resultado => {
                                    if (resultado.value) {
                                        window.dialogLoader.show('Espere un momento por favor..');
                                        window.axios
                                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                                        .then(async response => {
                                            this.model_vista._rev = response.data.rev;
                                            //Cancelamos tambien las facturas que tenga ligadas
                                            this.cancelar_facturas(self.model_vista._id);
                                        })
                                        .catch(error => {
                                            console.log(error);
                                            this.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                                                footer: ""
                                            });
                                        }).then(() => {
                                            this.modalEstatus = false;
                                            this.registros.items = [];
                                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                            window.dialogLoader.hide();
                                            if(this.model_vista.fecha_entrega_estimada)
                                                this.getPedidosVencidos();                                            
                                        });
                                    } else {
                                        this.modalEstatus = false;
                                    }
                                });
                            } else {//Si no tiene factura, se cambia estatus sin preguntar
                                window.dialogLoader.show('Espere un momento por favor..');
                                window.axios
                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                                .then(response => {
                                    this.model_vista._rev = response.data.rev;
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                                        footer: ""
                                    });
                                }).then(() => {
                                    this.modalEstatus = false;
                                    this.registros.items = [];
                                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                    window.dialogLoader.hide();
                                    if(this.model_vista.fecha_entrega_estimada)
                                        this.getPedidosVencidos()                                    
                                });
                            }
                        });
                        
                    } else {
                        window.dialogLoader.show('Espere un momento por favor..');
                        window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                        .then(response => {
                            this.model_vista._rev = response.data.rev;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                                footer: ""
                            });
                        }).then(() => {
                            this.modalEstatus = false;
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            window.dialogLoader.hide();
                            if(this.model_vista.fecha_entrega_estimada)
                                this.getPedidosVencidos();
                            
                        });
                    }
                    
                }

            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
                window.dialogLoader.hide();
            }

        },

        asociar_articulo_proveedor: function(id_articulo, proveedor){
            try {
                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + id_articulo;
                let data = {
                    id_proveedor: proveedor.id_proveedor,
                    nombre_proveedor: proveedor.nombre_proveedor,
                };

                window.axios
                    .post(url, data)
                    .then(response => {
                        if (response.data == true) {
                            //console.log("Articulo: "+id_articulo+" Agregado a proveedor correctamente");                                                
                        } else {
                            //console.log("Error Articulo: "+id_articulo+" no se agregó a proveedor");
                        }
                    }).catch(error => {
                        console.log("Exception Articulo: "+id_articulo+": "+error);
                    });
            } catch (error) {
                console.log("Error en asociar_articulo_proveedor: ", error);
            }
        },

        actualiza_precio_compra_equivalencias:async function(id_articulo, precio_proveedor, iva_padre){
            var busca = encodeURI(escape(id_articulo));
            var self = this;
            await window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
            .then(async responseEquivalencias => {
                if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                    for(var ke in responseEquivalencias.data.rows){
                        var equivalente = responseEquivalencias.data.rows[ke].value;
                        var precio_equivalente = parseFloat(precio_proveedor) / parseFloat(equivalente["cantidad"]);
                        precio_equivalente = parseFloat(precio_equivalente.toFixed(4));
                        var dataEquivalente = {
                            precio_compra: precio_equivalente, //Se manda el precio final del proveedor entre la cantidad equivalente
                            impuesto_iva: parseFloat(iva_padre),
                        };

                        await window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_precio_compra/" + equivalente["id_articulo_equivalente"], 
                                dataEquivalente)
                        .then(async response3 => {
                            if (response3.data == true) {
                                //console.log("Equivalente Actualizado: ",equivalente["nombre_articulo_equivalente"]);
                            }else {
                                console.log("Equivalente No Actualizado: ",equivalente["nombre_articulo_equivalente"]);
                            }
                        }).catch(errorequiv => {
                            console.log("ERROR al actualizar equivalencia: ", errorequiv);
                            console.log("Error al actualizar equivalencia: ",equivalente["nombre_articulo_equivalente"]);
                        });

                        await this.actualiza_precio_compra_equivalencias(equivalente["id_articulo_equivalente"], precio_equivalente, iva_padre);

                    }
                }
            })
            .catch(error => {
                console.log("ERROR al actualizar equivalencia2: ", error);                                                
            });
        },
        vistaPreviaExcel(row) {
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                this.model_vista = row;
                this.sumatoria_pedido = 0;
                if(this.model_vista.articulos.length){
                    this.model_vista.articulos.forEach(e => {
                        try {
                            this.sumatoria_pedido += parseFloat(e.cantidad);
                        } catch (error) {
                            console.log("Error en sumatoria de vistaPreviaExcel", error);
                            this.sumatoria_pedido += 0;
                        }
                        
                    });                    
                }
                this.correo.correos = "";
                if (this.model_vista.proveedor != null)
                    this.correo.correos = this.model_vista.proveedor.email;
                this.correo.responder_a = this.email_usuario;
                this.correo.asunto = "Compra - La Bodeguita";
                this.correo.mensaje = "Solicitud de Cotización de Compra";
                this.modalVistaPrevia = true;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });

            
        },
        cargarExcel() {
            var el = document.getElementById("excel-upload-input");
            el.click();
        },

        descargaExcel() {
            var self = this;
            window.dialogLoader.show('Espere un momento por favor..');

            let datos = {"id_compra":this.model_vista._id,
                          "fecha": window.moment().format("DD-MM-YYYY HH:mm")};
            window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_compra_proveedor/", datos, {            
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                })
                .then(response => {
                var bytes = response.data;
                let blob = new Blob([bytes], {
                    type: response.headers["content-type"]
                    }),
                    url = window.URL.createObjectURL(blob);

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = self.get_nombre_corto_proveedor(self.model_vista.proveedor.nombre_proveedor)+"_"+ self.model_vista.no_orden+"_"+ window.moment().format("DD-MM-YYYY")+".xlsx"                
                a.click();
                a.parentNode.removeChild(a);
                })
                .catch(error => {
                console.log(error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al generar el excel. Intente nuevamente.",
                    footer: ""
                });
                }).then(() => {
                    window.dialogLoader.hide();
                });


            //var wb = XLSX.utils.table_to_book(document.getElementById('orden_excel'));
            //XLSX.writeFile(wb, "OrdenCompra.xlsx");

            //FORMA ANTERIOR
            /*
            var tabla = document.getElementById("orden_excel");

            var options = {
                preserveColors: true,
                exclude: ".noExcel",
                name: "Orden_" + self.model_vista.no_orden,
                sheetName: self.model_vista.no_orden,
                filename: "Orden_" + self.model_vista.no_orden, //do not include extension
                fileext: ".xls", // file extension
                download: 1, //1 - Para que descargue el archivo, 0 - para que regrese el blob
            }
            self.exportExcel(tabla, options);  */

        },
        exportExcel: function (element, options) {
            var settings = options;

            //var utf8Heading = "<meta http-equiv=\"content-type\" content=\"application/vnd.ms-excel; charset=UTF-8\" >";
            var utf8Heading = "<meta http-equiv=\"Content-type\" content=\"text/html;charset=utf-8\" >";
            //var utf8Heading = "<meta http-equiv=\"content-type\" content=\"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8\" >";

            var template = {
                head: "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:x=\"urn:schemas-microsoft-com:office:excel\" xmlns=\"http://www.w3.org/TR/REC-html40\" ><head>" + utf8Heading + "<!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets>",
                sheet: {
                    head: "<x:ExcelWorksheet><x:Name>",
                    tail: "</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>"
                },
                mid: "</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>",
                table: {
                    head: "<table>",
                    tail: "</table>"
                },
                foot: "</body></html>"
            };

            var tableRows = [];
            var keysBorder = [];

            // Styling variables
            var additionalStyles = "";
            var compStyle = null;

            // get contents of table except for exclude
            $(element).each(function (i, o) {
                var tempRows = "";

                $(o).find("table").not(settings.exclude).each(function (i, t) {
                    var tableStyle = getComputedStyle(t);
                    var borderTable = t.border;

                    $(t).find("tr").not(settings.exclude).each(function (i, p) {

                        // Reset for this row
                        additionalStyles = "";

                        // Preserve background and text colors on the row
                        //Se agrega font-weight: bold;
                        if (settings.preserveColors) {
                            compStyle = getComputedStyle(p);
                            additionalStyles += (compStyle && compStyle.backgroundColor ? "background-color: " + compStyle.backgroundColor + ";" : "");
                            additionalStyles += (compStyle && compStyle.color ? "color: " + compStyle.color + ";" : "");
                            additionalStyles += (compStyle && compStyle.fontWeight ? "font-weight: " + compStyle.fontWeight + ";" : "");
                        }

                        // Create HTML for Row
                        tempRows += "<tr style='" + additionalStyles + "'>";

                        // Loop through each TH and TD
                        $(p).find("td,th").not(settings.exclude).each(function (i, q) { // p did not exist, I corrected

                            // Reset for this column
                            additionalStyles = "";

                            // Preserve background and text colors on the row
                            if (settings.preserveColors) {
                                compStyle = getComputedStyle(q);
                                additionalStyles += (compStyle && compStyle.backgroundColor ? "background-color: " + compStyle.backgroundColor + ";" : "");
                                additionalStyles += (compStyle && compStyle.color ? "color: " + compStyle.color + ";" : "");
                                additionalStyles += (compStyle && compStyle.textAlign ? "text-align: " + compStyle.textAlign + ";" : "");
                                additionalStyles += (compStyle && compStyle.fontSize ? "font-size: " + compStyle.fontSize + ";" : "");
                                additionalStyles += (compStyle && compStyle.fontWeight ? "font-weight: " + compStyle.fontWeight + ";" : "");
                                //additionalStyles += (compStyle && compStyle.border ? "border: " + compStyle.border + ";" : "");
                                additionalStyles += (compStyle && borderTable ? "border: thin solid black;" : "");

                                //additionalStyles += (compStyle && compStyle.width ? "width: " + compStyle.width + ";" : "");
                            }

                            var rc = {
                                rows: $(this).attr("rowspan"),
                                cols: $(this).attr("colspan"),
                                flag: $(q).find(settings.exclude)
                            };

                            if (rc.flag.length > 0) {
                                tempRows += "<td> </td>"; // exclude it!!
                            } else {
                                tempRows += "<td";
                                if (rc.rows > 0) {
                                    tempRows += " rowspan='" + rc.rows + "' ";
                                }
                                if (rc.cols > 0) {
                                    tempRows += " colspan='" + rc.cols + "' ";
                                }
                                if (additionalStyles) {
                                    tempRows += " style='" + additionalStyles + "'";
                                }
                                tempRows += " >" + $(q).html() + "</td>";
                            }
                        });

                        tempRows += "</tr>";

                    });
                });

                tableRows.push(tempRows);
            });

            if (options.download == 1)
                this.tableToExcel(tableRows, settings, template, keysBorder);
            else
                return this.tableToExcel(tableRows, settings, template, keysBorder);
        },
        format: function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            });
        },
        tableToExcel: function (table, settings, template, keysBorder) {
            var name = settings.name;
            var sheetName = settings.sheetName;
            var filename = settings.filename;
            var fileext = settings.fileext;
            var fullTemplate = "",
                i, link, a;

            sheetName = typeof sheetName === "undefined" ? "Sheet" : sheetName;

            var ctx = {
                worksheet: name || "Worksheet",
                table: table,
                sheetName: sheetName
            };

            fullTemplate = template.head;

            if ($.isArray(table)) {
                Object.keys(table).forEach(function (i) {
                    //fullTemplate += e.template.sheet.head + "{worksheet" + i + "}" + e.template.sheet.tail;
                    fullTemplate += template.sheet.head + sheetName + i + template.sheet.tail;
                });
            }

            fullTemplate += template.mid;

            if ($.isArray(table)) {
                Object.keys(table).forEach(function (i) {
                    fullTemplate += template.table.head + "{table" + i + "}" + template.table.tail;
                });
            }

            fullTemplate += template.foot;

            for (i in table) {
                ctx["table" + i] = table[i];
            }
            delete ctx.table;

            var isIE = navigator.appVersion.indexOf("MSIE 10") !== -1 || (navigator.userAgent.indexOf("Trident") !== -1 && navigator.userAgent.indexOf("rv:11") !== -1); // this works with IE10 and IE11 both :)
            //if (typeof msie !== "undefined" && msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // this works ONLY with IE 11!!!
            if (isIE) {
                if (typeof Blob !== "undefined") {
                    //use blobs if we can
                    fullTemplate = this.format(fullTemplate, ctx); // with this, works with IE
                    fullTemplate = [fullTemplate];
                    //convert to array
                    var blob1 = new Blob(fullTemplate, {
                        type: "text/html"
                    });
                    window.navigator.msSaveBlob(blob1, filename);
                } else {
                    //otherwise use the iframe and save
                    //requires a blank iframe on page called txtArea1
                    txtArea1.document.open("text/html", "replace");
                    txtArea1.document.write(this.format(fullTemplate, ctx));
                    txtArea1.document.close();
                    txtArea1.focus();
                    sa = txtArea1.document.execCommand("SaveAs", true, filename);
                }

            } else {
                var blob = new Blob([this.format(fullTemplate, ctx)], {
                    type: "application/vnd.ms-excel"
                });
                //var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                if (settings.download == 0) {
                    return blob;
                } else {
                    window.URL = window.URL || window.webkitURL;
                    link = window.URL.createObjectURL(blob);
                    a = document.createElement("a");
                    a.download = filename + fileext;
                    a.href = link;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            }

            return true;
        },

        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (isLt2M) {
                return true;
            }
            this.$swal({
                type: "warning",
                title: "¡Operación no Permitida!",
                text: "El archivo no debe ser mayor a 2MB.",
                footer: ""
            });
            return false;
        },
        esSoloDigitos:function (cadena) {
            const regex = /^\d+$/;
            return regex.test(cadena);
        },
        validaCodigo: function (val) {
            var codigoValidado = val;
            var self = this;
            var filter = this.model_vista.articulos.filter(function (e) {
                for (var k in e.codigo_articulo) {
                    if (e.codigo_articulo[k].toString().toLowerCase().trim() == val.toString().toLowerCase().trim()) {
                        codigoValidado = e.codigo_articulo[k];                        
                        return true;
                    } else if (self.esSoloDigitos(e.codigo_articulo[k]) && self.esSoloDigitos(val) && parseInt(e.codigo_articulo[k]) == parseInt(val)){
                        codigoValidado = e.codigo_articulo[k];                        
                        return true;
                    }
                }
            });
            if (filter.length > 0)
                return codigoValidado.toString();
            else
                return "";
        },

        validaCargaLayout: async function (results) {
            var self = this;
            return new Promise(async function (resolve, reject) {
                var errores = 0;
                var registros = 0;
                var erroresText = '';
                var precios = {};
                var errorTmp = '';
                let usuario = self.$local_storage.get("sb_usuario");
                const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
                const patternEntero = /^[\d]*$/;
                try {

                    if (results.length == 0) {
                        errores++;
                        erroresText += "No hay datos en el excel. Verifique que exista información a partir de la sexta fila ó que no falte ninguna columna.";
                    } else if (results.length > 500) {
                        errores++;
                        erroresText += "El máximo de filas a procesar es 500, favor de verificar.";
                    } else {
                        for (var k in results) {

                            if (results[k]['CÓDIGO BARRAS'] != null && results[k]['$ NUEVO'] != null &&
                                results[k]['CÓDIGO BARRAS'].toString().trim() != "" && results[k]['$ NUEVO'].toString().trim() != "") {

                                registros++;

                                var precioNuevo = results[k]['$ NUEVO'].toString().replace("$", "").replace("$", "").trim();
                                if (!patternFloat.test(precioNuevo) || precioNuevo == "") {
                                    errores++;
                                    errorTmp = '<br/>' + "- El precio " + results[k]['$ NUEVO'] + " no es una cantidad válida.";
                                    if (erroresText.indexOf(errorTmp) == -1)
                                        erroresText += errorTmp;
                                } else
                                    results[k]['$ NUEVO'] = parseFloat(precioNuevo);

                                var valCodigo = self.validaCodigo(results[k]['CÓDIGO BARRAS']);
                                //Valida qué exista el codigo_barras en los articulos de la orden
                                if (valCodigo == "") {
                                    errores++;
                                    errorTmp = '<br/>' + "- El código de barras " + results[k]['CÓDIGO BARRAS'] + " no se encontró en los articulos de la orden.";
                                    if (erroresText.indexOf(errorTmp) == -1)
                                        erroresText += errorTmp;
                                } else {
                                    results[k]['CÓDIGO BARRAS'] = valCodigo;
                                    if (precios[results[k]['CÓDIGO BARRAS']] != null) { //Si ya estaba en la colección (Significa que lo pusieron 2 o más veces en el excel) se actualiza, tomará los datos del último
                                        precios[results[k]['CÓDIGO BARRAS']].precio_proveedor = parseFloat(parseFloat(results[k]['$ NUEVO']).toFixed(4));
                                        if (results[k]['OBS'] != null && results[k]['OBS'].toString().trim() != "")
                                            precios[results[k]['CÓDIGO BARRAS']].observaciones_proveedor = results[k]['OBS'].toString().trim();
                                        else
                                            precios[results[k]['CÓDIGO BARRAS']].observaciones_proveedor = "";
                                    } else {
                                        precios[results[k]['CÓDIGO BARRAS']] = {};
                                        precios[results[k]['CÓDIGO BARRAS']].precio_proveedor = parseFloat(parseFloat(results[k]['$ NUEVO']).toFixed(4));
                                        if (results[k]['OBS'] != null && results[k]['OBS'].toString().trim() != "")
                                            precios[results[k]['CÓDIGO BARRAS']].observaciones_proveedor = results[k]['OBS'].toString().trim();
                                        else
                                            precios[results[k]['CÓDIGO BARRAS']].observaciones_proveedor = "";
                                    }
                                }

                            }

                        }

                    }

                    if (registros == 0) {
                        errores++;
                        erroresText += "No hay datos en el excel. Verifique que exista información a partir de la sexta fila.";
                    }

                    return resolve({
                        "errores": errores,
                        "erroresText": erroresText,
                        "precios": precios,
                    });

                } catch (error) {
                    console.log(error);
                    return reject(error);
                }
            }); //promise
        },
        handleSuccess: async function ({
            results,
            header
        }) {
            var errores = 0;
            var erroresText = '';
            var importData = {};
            try {
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;

                self.validaCargaLayout(results).then(validaciones => {
                    window.dialogLoader.show('Espere un momento por favor..');

                    if (validaciones.errores > 0) {
                        this.$swal({
                            type: "warning",
                            title: "¡Operación no Permitida!",
                            text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                            footer: validaciones.erroresText,
                        });
                        window.dialogLoader.hide();
                    } else {

                        for (var articulok in self.model_vista.articulos) {
                            for (var codigok in self.model_vista.articulos[articulok].codigo_articulo) {
                                var codice = self.model_vista.articulos[articulok].codigo_articulo[codigok].toString();
                                if (validaciones.precios[codice] != null) {
                                    self.model_vista.articulos[articulok].precio_proveedor = parseFloat(validaciones.precios[codice].precio_proveedor);

                                    //Se desglosa el iva para llenar campos
                                    self.model_vista.articulos[articulok].precio_sin_iva = parseFloat((validaciones.precios[codice].precio_proveedor / (1 + (self.model_vista.articulos[articulok].iva/100))).toFixed(4));
                                    self.model_vista.articulos[articulok].precio_proveedor_sin_iva = self.model_vista.articulos[articulok].precio_sin_iva;
                                    self.model_vista.articulos[articulok].precio_con_iva = parseFloat(validaciones.precios[codice].precio_proveedor);
                                    self.model_vista.articulos[articulok].porcentaje_descuento1 = 0;
                                    self.model_vista.articulos[articulok].porcentaje_descuento2 = 0;

                                    self.model_vista.articulos[articulok].observaciones_proveedor = validaciones.precios[codice].observaciones_proveedor;
                                }
                            }

                        }

                        if (["Orden Abierta", "En Proceso de Cotización"].includes(self.model_vista.estatus)) {
                            self.model_vista.estatus = "Cotización Recibida";
                        }

                        window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model_vista._id + '?conflicts=true', self.model_vista)
                            .then(response => {
                                window.dialogLoader.showSnackbar('Precios agregados correctamente', {
                                    color: 'success'
                                });
                                self.model_vista._rev = response.data.rev;
                                self.registros.items = [];
                                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                self.actualizar_precio_proveedor_facturas(self.model_vista);
                            })
                            .catch(error => {
                                self.modalVistaPrevia = false;
                                self.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                    footer: "handleSuccess"
                                });
                            }).then(() => {
                                window.dialogLoader.hide();
                            });

                    }
                }).catch(error => {
                    self.$swal({
                        type: "warning",
                        title: "¡Operación no Permitida!",
                        text: "Error al cargar el archivo. Intente nuevamente.",
                        footer: error,
                    });
                    console.log(error);
                    window.dialogLoader.hide();

                });

            } catch (error) {
                this.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
                    footer: error.message,
                });
                window.dialogLoader.hide();
            }

        },
        modalAdjuntar(row) {
            var self = this;
            self.file_adjunto = null;            
            self.model_adjuntos = [];            
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                self.model_vista = row;
                if (response.data._attachments != null)
                    for (var key in response.data._attachments) {
                        self.model_adjuntos.push({
                            "nombre": key,
                            "_id": response.data._id,
                            "_rev": response.data._rev
                        });
                    }                               
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
                window.dialogLoader.hide();
            }).then(() => {
                window.dialogLoader.hide();
                this.modalAdjuntarDocumentos = true;
            });        

        },

        adjuntarArchivo: function () {

            if (this.$refs.formadjuntos.validate()) {
                window.dialogLoader.show('Espere un momento por favor..');

                var name = encodeURIComponent(this.file_adjunto.name);
                var id_subasta = this.model_vista._id;
                var rev = this.model_vista._rev;

                var url = process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+id_subasta+'/' + name;
                if(rev != ""){ //Si trae rev es que ya se habían adjuntado archivos antes
                    url = process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+id_subasta+'/' + name+"?rev="+rev;
                }

                var self = this;
                window.axios
                    .put(url, self.file_adjunto, {
                        headers: {
                            'Content-Type': self.file_adjunto.type
                        }
                    })
                    .then(response => {
                        window.dialogLoader.showSnackbar('Archivo adjuntado correctamente', {
                            color: 'success'
                        });
                        self.modalAdjuntar(this.model_vista);
                    })
                    .catch(error => {
                        window.dialogLoader.hide();
                        console.log("ERROR ", error);
                        self.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al adjuntar el archivo. Intente nuevamente.",
                            footer: ""
                        });
                    }).then(() => {
                        self.file_adjunto = null;
                    });

            }

        },

        descargarAdjunto: function (row) {
            var name = encodeURIComponent(row.nombre);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+row._id+'/'+name+"?rev="+row._rev;
            a.target = "_blank";
            a.click();
            a.parentNode.removeChild(a);
        },
        eliminarAdjunto: function (row) {
            window.dialogLoader.show('Espere un momento por favor..');
            var name = encodeURIComponent(row.nombre);
            var self = this;

            window.axios
                .delete(process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+row._id+'/'+name+"?rev="+row._rev)
                .then(response => {
                    window.dialogLoader.showSnackbar('Archivo eliminado correctamente', {
                        color: 'success'
                    });
                })
                .catch(error => {
                    self.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al eliminar el archivo. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    self.modalAdjuntar(self.model_vista);
                });
        },
        //CALCULO ORIGINAL
        /*calcular: async function() {

            this.loadingVentas = true;

            let plazo = this.plazo_calculo;

            //await this.get_existencia();

            let group_level = this.periodo == "1" ? "4" : this.periodo == "2" ? "3" : "2";

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
            const [year_de, month_de, day_de] = this.fecha_de_venta.split("-");
            const [year_a, month_a, day_a] = this.fecha_a_venta.split("-");
            let urlConditions = `&startkey=["${this.id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${this.id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

            axios.get(url + urlConditions)
            .then(response => {
                if (response.data.rows.length > 0) {
                    let respuesta = response.data.rows;
                    let procesado = [];

                    //Aqui ya seria agrupar por dia, semana o mes
                    if (this.periodo == "1") {
                        //console.log("busqueda por dias")
                        respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                        });
                    } else if (this.periodo == "2") { //if si es por mes

                        //console.log("busqueda por mes")
                        respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                        });

                    } else { // if si es por año
                        //console.log("busqueda por año");
                        respuesta.forEach(x => {
                        let key = `${x.key[1]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                        });
                    }

                    //llenar los datos de ventas
                    this.total_venta = 0;
                    this.promedio_venta = 0;
                    this.precio_promedio_venta = 0;

                    let sorted = []
                    procesado.forEach(x => {
                        sorted.push(x);
                        this.total_venta += x.cantidad;
                        this.promedio_venta += x.cantidad;
                        this.precio_promedio_venta += x.precio;
                    });

                    let numero_dias_tiempo = new Date(this.fecha_a_venta) - new Date(this.fecha_de_venta);
                    let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);
                    //console.log("DIAS: ", numero_dias);
                    if(numero_dias <= 0)
                        numero_dias = 1;
                    numero_dias += 1; //Se suma 1, por el día que se pierde en la resta

                    this.promedio_venta = this.promedio_venta > 0 ? Math.ceil(this.promedio_venta / numero_dias) : 0;
                    this.precio_promedio_venta = this.precio_promedio_venta > 0 ? this.precio_promedio_venta / procesado.length : 0;

                    //Falta sacar los datos del articulo maximo, minimo, etc
                    if (sorted.length > 0) {
                        sorted.sort((a, b) => {
                        return a.cantidad - b.cantidad;
                        });

                        this.consumo_minimo = this.periodo == "1" ? Math.ceil(sorted[0].cantidad) : (this.periodo == "2" ? Math.ceil(sorted[0].cantidad / 30) : Math.ceil(sorted[0].cantidad / 365));
                        this.consumo_maximo = this.periodo == "1" ? Math.ceil(sorted[sorted.length - 1].cantidad) : (this.periodo == "2" ? Math.ceil(sorted[sorted.length - 1].cantidad / 30) : Math.ceil(sorted[sorted.length - 1].cantidad / 365));
                        this.consumo_medio = this.promedio_venta;

                        this.minimo = this.consumo_minimo * plazo;
                        this.reorden = (this.consumo_medio * plazo) + this.minimo;
                        this.maximo = (this.consumo_maximo * plazo) + this.minimo;

                        this.cantidad_pedir = this.maximo - this.existencia_articulo;

                    }

                    //Poner arreglo en reversa para mostrar la venta mas reciente al inicio
                    this.items_ventas = procesado.reverse();
                } else {
                    this.total_venta = 0;
                    this.promedio_venta = 0;
                    this.precio_promedio_venta = 0;
                    this.minimo = 0;
                    this.reorden = 0;
                    this.maximo = 0;
                    this.consumo_minimo = 0;
                    this.consumo_medio = 0;
                    this.consumo_maximo = 0;
                    this.cantidad_pedir = 0;
                    this.items_ventas = [];
                }
            })
            .catch(error => {
                console.log(error)
            }).then(()=>{
                //Se pone la cantidad de ventas del periodo en la celda donde se selecciono articulo
                /*var clave_fila = "celda_"+this.row_selected.articulo._id+'_'+this.row_selected.proveedor._id;
                var celda_periodo = document.querySelectorAll('.'+clave_fila);
                for(var i = 0; i < celda_periodo.length; i++) {
                    if(celda_periodo[i].className!=undefined && celda_periodo[i].className.indexOf("columnaVentasPeriodo") != -1 ){
                        celda_periodo[i].innerHTML = this.total_venta;
                    }
                }*/
            /*    this.loadingVentas = false;
            });
        },*/
        calcular: async function(fechade=null, fechaa=null) {

            var self = this;
            this.loadingVentas = true;

            this.total_venta = 0;
            this.promedio_venta = 0;
            this.precio_promedio_venta = 0;
            this.minimo = 0;
            this.reorden = 0;
            this.maximo = 0;
            this.consumo_minimo = 0;
            this.consumo_medio = 0;
            this.consumo_maximo = 0;
            this.cantidad_pedir = 0;
            this.items_ventas = [];

            let plazo = parseFloat(this.plazo_calculo);

            //await this.get_existencia();

            let group_level = this.periodo == "1" ? "4" : this.periodo == "2" ? "3" : "2";

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
            var [year_de, month_de, day_de] = this.fecha_de_venta.split("-");
            var [year_a, month_a, day_a] = this.fecha_a_venta.split("-");
            //(23-Sep-2024): Martín solicitó que se queden fijo los 12 meses de compras y ventas, solo cuando se requiere buscar de la fecha de una compra a la fecha se busca por ese rango
            if(fechade != null && fechaa != null){                
                [year_de, month_de, day_de] = fechade.split("-");
                [year_a, month_a, day_a] = fechaa.split("-");
            } 
            
            let urlConditions = `&startkey=["${this.id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${this.id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;
            let procesado = [];
            axios.get(url + urlConditions)
            .then(async response => {
                if (response.data.rows.length > 0) {
                    let respuesta = response.data.rows;                    

                    //Aqui ya seria agrupar por dia, semana o mes
                    if (this.periodo == "1") {
                        //console.log("busqueda por dias")
                        respuesta.forEach(x => {
                            let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                            procesado.push({
                                "fecha": key,
                                "cantidad": x.value.cantidad,
                                "precio": x.value.precio_venta / x.value.cantidad,
                                "total": 0
                            });
                        });
                    } else if (this.periodo == "2") { //if si es por mes

                        //console.log("busqueda por mes")
                        respuesta.forEach(x => {
                            let key = `${x.key[1]}-${x.key[2]}`;

                            procesado.push({
                                "fecha": key,
                                "cantidad": x.value.cantidad,
                                "precio": x.value.precio_venta / x.value.cantidad,
                                "total": 0
                            });
                        });

                    } else { // if si es por año
                        //console.log("busqueda por año");
                        respuesta.forEach(x => {
                            let key = `${x.key[1]}`;

                            procesado.push({
                                "fecha": key,
                                "cantidad": x.value.cantidad,
                                "precio": x.value.precio_venta / x.value.cantidad,
                                "total": 0
                            });
                        });
                    }
                }    
                
                //Obtener articulos equivalentes y obtener sus registros
                await this.get_ventas_articulo(this.id_articulo_calculo).then(function(equivalencias_procesado){
                    procesado = procesado.concat(equivalencias_procesado); 

                    var new_procesado = [];
                    //juntarlo por key
                    for(var k in procesado){
                        var fecha_key = new_procesado.find(e => e.fecha == procesado[k].fecha);
                        if(fecha_key){
                            fecha_key.cantidad += procesado[k].cantidad;
                            fecha_key.precio = (fecha_key.precio + procesado[k].precio)/ 2; //El que ya tenía, mas el de la misma fecha, entre 2, ya no entre cantidad
                        } else {
                            new_procesado.push(procesado[k]);
                        }
                    }

                    new_procesado.sort((a,b) => {
                        if (a.fecha < b.fecha) {
                            return -1;
                        }
                        if (a.fecha > b.fecha) {
                            return 1;
                        }
                        // si son iguales
                        return 0;
                    });


                    //llenar los datos de ventas
                    self.total_venta = 0;
                    self.promedio_venta = 0;
                    self.precio_promedio_venta = 0;

                    let sorted = []
                    new_procesado.forEach(x => {
                        sorted.push(x);
                        self.total_venta += x.cantidad;
                        self.promedio_venta += x.cantidad;
                        self.precio_promedio_venta += x.precio;
                    });

                    let numero_dias_tiempo = new Date(self.fecha_a_venta) - new Date(self.fecha_de_venta);
                    let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);
                    //console.log("DIAS: ", numero_dias);
                    if(numero_dias <= 0)
                        numero_dias = 1;
                    numero_dias += 1; //Se suma 1, por el día que se pierde en la resta

                    self.promedio_venta = self.promedio_venta > 0 ? parseFloat((self.promedio_venta / numero_dias).toFixed(2)) : 0;
                    self.precio_promedio_venta = self.precio_promedio_venta > 0 ? self.precio_promedio_venta / new_procesado.length : 0;

                    //Falta sacar los datos del articulo maximo, minimo, etc
                    if (sorted.length > 0) {
                        sorted.sort((a, b) => {
                        return a.cantidad - b.cantidad;
                        });

                        //CALCULO PILOS
                        /*self.consumo_minimo = self.periodo == "1" ? Math.ceil(sorted[0].cantidad) : (self.periodo == "2" ? Math.ceil(sorted[0].cantidad / 30) : Math.ceil(sorted[0].cantidad / 365));
                        self.consumo_maximo = self.periodo == "1" ? Math.ceil(sorted[sorted.length - 1].cantidad) : (self.periodo == "2" ? Math.ceil(sorted[sorted.length - 1].cantidad / 30) : Math.ceil(sorted[sorted.length - 1].cantidad / 365));
                        self.consumo_medio = self.promedio_venta;

                        self.minimo = self.consumo_minimo * plazo;
                        self.reorden = (self.consumo_medio * plazo) + self.minimo;
                        self.maximo = (self.consumo_maximo * plazo) + self.minimo;

                        self.cantidad_pedir = parseFloat((self.maximo - self.existencia_articulo).toFixed(2));*/

                        //CALCULO ENRIQUE
                        self.minimo = Math.ceil(self.promedio_venta * plazo);
                        self.reorden = Math.ceil((self.promedio_venta * plazo) + self.minimo);
                        self.maximo = Math.ceil((self.promedio_venta * (plazo+30)) + self.minimo); //Suponiendo que quieren surtir para 30 días 

                        if(parseFloat(self.existencia_articulo) >= self.maximo){
                            self.cantidad_pedir = 0;
                        } else {
                            self.cantidad_pedir = parseFloat((self.maximo - self.existencia_articulo).toFixed(2));
                        }                        

                    }
                    //console.log("Finalizando");

                    //Poner arreglo en reversa para mostrar la venta mas reciente al inicio
                    self.items_ventas = new_procesado.reverse();

                });

                /*if(procesado.length == 0){//Si no encontró ventas se deja todo en cero                
                    this.total_venta = 0;
                    this.promedio_venta = 0;
                    this.precio_promedio_venta = 0;
                    this.minimo = 0;
                    this.reorden = 0;
                    this.maximo = 0;
                    this.consumo_minimo = 0;
                    this.consumo_medio = 0;
                    this.consumo_maximo = 0;
                    this.cantidad_pedir = 0;
                    this.items_ventas = [];
                }*/
            })
            .catch(error => {
                console.log(error)
            }).then(()=>{               
               this.loadingVentas = false;
            });
        },

        get_ventas_articulo: function(id_articulo_padre, cantidad_padre = 1){
            var self = this;
            return new Promise(async function (resolve, reject) {
                let procesado = [];
                let group_level = self.periodo == "1" ? "4" : self.periodo == "2" ? "3" : "2";

                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                const [year_de, month_de, day_de] = self.fecha_de_venta.split("-");
                const [year_a, month_a, day_a] = self.fecha_a_venta.split("-");

                var busca = encodeURI(escape(id_articulo_padre));
                
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
                .then(async responseEquivalencias => {
                    if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                        //console.log("Buscando equivalencias artículo: ", id_articulo_padre);
                        for(var ke in responseEquivalencias.data.rows){
                            var equivalente = responseEquivalencias.data.rows[ke].value;
                            
                            var urlConditions = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a}", "${month_a}", "${day_a}"]`;

                            await axios.get(url + urlConditions)
                            .then(async response => {
                                if (response.data.rows.length > 0) {
                                    //console.log("Buscando ventas artículo: ", equivalente['id_articulo_equivalente']);
                                    let respuesta = response.data.rows;                    

                                    //Aqui ya seria agrupar por dia, semana o mes
                                    if (self.periodo == "1") {
                                        //console.log("busqueda por dias")
                                        respuesta.forEach(x => {
                                            let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;
                                            var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                            procesado.push({
                                                "fecha": key,
                                                "cantidad": cantidad_calculada,
                                                "precio": x.value.precio_venta / cantidad_calculada, //Aqui no se divide pues el precio del equivalente es diferente al de la caja, se deja lo que se vendió como su precio
                                                "total": 0
                                            });
                                        });
                                    } else if (self.periodo == "2") { //if si es por mes

                                        //console.log("busqueda por mes")
                                        respuesta.forEach(x => {
                                            let key = `${x.key[1]}-${x.key[2]}`;
                                            var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                            procesado.push({
                                                "fecha": key,
                                                "cantidad": cantidad_calculada,
                                                "precio": x.value.precio_venta / cantidad_calculada,
                                                "total": 0
                                            });
                                        });

                                    } else { // if si es por año
                                        //console.log("busqueda por año");
                                        respuesta.forEach(x => {
                                            let key = `${x.key[1]}`;
                                            var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                            procesado.push({
                                                "fecha": key,
                                                "cantidad": cantidad_calculada,
                                                "precio": x.value.precio_venta / cantidad_calculada,
                                                "total": 0
                                            });
                                        });
                                    }

                                    //Buscamos si el equivalente tiene un subequivalente
                                    await self.get_ventas_articulo(equivalente["id_articulo_equivalente"], equivalente["cantidad"]).then(function(equivalencias_procesado){
                                        procesado = procesado.concat(equivalencias_procesado); 
                                    });                                    
                                    
                                }
                            })
                            .catch(error => {
                                console.log(error);                            
                            });                        

                        }
                        return resolve(procesado);
                    } else {
                        return resolve(procesado);
                    }
                })
                .catch(error => {
                    console.log("ERROR al buscar equivalencia2: ", error);   
                    return resolve(procesado);  
                });
            });
            
        },

        consultar_compras: async function() {
            try {
                this.loadingCompras = true;
                this.items_compras = [];

                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_grupo';
                const [year_de, month_de, day_de] = this.fecha_de_venta.split("-");
                const [year_a, month_a, day_a] = this.fecha_a_venta.split("-");
                let urlConditions = `?startkey=["${this.id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${this.id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

                axios.get(url + urlConditions)
                .then(response => {
                    if (response.data.rows.length > 0) {
                        let respuesta = response.data.rows;
                        let procesado = [];

                        //Aqui ya seria agrupar por dia, semana o mes
                        //console.log("busqueda por dias")
                        respuesta.forEach(x => {
                            let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                            if(x.value.precio_proveedor.toString().trim() != ""){
                                procesado.push({
                                    "fecha": key,
                                    "cantidad": parseFloat(x.value.cantidad),
                                    "existencia": x.value.existencia,
                                    "precio": parseFloat(x.value.precio_proveedor),
                                    "proveedor": x.value.proveedor.nombre_proveedor,
                                    "orden": x.value.no_orden,
                                    "total": 0
                                });
                            }
                        });
                        //console.log(procesado);

                        // Sacar los datos de la parte de abajo
                        this.total_compra = 0;
                        this.promedio_compra = 0;
                        this.precio_promedio_compra = 0;

                        procesado.forEach(x => {
                            this.total_compra += parseFloat(x.cantidad);
                            this.promedio_compra += parseFloat(x.cantidad);
                            this.precio_promedio_compra += parseFloat(x.precio);
                        });
                        
                        let numero_dias_tiempo = new Date(this.fecha_a_venta) - new Date(this.fecha_de_venta);
                        let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);

                        this.promedio_compra = this.promedio_compra > 0 ? Math.ceil(this.promedio_compra / numero_dias) : 0;
                        this.precio_promedio_compra = this.precio_promedio_compra > 0 ? this.precio_promedio_compra / procesado.length : 0;
                        

                        //Poner arreglo en reversa para mostrar la compra mas reciente al inicio
                        this.items_compras = procesado.reverse();
                    } else {
                        this.total_compra = 0;
                        this.promedio_compra = 0;
                        this.precio_promedio_compra = 0;
                        this.items_compras = [];
                    }
                })
                .catch(error => {
                    this.items_compras = [];
                    console.log(error)
                }).then(()=>{
                    this.loadingCompras = false;
                });
            } catch (error) {
                console.log("Error al consultar compras: ", error);
            }
        },

        calcular_fecha: function(fecha) {
            var fecha_de_venta = moment(String(fecha)).format("YYYY-MM-DD");
            var fecha_a_venta = moment().format("YYYY-MM-DD");

            this.calcular(fecha_de_venta, fecha_a_venta);
        },
        getNombreSucursal: function(id_sucursal) {
            var filter = this.sucursales.find(e => e._id == id_sucursal);
            if (filter)
                return filter.nombre;
            else
                return "";
        },
        get_sucursales: function () {
            window.dialogLoader.show('Espere un momento por favor..');
            let data = {
                "selector": {
                    "nombre":{"$exists":true}
                },
                "fields": [
                    "nombre", "_id","abastos","matriz"
                ],
                "use_index": "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    this.sucursales = [];
                    this.sucursales_tipo = [];
                    this.suc_abastos = "";
                    this.suc_matriz = "";
                    if (response.data.docs.length > 0){
                        this.sucursales = response.data.docs;
                        for(var k in this.sucursales){
                            if(this.sucursales[k].abastos == 1){
                                this.suc_abastos = this.sucursales[k];
                                this.sucursales_tipo.push(this.sucursales[k]);
                            }
                            if(this.sucursales[k].matriz == 1){
                                this.suc_matriz = this.sucursales[k];
                                this.sucursales_tipo.push(this.sucursales[k]);
                                this.sucursal_seleccionada = this.suc_matriz;
                            }
                        }

                    }

                    if(this.suc_abastos == ""){
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "No se ha configurado en Sucursales la sucursal Abastos. Es necesario para el módulo de compras.",
                            footer: ""
                        }).then(()=>{
                            this.goHome();
                        });
                    }
                    if(this.suc_matriz == ""){
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "No se ha configurado en Sucursales la sucursal Matriz. Es necesario para el módulo de compras.",
                            footer: ""
                        }).then(()=>{
                            this.goHome();
                        });
                    }
                })

                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });
                }).then(()=>{
                    window.dialogLoader.hide();
                });
        },
        getImpuestoIVA: function(){
            let data = {
                "selector": {
                "type": "configuracion"
                },
                "fields": ["_id", "impuestos"]
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    this.TASA_IVA = 16;  //Por default

                    if (response.data.docs.length > 0){
                        var records = response.data.docs[0];
                        for(var k in records.impuestos){
                            if(records.impuestos[k].clave == "002" && parseFloat(records.impuestos[k].tasa)>0){ //Clave IVA 002
                                this.TASA_IVA = parseFloat(records.impuestos[k].tasa);
                            }
                        }

                    }
                }).catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener el impuesto IVA.",
                        footer: ""
                    });
                });
        },
        get_existencia: async function() {
            let n = 0;
            var self = this;
            self.existencia_matriz = 0;
            await window.funciones_lotes.consultaExistencia({
                "id_articulo": this.id_articulo_calculo
            }).then(result => {
                if (result.length > 0) {

                    result.forEach(el => {
                        n += el.existencia;
                        el["sucursal"] = self.getNombreSucursal(el.id_sucursal);
                        if(el.id_sucursal == self.suc_matriz._id){
                            self.existencia_matriz += el.existencia;
                        }
                    });
                    this.articulo_existencias = result;
                    this.existencia_articulo = parseFloat(parseFloat(n).toFixed(2));
                } else {
                    this.articulo_existencias = [];
                }
                //console.log("se asigno la existencia");

            }).catch(err => {
                console.log("error", err);
                this.existencia_articulo = "Error";
                this.articulo_existencias = [];
            });
        },
    asignar_articulo_proveedor: function(row) {
        let existe = this.articulos_proveedor.filter(x => x._id == row._id);

        if(existe.length <= 0) {

          window.dialogLoader.show('Espere un momento por favor..');

          let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + row._id;
          let data = {
            id_proveedor: this.id_proveedor,
            nombre_proveedor: this.nombre_proveedor_seleccionado,
          };

          window.axios
            .post(url, data)
            .then(response => {
              if (response.data == true) {
                  window.dialogLoader.showSnackbar('El artículo se asignó correctamente..', {
                    color: 'success'
                  });
                                
                this.articulos_proveedor.push(row);
                if(this.modalFacturaDirecta == true){//Si es directa es que se está agregando artículo a proveedor desde la modal de facturas
                    this.get_articulos_proveedor();
                } else {//Sino, se esta agregando desde la modal de crear orden                    
                    this.consultarProveedor_combo();
                }

              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: response.data,
                  footer: ""
                });
              }
            })
            .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al agregar el artículo. Intente nuevamente",
                  footer: error
                });
            }).then( ()=> {
              window.dialogLoader.hide();
            });

        }
    },
    remover_articulo_proveedor: function(row) {

      window.dialogLoader.show('Espere un momento por favor..');

      let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/delete_proveedor/" + row._id;
      let data = {
        id_proveedor: this.id_proveedor,
        nombre_proveedor: this.nombre_proveedor_seleccionado,
      };

      window.axios
        .post(url, data)
        .then(response => {
          if (response.data == true) {
                window.dialogLoader.showSnackbar('El artículo se removió correctamente..', {
                    color: 'success'
                });
                var new_arti = this.articulos_proveedor.filter(x => x._id != row._id);
                    this.articulos_proveedor = new_arti;  
                if(this.modalFacturaDirecta == true){//Si es directa es que se está agregando artículo a proveedor desde la modal de facturas
                    this.get_articulos_proveedor(); //Actualizamos los artículos en la vista de facturas
                } else {//Sino, se esta agregando desde la modal de crear orden, actualizamos articulos del proveedor en crear orden                    
                    this.consultarProveedor_combo(); 
                }          
          } else {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: response.data,
              footer: ""
            });
          }
        })
        .catch(error => {
          this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al remover el artículo. Intente nuevamente",
              footer: ""
            });
        }).then( ()=> {
          window.dialogLoader.hide();                    
        });

    },

    agregar_articulos_pedido_orden_normal: function(opcion=0){ //Opcion 0-Agregar Todos, 1-Agregar solo articulos restantes
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        var articulos_pedido = [];
        //Traemos el mode_vista actualizado
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
        .then(response => {
            this.model_vista = response.data;

            for(var k in this.model_vista.articulos){
                if(opcion == 1 && this.articulos_facturas_previas.includes(this.model_vista.articulos[k]["id_articulo"]))//Si el artículo ya está en otras facturas, ya no se agrega, solo si Opcion = 1
                    continue;
                    
                this.model_vista.articulos[k]["agregado_a_factura"] = 1;
                var copy_row = JSON.parse(JSON.stringify(this.model_vista.articulos[k]));
                copy_row["precio_factura"] = ""; //Cuando es por pedido(no directa), el precio_proveedor es el del pedido, no el de la factura, por eso se guarda aparte
                copy_row["precio_factura_sin_iva"] = "";
                articulos_pedido.push(copy_row);           
            }

            if(articulos_pedido.length > 0) {
                this.model_vista_factura.articulos = articulos_pedido;

                if(this.model_vista_factura._id && this.model_vista_factura._id!=undefined && this.model_vista_factura._id!="undefined" && this.model_vista_factura._rev){
                    window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)                        
                    .then(response => {
                        this.model_vista_factura._rev = response.data.rev;
                        window.dialogLoader.showSnackbar('Los artículos se agregaron correctamente..', {
                            color: 'success'
                        });                                
                        this.update_arreglo_facturas();
                    }).catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al agregar los artículos. Intente nuevamente.",
                            footer: ""
                        });
                    }).then(() => {
                        window.dialogLoader.hide();                        
                    });
                } else {
                    //calcular no_orden con secuencia de suborden
                    var model_copy = JSON.parse(JSON.stringify(this.model_vista));
                    var secuencia_factura = this.get_numero_suborden(model_copy);
                    this.model_vista_factura.secuencia = secuencia_factura;
                    this.model_vista_factura.no_orden = model_copy.no_orden +"S"+ secuencia_factura.toString();
                    window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model_vista_factura)
                    .then(response => {
                        this.model_vista_factura["_rev"] = response.data.rev;
                        this.model_vista_factura["_id"] = response.data.id;
                        window.dialogLoader.showSnackbar('Los artículos se agregaron correctamente..', {
                            color: 'success'
                        });                                
                        this.update_arreglo_facturas();
                    }).catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al agregar los artículos. Intente nuevamente.",
                            footer: ""
                        });
                    }).then(() => {
                        window.dialogLoader.hide();                    
                    });
                }
            } else {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "No se han agregado artículos a la factura. Favor de verificar.",
                    footer: opcion==1?"No hay artículos restantes.":""
                });
                window.dialogLoader.hide();
            }
            
                
            
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        });              
    },

    agregar_articulos_proveedor_orden_directa:async function(){
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        var articulos_proveedor = [];
        for(var k in this.articulos_proveedor_seleccionado_directo){
            this.articulos_proveedor_seleccionado_directo[k]["agregado_a_factura"] = 1;
            var articulo = JSON.parse(JSON.stringify(this.articulos_proveedor_seleccionado_directo[k]));
            await window.funciones_lotes.consultaExistenciaTotal({
                "id_articulo": articulo._id
            })
            .then(async responseExistencia => {
                var copy_row = {
                    id_articulo: articulo._id,
                    nombre_articulo: articulo.nombre,
                    descripcion: articulo.descripcion,
                    codigo_articulo: articulo.codigo_barras,
                    impuestos_articulo: articulo.impuestos,
                    cantidad: 1,
                    precio_compra: parseFloat(articulo.precio_compra), //Último precio de compra
                    precio_compra_con_iva: parseFloat(articulo.precio_compra_con_iva), //Último precio de compra con iva
                    precio_sin_iva:"",
                    iva: self.getSumaImpuestos(articulo.impuestos), //Ahora guarda la suma de impuestos del articulo
                    precio_con_iva:"",
                    porcentaje_descuento1:"",
                    porcentaje_descuento2:"",
                    precio_factura:"", //Cuando es directa, el precio_factura se debe copiar al precio_proveedor
                    precio_factura_sin_iva:"",
                    precio_proveedor: "",
                    precio_proveedor_sin_iva: "",
                    existencia_articulo: parseFloat(parseFloat(responseExistencia).toFixed(2)),
                    observaciones_proveedor: "",
                    estatus: "Pendiente",
                    cantidad_recibida: 0,
                    cantidad_faltante: 0,
                    cantidad_devuelta: 0,
                    lotes_destino: [],
                    resaltado: 0,
                    sucursal_recibio: "",
                    usuario_recibio: "",
                    fecha_recibio: "",
                };   
                articulos_proveedor.push(copy_row);             
            }).catch(err => {
                console.log("Error ", err);
                self.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                    footer: ""
                });
            });
        }

        this.model_vista_factura.articulos = articulos_proveedor;
        //Traemos el mode_vista actualizado
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
        .then(response => {
            this.model_vista = response.data;

            if(this.model_vista_factura._id && this.model_vista_factura._id!=undefined && this.model_vista_factura._id!="undefined" && this.model_vista_factura._rev){
                window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)                        
                .then(response => {
                    this.model_vista_factura._rev = response.data.rev;
                    window.dialogLoader.showSnackbar('Los artículos se agregaron correctamente..', {
                        color: 'success'
                    });                                
                    this.update_arreglo_facturas();
                }).catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al agregar el artículo. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    window.dialogLoader.hide();                        
                });
            } else {
                //calcular no_orden con secuencia de suborden
                var model_copy = JSON.parse(JSON.stringify(this.model_vista));
                var secuencia_factura = this.get_numero_suborden(model_copy);
                this.model_vista_factura.secuencia = secuencia_factura;
                this.model_vista_factura.no_orden = model_copy.no_orden +"S"+ secuencia_factura.toString();
                window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model_vista_factura)
                .then(response => {
                    this.model_vista_factura["_rev"] = response.data.rev;
                    this.model_vista_factura["_id"] = response.data.id;
                    window.dialogLoader.showSnackbar('Los artículos se agregaron correctamente..', {
                        color: 'success'
                    });                                
                    this.update_arreglo_facturas();
                }).catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al agregar los artículos. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    window.dialogLoader.hide();                    
                });
            }
            
                
            
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        });
        
    },

    //Se utiliza la misma función tanto si es directa o normal
    remover_articulos_orden_directa: function(){
        if(this.model_vista_factura._id && this.model_vista_factura._rev){
            this.model_vista_factura.articulos = [];
            window.dialogLoader.show('Espere un momento por favor..');

            //Traemos el mode_vista actualizado
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista._id)
            .then(response => {
                this.model_vista = response.data;
                
                    window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)                        
                    .then(response => {
                        this.model_vista_factura._rev = response.data.rev;
                        window.dialogLoader.showSnackbar('Los artículos se eliminaron correctamente..', {
                            color: 'success'
                        });                                
                        this.update_arreglo_facturas();
                    }).catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                            footer: ""
                        });
                    }).then(() => {
                        this.update_colors_lista_izquierda();
                        window.dialogLoader.hide();                        
                    });
                
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });                
                window.dialogLoader.hide();
            });
        }        

    },

    finalizar_entrada_mercancia: async function(){        
        if (!this.guardando) {                   

            var articulos_sin_precio = this.model_vista_factura.articulos.reduce(function(total, a){
            //console.log("Articulo: ", a);
            //console.log("Precio: ", a.precio_proveedor);
            //console.log("Condicion: ", ((a.precio_proveedor && a.precio_proveedor!="" && parseFloat(a.precio_proveedor)>0) ? 0 : 1 ));
                return total + ((a.precio_proveedor && a.precio_proveedor!="" && parseFloat(a.precio_proveedor)>0) ? 0 : 1 );
            }, 0);                    

            if(articulos_sin_precio > 0){
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Hay artículos sin precio en la factura. Revise e intente nuevamente.",
                    footer: ""
                });
            }else {
                this.$swal({
                        type: "info",
                        title: "Cuidado",
                        text: "Se actualizará el inventario y el precio de compra de cada uno de los artículos. ¿Desea continuar?",
                        footer: "",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Si",
                        cancelButtonText: "No"
                    }).then(async resultado => {
                        if (resultado.value) {
                            
                                window.dialogLoader.show('Espere un momento por favor..');                                
                                this.guardando = true; // Deshabilitar el botón

                                var errores = [];
                                var bulkDestino = [];          
                                var self = this;

                                await Promise.all(self.model_vista_factura.articulos.map(async (art) => { 

                                    // Verificar si el artículo ya existe en bulkDestino
                                    const existsInBulk = bulkDestino.some(destino => destino.id_articulo === art.id_articulo);
                                    
                                    // Si no existe, agregar el artículo
                                    if (!existsInBulk) {
                                    
                                        if (art.estatus == "Pendiente" && parseFloat(art.cantidad) > 0 ) { //Pendiente, Recibido
                                            var dataDestino = {
                                                id_sucursal: self.model_vista_factura.id_sucursal_destino, //Requerido
                                                id_articulo: art.id_articulo, //Requerido
                                                id_compra: self.model_vista_factura._id,
                                                cantidad: parseFloat(art.cantidad), //Requerido positivo
                                                precio_compra: parseFloat(art.precio_proveedor), //Requerido
                                                fecha_caducidad: art.fecha_caducidad?art.fecha_caducidad:"", //Opcional
                                                fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"), //Requerido
                                                origen: "compra",
                                                usuario: this.$local_storage.get("sb_usuario"),
                                            };

                                            var copyData = JSON.parse(JSON.stringify(dataDestino));
                                            bulkDestino.push(copyData);
                                        }
                                    }

                                    //(20-02-2024): Martín solicito actualizar la existencia al momento de darle entrada a la factura
                                    await window.funciones_lotes.consultaExistenciaTotal({
                                        "id_articulo": art.id_articulo
                                    })
                                    .then(responseExistencia => {                                                
                                        art.existencia_articulo = parseFloat(parseFloat(responseExistencia).toFixed(2));
                                    });

                                }));

                                //Afectar el inventario destino
                                for (var k in bulkDestino) {
                                    await window.funciones_lotes.nuevaEntradaInventario(bulkDestino[k]).then(result => {

                                         // Busca el artículo correspondiente
                                        let art = self.model_vista_factura.articulos.find(art => art.id_articulo === bulkDestino[k].id_articulo);

                                        // Si se encuentra el artículo y no está ya marcado como "Recibido"
                                        if (art && art.estatus !== "Recibido") {
                                            // Actualizamos los campos solo si el artículo no ha sido marcado como "Recibido"
                                            art.estatus = "Recibido";
                                            art.cantidad_recibida = art.cantidad;
                                            art.fecha_recibio = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                                            art.sucursal_recibio = self.model_vista_factura.nombre_sucursal_destino;
                                            art.usuario_recibio = self.$local_storage.get("sb_usuario");

                                            // Manejar los lotes destino, evitando duplicidades
                                            if (!art.lotes_destino || art.lotes_destino.length === 0) {
                                                art.lotes_destino = result;
                                            } else {
                                                art.lotes_destino = art.lotes_destino.concat(result);
                                            }
                                        }                                        

                                    }).catch(err => {
                                        errores.push(err);
                                    });
                                }

                                if (errores.length == 0) {
                                    //Guardar model y poner estatus en "Orden Finalizada" ya que ahora se maneja como factura, si ya se recibió la factura se finaliza
                                    this.model_vista_factura.estatus = "Orden Finalizada";
                                    this.model_vista_factura.fecha_recibio_mercancia = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                                    this.model_vista_factura.fecha_finalizada = this.model_vista_factura.fecha_recibio_mercancia;
                                    this.model_vista_factura.usuario_entrada = this.$local_storage.get("sb_usuario");
                                    
                                    window.axios
                                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
                                    .then(async response => {
                                        this.model_vista_factura._rev = response.data.rev;
                                        await window.funciones_lotes.actualizar_precio_compra_articulos(this.model_vista_factura._id);//Actualizar precios de compra en catalogo de articulos                                            

                                        var segundos = self.model_vista_factura.articulos.length * 800;
                                        setTimeout(() => { //Para dar tiempo a que actualice precios de articulos
                                            window.dialogLoader.hide();
                                            self.modalArticulosFactura = false;
                                            self.modalCaducidadArticulosFactura = false;
                                            self.showFacturas(self.model_vista);
                                            window.dialogLoader.showSnackbar('El proceso finalizó correctamente... ', {
                                                color: 'success'
                                            });
                                        }, segundos);
                                        
                                        //Actualizamos el pedido origen con estatus de proceso de entrada
                                        window.axios
                                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_vista_factura.id_orden_origen)
                                        .then(responseOrigen => {
                                            var model_pedido = responseOrigen.data;
                                            model_pedido.estatus = "En Proceso de Entrada";
                                            window.axios
                                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + model_pedido._id + '?conflicts=true', model_pedido)
                                            .then(responsea => {
                                            //Orden actualziada
                                            }).catch(errora => {
                                            console.log("Error al actualizar orden origen: ", errora);
                                            });
                                        
                                        }).catch(error => {
                                            console.log("Error al obtener orden origen: ", error);
                                        });

                                    }).catch(error => {
                                        window.dialogLoader.hide();
                                        console.log("Error1: ", error);
                                        this.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al finalizar entrada de mercancía. Por favor intente nuevamente.",
                                            footer: ""
                                        });
                                    }).then(() => {
                                        this.guardando = false;                                        
                                    });
                                } else {

                                    //Guardamos cambios en el modelo para guardar los estatus de los articulos que si se hayan afectado correctamente
                                    window.axios
                                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
                                    .then(response => {
                                        this.model_vista_factura._rev = response.data.rev;
                                    })
                                    .catch(error => {
                                        console.log("Error2", error);
                                    }).then(() => {
                                        this.guardando = false;
                                        console.log("Error3: ");
                                        window.dialogLoader.hide();
                                        this.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al finalizar entrada de mercancía. Intente nuevamente.",
                                            footer: ""
                                        });
                                    });

                                }
                            
                        }
                    });
            }
        }          
    },
    showModalCaducidades: function(){
        window.dialogLoader.show('Espere un momento por favor..');

        //checar si los articulos tiene caducidad, si no tiene se agrega y al final se guarda
        var agregados = 0;
        for(var k in this.model_vista_factura.articulos){
            var art = this.model_vista_factura.articulos[k];
            if(!art.hasOwnProperty('caducidad')){                
                var art_find = this.articulos.find(a => a._id == art.id_articulo)
                if(art_find){
                    art.caducidad = art_find.caducidad;
                    art.fecha_caducidad = "";
                } else {
                    console.log("No encontrado");
                    console.log(art);
                }
                agregados += 1; 
            }
        }

        if(agregados > 0){
            window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
            .then(response => {
                this.model_vista_factura._rev = response.data.rev;
                this.filters = {};
                this.modalCaducidadArticulosFactura=true;
                this.keyTableCaducidadArticulosFactura = moment(new Date()).format("x"); //Refrescar tabla
            })
            .catch(error => {
                console.log("Error2", error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al mostrar los artículos. Intente nuevamente.",
                    footer: ""
                });
            }).then(() => {
                window.dialogLoader.hide();                
            });
        } else {
            window.dialogLoader.hide();
            this.modalCaducidadArticulosFactura=true;
            this.$nextTick(() => {
                this.$refs.vuetablearticuloscaducidad.resetQuery();
            });            
        }

        
    },
    parseDate(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    showModalFechaCaducidad: function(row){
        this.articulo_editable = row;
        if(row.fecha_caducidad != null && row.fecha_caducidad != undefined && row.fecha_caducidad != ""){
            this.articulo_editable.fecha_caducidad = this.parseDate(row.fecha_caducidad);
        }
        this.modalfechacaducidad=true;
    },
    saveCaducidadArticulo: function(row){
        console.log(row.caducidad);
        //funcion en couch que edite la caducidad del articulo, luego se guarda el model_vista_factura
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_caducidad/" + row.id_articulo;
        let data = { caducidad: row.caducidad };

        window.axios
            .post(url, data)
            .then(response => {
                if (response.data == true) {
                    //console.log("Articulo: "+id_articulo+" Caducidad actualizada correctamente");                                                
                } else {
                    //console.log("Error Articulo: "+id_articulo+" no se actualizó caducidad");
                }

            }).catch(error => {
                console.log("Exception Articulo: "+error);                
            }).then(()=>{

                //Lo actualizamos en el arreglo de articulos
                var art_arr = this.articulos.find(a => a._id == row.id_articulo)
                if(art_arr){
                    art_arr.caducidad = row.caducidad;
                }

                var art_find = this.model_vista_factura.articulos.find(a => a.id_articulo == row.id_articulo)
                if(art_find){
                    art_find.caducidad = row.caducidad;                    
                } 
                        
                window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
                .then(response => {
                    this.model_vista_factura._rev = response.data.rev;
                    this.modalfechacaducidad = false;
                })
                .catch(error => {
                    console.log("Error2", error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    this.keyTableCaducidadArticulosFactura = moment(new Date()).format("x"); //Refrescar tabla
                    window.dialogLoader.hide();                
                });
            });                
    },
    saveFechaCaducidadArticulo: function(){
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        
        var art_find = this.model_vista_factura.articulos.find(a => a.id_articulo == self.articulo_editable.id_articulo)
        if(art_find){
            if(self.articulo_editable.fecha_caducidad != null && self.articulo_editable.fecha_caducidad != undefined && self.articulo_editable.fecha_caducidad != ""){
                art_find.fecha_caducidad = window.moment(self.articulo_editable.fecha_caducidad).format("YYYY-MM-DDTHH:mm:ss");
            } else {
                art_find.fecha_caducidad = "";
            }
            
        } else {
            console.log("No encontrado");
            console.log(self.articulo_editable);
        }
                
        window.axios
        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista_factura._id + '?conflicts=true', this.model_vista_factura)
        .then(async response => {
            this.model_vista_factura._rev = response.data.rev;
            this.modalfechacaducidad = false;

            //Si tiene lotes destino el articulo, se modifica su fecha caducidad
            if(art_find && art_find.lotes_destino && art_find.lotes_destino.length>0){
                for(var kl in art_find.lotes_destino){
                    var lote_id = art_find.lotes_destino[kl]['lote'];
                    
                    let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_lotes/_update/actualizar_fecha_caducidad/" + lote_id;
                    let data = { fecha_caducidad: art_find.fecha_caducidad };

                    await window.axios
                        .post(url, data)
                        .then(responseLote => {
                            if (responseLote.data == true) {
                                //console.log("Lote: "+lote_id+" Caducidad actualizada correctamente");                                                
                            } else {
                                //console.log("Error Lote: "+lote_id+" no se actualizó caducidad");
                            }

                        }).catch(error => {
                            console.log("Exception al actualizar fecha de caducidad en lote: "+error);                            
                        })
                }
            }
            window.dialogLoader.hide();
            
        })
        .catch(error => {
            console.log("Error2", error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar la fecha. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        }).then(() => {
            this.keyTableCaducidadArticulosFactura = moment(new Date()).format("x"); //Refrescar tabla
            this.$nextTick(() => {
                this.$refs.vuetablearticuloscaducidad.setFilter(this.filters); //Regresar filtros
            });
        });
        
    },

    cambiarProveedor(row) {
        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {
            row = response.data;
            this.model_vista = row;
            this.proveedor_editable = row.proveedor.id_proveedor;
            this.modalCambioProveedor = true;
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });
        
    },


    changeProveedor() {
        try {
            var prove = this.getProveedor(this.proveedor_editable);
            if(prove !== false){
                var newprove = {
                    "id_proveedor": prove._id,
                    "nombre_proveedor": prove.nombre,
                    "rfc": prove.rfc,
                    "plazo": prove.plazo,
                    "contacto": prove.contacto,
                    "email": prove.email,
                    "telefono_1": prove.telefono_1
                }                                                      
                    this.$swal({
                        type: "info",
                        title: "Actualizar Proveedor",
                        text: "El proveedor del pedido y sus facturas se cambiarán al proveedor seleccionado: "+newprove.nombre_proveedor+" ¿Desea Continuar?",
                        footer: "",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Si",
                        cancelButtonText: "No"
                    }).then(async resultado => {
                        if (resultado.value) {
                            window.dialogLoader.show('Espere un momento por favor..');
                            var self = this;
                            this.model_vista.proveedor = newprove;
                            
                            await window.axios
                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                            .then(async response=> {
                                this.model_vista._rev = response.data.rev;

                                //Cambiar el proveedor en las facturas, si es que tiene
                                if(this.model_vista.facturas && this.model_vista.facturas.length > 0){
                                    for(var k in this.model_vista.facturas){
                                        var factura = this.model_vista.facturas[k];
                                        await window.axios
                                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + factura.id)
                                        .then(async responseFactura => {
                                            var factura_model = responseFactura.data;
                                            factura_model.proveedor = newprove;
                                            await window.axios
                                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + factura_model._id + '?conflicts=true', factura_model)
                                            .then(responseF=> {

                                            }).catch(error => {
                                                this.$swal({
                                                    type: "error",
                                                    title: "¡Operación no Permitida!",
                                                    text: "Ocurrió un error al editar el Proveedor de la factura. Intente nuevamente.",
                                                    footer: ""
                                                });
                                            });
                                            
                                        })
                                        .catch(error => {
                                            this.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al obtener datos de la factura. Intente nuevamente.",
                                                footer: ""
                                            });
                                        });
                                    } 
                                }
                                                                
                            }).catch(error => {
                                console.log(error);
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el Proveedor. Intente nuevamente.",
                                    footer: ""
                                });                                
                            }).then(()=>{
                                this.modalCambioProveedor = false;
                                window.dialogLoader.hide();
                                this.registros.items = [];
                                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            });
                        } else {
                            this.modalCambioProveedor = false;
                            window.dialogLoader.hide();
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');                       
                        }
                    });

            }
            
            
            

        } catch (error) {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                footer: ""
            });
            this.modalCambioProveedor = false;
            window.dialogLoader.hide();
            console.log(error);
            window.dialogLoader.hide();
        }

    },
    selectRow(row) {          
        this.selectedRowIndex = row.row._id;          
    },
    getRowClass(row) {          
        return this.selectedRowIndex === row._id ? 'selected-row' : '';
    }, 

    getComprasPeriodo: async function() {
        try {
            var self = this;            
            this.compras_articulos = {};
            
            var fecha = window.moment().subtract(30, 'days').format("YYYY-MM-DD");
            var where = {
                "selector": {
                    "type": "pedidos",
                    "estatus":{"$exists": true },
                    "fecha": {"$gte": fecha } 
                },
                "use_index": "_design/060eba6482245aa90249dd488f5ee4f0"
            };

            //PEDIDOS
            await window.axios({
                method: 'POST',
                url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
                withCredentials: true,
                data: where,
            }).then(async response => {
                if (response.data.docs.length) {
                    var docs = response.data.docs;
                    docs.map(function(compra){
                        if(compra["estatus"] != "Orden Cancelada"){                            
                            compra["articulos"].map(function(art){
                                if(!self.compras_articulos[art["id_articulo"]]){                                
                                    self.compras_articulos[art["id_articulo"]] = [];                                
                                }
                                var record = {"id":compra["_id"],"type":"pedidos","no_orden":compra["no_orden"], "observaciones_orden":compra["observaciones_orden"],"observaciones_entrada":compra["observaciones_entrada"],
                                    "estatus":compra["estatus"], "fecha":compra["fecha"], "sucursal":compra["nombre_sucursal_destino"], "proveedor":compra["proveedor"]["nombre_proveedor"],
                                    "articulo":art["nombre_articulo"],"descripcion":art["descripcion"], "cantidad":parseFloat(art["cantidad"]), "cantidad_recibida":parseFloat(art["cantidad_recibida"]),
                                    "existencia":art["existencia_articulo"], "fecha_recibio":compra["fecha_finalizada"],
                                    "fecha_estimada":compra["fecha_entrega_estimada"]
                                }
                                self.compras_articulos[art["id_articulo"]].push(record);
                                self.compras_articulos[art["id_articulo"]].sort(function (a, b) {
                                    //Ordenar por fecha más reciente
                                    return new Date(b.fecha) - new Date(a.fecha);
                                });
                            }); 
                        }
                        
                        
                    });
                    

                        let data = {
                            selector: {"type":"compras","fecha": {"$gte": fecha }, "estatus":{"$exists":true}},
                            use_index:"_design/fa6a4280324d329a631ce3ae76a2682d6b44d421"
                        };                        
                        await window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
                        .then(responseFacturas => {

                            if (responseFacturas.data.docs.length) {
                                var facturas = responseFacturas.data.docs;
                                facturas.map(function(factura){

                                    if(factura["estatus"] != "Orden Cancelada"){
                                        factura["articulos"].map(function(art){
                                            var encontrado = 0;
                                            if(!self.compras_articulos[art["id_articulo"]]){
                                                self.compras_articulos[art["id_articulo"]] = [];                                
                                            } else {
                                                //Buscar si tiene pedido, si tiene sumar cantidad_recibida y actualizar fecha_recibió solamenbte                                            
                                                self.compras_articulos[art["id_articulo"]].map(function(comp){
                                                    if(comp["type"] == "pedidos" && factura["id_orden_origen"] == comp["id"]){
                                                        encontrado = 1;
                                                        comp["cantidad_recibida"] += parseFloat(art["cantidad_recibida"]);
                                                        comp["fecha_recibio"] = factura["fecha_finalizada"];
                                                    }
                                                });

                                            }
                                            if (encontrado == 0){
                                                var record = {"id":factura["_id"],"type":"compras","no_orden":factura["no_orden"], "observaciones_orden":factura["observaciones_orden"],"observaciones_entrada":factura["observaciones_entrada"],
                                                    "estatus":factura["estatus"]!="Orden Finalizada"?"Entrada Pendiente":factura["estatus"], "fecha":factura["fecha"], "sucursal":factura["nombre_sucursal_destino"], "proveedor":factura["proveedor"]["nombre_proveedor"],
                                                    "articulo":art["nombre_articulo"],"descripcion":art["descripcion"], "cantidad":parseFloat(art["cantidad"]), "cantidad_recibida":parseFloat(art["cantidad_recibida"]),
                                                    "existencia":art["existencia_articulo"], "fecha_recibio":factura["fecha_finalizada"], "fecha_estimada":""
                                                }
                                                self.compras_articulos[art["id_articulo"]].push(record);
                                                self.compras_articulos[art["id_articulo"]].sort(function (a, b) {
                                                    //Ordenar por fecha más reciente
                                                    return new Date(b.fecha) - new Date(a.fecha);
                                                });

                                                
                                            }
                                            
                                        });
                                    }
                                    
                                });
                            }   
                            
                        })
                        .catch(error => {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al obtener las facturas. Intente nuevamente.",
                                footer: ""
                            });
                        });
                    //}));
                   
                } 
               
            })
            .catch(error => {
                console.log(error)
            });

        } catch (error) {
            console.log(error);
        }
    },
    showCompras: async function() {
        try {
            var self = this;
            this.items_compras_historico = []; 
            var id_articulo_calculo = this.id_articulo_calculo;

            if (this.compras_articulos[id_articulo_calculo]){
                this.items_compras_historico = this.compras_articulos[id_articulo_calculo];
                this.articulo_editable = this.items_compras_historico[0]["articulo"];
            }

            self.calcula_precio_promedio(id_articulo_calculo);

            this.modal_compras = true;
            
        } catch (error) {
            console.log("Error en showCompras: ", error);
        }
    },
    calcula_precio_promedio: async function(id_articulo_calculo){
        var self = this;
        this.precio_promedio_compra = 0;
        self.loadingPrecioPromedio = true;
        
        var precio_sumatoria = 0;
        var cantidad_lotes = 0;

        for(var k in this.sucursales){
            var id_sucursal = this.sucursales[k]._id;
            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/lotes_articulo';
              let urlConditions = `?startkey=["${id_sucursal}","${id_articulo_calculo}"]&endkey=["${id_sucursal}","${id_articulo_calculo}"]`;
              await axios.get(url + urlConditions)
              .then(response => {
                  if (response.data.rows.length > 0) {
                    let lotes = response.data.rows;                  
                    //console.log("LOTES: ", lotes);
                    for(var kl in lotes){
                      if( parseFloat(lotes[kl].value.precio_compra) > 0){
                        precio_sumatoria += parseFloat(lotes[kl].value.precio_compra);
                        cantidad_lotes += 1;
                      }
                    }
                   
              } 
            }).catch(error => {
              
            });
        }

        if(cantidad_lotes > 0){
          self.precio_promedio_compra = precio_sumatoria > 0 ? precio_sumatoria / cantidad_lotes : 0;
        }
        self.loadingPrecioPromedio = false;
        
    },
    articuloEnPedidoPendiente:function(id_articulo) {
        try {
            var self = this;
            var result = "";    

            if (this.compras_articulos[id_articulo]){
                var compras = this.compras_articulos[id_articulo];
                for(var k in compras){
                    var compra = compras[k];
                    if(compra["estatus"] != "Orden Finalizada"){
                        result = "SI";
                        break;
                    }
                }                
            }

            return result;
            
        } catch (error) {
            console.log("Error en articuloEnPedidoPendiente: ", error);
        }
    },
    formatNumberDecCero: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            numero = 0;
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else{
            if(parseFloat(numero) == parseInt(numero))
            return numero.toString().replace(/\d(?=(\d{3}))/g, '$&,');
            else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
            
    },
    desactivarArticulo: function(id_registro, nombre_articulo) {
        var self = this;
        this.$swal({
                icon: "info",
                title: "Cuidado",
                text: "¿Desea desactivar el artículo: "+nombre_articulo+"?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(resultado => {
                if (resultado.value) {
                    window.dialogLoader.show('Espere un momento por favor..');
                    
                    let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_estatus/" + id_registro;
                    let data = { estatus: 'Inactivo' };

                    window.axios
                        .post(url, data)
                        .then(response => {
                            if (response.data == true) {
                                this.$swal({
                                    type: "success",
                                    title: "¡Operación Exitosa!",
                                    text: "El registro se actualizó de manera correcta.",
                                    footer: ""
                                });
                                self.getArticulos();
                                self.limpiartablas();
                                //Quitar del arreglo el articulo desactivado
                                var filtrado = this.articulos_encontrados.filter(e => e.articulo._id != id_registro);
                                this.articulos_encontrados = filtrado;
                                //Actualizar tabla de busqueda
                                this.keyTablaBusqueda = moment(new Date()).format("x"); //Refrescar tabla
                            } else {
                                window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro. Intente nuevamente..', {
                                    color: 'error'
                                });
                            }                
                        }).catch(error => {                
                            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro. Intente nuevamente..', {
                                color: 'error'
                            });
                        }).then(()=>{
                            window.dialogLoader.hide();                
                        });  
                }
            });   
    },
    getPedidosVencidos: function(){
        this.loading_counter = true;
        var today = window.moment().format("YYYY-MM-DD");
        var where = {
            "selector": {
                "type": "pedidos",
                "estatus":{"$nin": ["Orden Cancelada","Orden Finalizada"] }
            }
        };

        this.pedidos_vencidos = [];
        //PEDIDOS
        window.axios({
            method: 'POST',
            url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
            withCredentials: true,
            data: where,
        }).then(response => {
            if (response.data && response.data.docs.length > 0) {                
                var pedidos = response.data.docs;                
                for(var k in pedidos){
                    var pedido = pedidos[k];
                    if( pedido["fecha_entrega_estimada"] && pedido["fecha_entrega_estimada"].substring(0,10) < today){                        
                        pedido["fecha_estimada"] = pedido["fecha_entrega_estimada"];
                        this.pedidos_vencidos.push(pedido);
                    }
                }
                if(this.pedidos_vencidos.length > 0){
                    //Ordenas por fecha vencida mas antigüa
                    this.pedidos_vencidos.sort(function (a, b) {
                        return new Date(a.fecha_entrega_estimada) - new Date(b.fecha_entrega_estimada);
                    });
                }                
            }
        }).catch(error => {
            console.log("Error en getPedidosVencidos: ", error);
        }).then(()=>{
            this.loading_counter = false;
        })
    },
    
    
    }
};
</script>

<style scoped>
.elevation-2 >>> .VueTables__row.selected-row {
  background-color: #d3f2dc !important;
}
.footer-hide>>>.VuePagination {
    display: none;
}

.bg-seleccionado {
    /*background-color: #861306 !important;*/
    background-color: #ABF6AF !important;
}

.bg-rojo {
    background-color: rgba(238, 92, 92, 0.813) !important;
}

.bg-resaltado {
    background-color: #FFF9C4 !important;
}

.oculto {
    display: none;
}

#orden_excel>table>tr>td {
    padding-left: 2px;
    padding-right: 2px;
}

#orden_excel>table>tr {
    page-break-inside: avoid
}

.celda_titulo {
    font-size: 10px;
    text-align: center;
    color: red;
    border: 2px solid black !important;
}

.celda_datos {
    color:black;
    font-size: 10px;
    border: 1px solid black !important;
}

.celda_datos_resaltado {
    color:black;
    font-size: 11px;
    border: 1px solid black !important;
    background-color: #FFF9C4 !important;
}

.celda_datos_seleccionado {
    color:black;
    font-size: 11px;
    border: 1px solid black !important;
    /*background-color: #EF9A9A !important;*/
    background-color: #ABF6AF !important;
}

.v-dialog  #modalbusqueda >>> .table-bordered thead th, .table-bordered thead td {
  font-size: x-small !important;
  padding-left: 2px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.v-dialog >>> .form-control {
  font-size: x-small !important;  
}
.v-dialog fieldset legend{
  font-size: x-small !important;  
}
.v-dialog #modalbusqueda >>> .v-input__control {
    font-size: 11px !important;
}
.v-dialog #modalbusqueda >>> .v-label {
    font-size: 11px !important;
}

.v-dialog #tablePrecios >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableBuscar >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableBuscarSugeridos >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #combos_busqueda {
    margin-top: 0px;
    margin-bottom: -5px;
}
.v-dialog #combos_busqueda >>> #row_combos {
    background-color: lightblue;
    font-weight: bolder;        
}
.v-dialog #combos_sugeridos {
    margin-top: 0px;
    margin-bottom: -8px;
}
.v-dialog #combos_sugeridos >>> #row_combos_sugeridos {
    background-color: lightblue;
    font-weight: bolder;    
}
.v-dialog #modalbusqueda >>> th {
    background-color: lightgoldenrodyellow;
    text-align: center !important;    
}
.v-dialog #tableExistencias >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableCompras >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableVentas >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableVentas >>> #filtroPeriodo .v-label, .error--text {
    font-size: 9px !important;
}

.v-dialog #modalbusqueda >>> .table td{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.v-dialog #modalbusqueda >>> .table th{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.v-dialog .tablafija thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* color de fondo del encabezado fijo */
  z-index: 1; /* asegura que el encabezado fijo esté en la parte superior */
}
.v-card__text >>> #datagrid td {
    /* overflow-x: hidden !important; 
    overflow-x: auto !important; */
    white-space: nowrap !important;    
    font-size: x-small !important;
    color: black !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
}
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: black;
  font-size: x-small;
  background-color: #EEEEEE;
  padding: 0px;
  height: 20px;
}

/*div.v-dialog__content.v-dialog__content--active div.v-dialog.v-dialog--active div.v-card.v-sheet.theme--light div.v-card__text div#modalbusqueda.container.grid-list-md div.v-tabs.v-tabs--grow.theme--light div.v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text*/
.theme--light.v-tabs >>> .v-tabs-bar, .v-slide-group, .v-tabs-bar{
height: 20px;
margin-bottom: 0px;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  color: white;
  font-size: x-small;
  background-color: #df7205;
  padding: 0px;
  font-weight: bold;
  height: 20px;
}
.bg-red {
    background-color: #fcb7af !important;
}
.bg-green {
    background-color: #d8f8e1 !important;
}
.compact {
    transform: scale(0.800);
    transform-origin: left;
    margin-top: 0px;
    margin-bottom: -10px;
    padding: 0px;
}
.notification-button {
  position: relative;
  display: inline-block;
}

.counter {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
  transform: translate(25%, -25%);
}
/*
.v-dialog .columnaProveedor, .columnaArticulo  {
  white-space: normal !important;
  min-width: 300px !important;
  max-width: 300px !important;  
}
.v-dialog .columnaCategoria {
  white-space: normal !important;
  min-width: 165px !important;
  max-width: 165px !important;  
}
.v-dialog .columnaUnidad {
  white-space: normal !important;
  min-width: 80px !important;
  max-width: 80px !important;  
}

.v-dialog .columnaPlazo, .columnaPrecioCompra, .columnaPrecioVenta{
  white-space: normal !important;
  min-width: 50px !important;
  max-width: 50px !important;  
}*/
</style>
